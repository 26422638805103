import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { CheckOutlined, LinkOutlined, ShopOutlined, TruckOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { get, isEmpty, set, sumBy } from 'lodash';
import { CardToolbox, Main } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import withAuthLayout from '@/layouts/withAuthLayout';
import { SaveButton, FontsStyle, OrderCard } from '@/components/Order/styled';
import { useAuth } from '@/helpers/userContext';
import ApiClient from '@/helpers/apiClient/ApiClient';
import useFormValues from '@/hooks/useFormValues';
import OrderSummaryInfo from '@/components/Order/OrderSummaryInfo';
import DeliveryDescription from '@/components/Order/DeliveryDescription';
import ClientPhoneSearch from '@/components/Order/ClientPhoneSearch';
import ProductsInventory from '@/components/Order/ProductsInventory';
import GoodsForm from '@/components/Order/GoodsForm';
import { HH_mm, YYYY_MM_DD } from '@/constants/momentFormat';
import PersonalInfo from '@/components/Order/PersonalInfo';
import PaymentDetails from '@/components/Order/PaymentDetails';
import DeliveryDetails from '@/components/Order/DeliveryDetails';
import OrderLinksCard from '@/components/Order/OrderLinksCard';
import {
  ORDER_DELIVERY_TYPE_COURIER,
  ORDER_PAYMENT_STATUS_PENDING,
  ORDER_PAYMENT_TYPE_ACQUIRING,
  ORDER_SOCIAL_NETWORK_FACEBOOK,
  ORDER_SOCIAL_NETWORK_INSTAGRAM,
  ORDER_SOCIAL_NETWORK_TELEGRAM,
  ORDER_SOCIAL_NETWORK_VIBER,
} from '@/constants/orders';
import Title from '@/components/Order/Title';

const INITIAL_CLIENT_DATA = {
  first_name: '',
  last_name: '',
  gender: '',
  phone_number: '',
  social_info: [
    { type: ORDER_SOCIAL_NETWORK_INSTAGRAM, value: '' },
    { type: ORDER_SOCIAL_NETWORK_VIBER, value: '' },
    { type: ORDER_SOCIAL_NETWORK_TELEGRAM, value: '' },
    { type: ORDER_SOCIAL_NETWORK_FACEBOOK, value: '' },
  ],
};

const OrderForm = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { id, form, handleSubmit, errors } = useFormValues('orders', true);

  const cityId = Form.useWatch('city_id', form);
  const storeId = Form.useWatch('store_id', form);
  const clientId = Form.useWatch('client_id', form);
  const manualDiscount = Form.useWatch('manual_discount', form);
  const promoCodeId = Form.useWatch('promo_code_id', form);
  const paymentType = Form.useWatch('payment_type', form);
  const deliveryType = Form.useWatch('delivery_type', form);
  const deliveryPrice = Form.useWatch('delivery_price', form);
  const products = Form.useWatch('products', form);

  const sum = useMemo(() => sumBy(products, (product) => sumBy(product.items, (item) => item.price * item.qty * product.count)), [products]);

  const [clientData, setClientData] = useState(INITIAL_CLIENT_DATA);

  const [isCustomerRecipient, setIsCustomerRecipient] = useState(Boolean(clientData.id));
  const [prices, setPrices] = useState({});
  const [promoCodeDiscount, setPromoCodeDiscount] = useState(null);
  const [errorData, setErrorData] = useState({});

  useEffect(() => {
    const formattedErrors = Object.entries(errors).reduce((acc, [key, value]) => set(acc, key, value[0]), {});
    setErrorData({ errors: formattedErrors });
  }, [errors]);

  const initialValues = {
    manager_id: user.id,
    client_id: '',
    city_id: '',
    source: '',
    store_id: '',
    fop_id: '',
    payment_type: '',
    payment_status: ORDER_PAYMENT_STATUS_PENDING,
    comment: '',
    manual_discount: '',
    use_cashback: false,
    promo_code_id: '',
    products: [],
    delivery_type: '',
    delivery_price: '',
    delivery_data: {
      date: moment().format(YYYY_MM_DD),
      time_from: moment().add(1, 'hour').startOf('hour').format(HH_mm),
      time_to: moment().add(2, 'hour').startOf('hour').format(HH_mm),
    },
    recipient_name: '',
    recipient_phone_number: '',
    recipient_gender: '',
    delivery_address: {
      city: '',
      street: '',
      street_number: '',
      entrance: '',
      floor: '',
      apartment_number: '',
      details: '',
    },
  };

  const goodIds = useMemo(() => {
    if (!products) {
      return '[]';
    }

    return JSON.stringify([...new Set(products.flatMap((product) => product.items.map((item) => +item.good_id)))]);
  }, [products]);

  useEffect(() => {
    const ids = JSON.parse(goodIds);

    if (cityId && ids.length) {
      ApiClient.call('get', 'warehouse/goods/inventory-by-city', { ids, city_id: cityId }).data((data) => {
        setPrices(data);
      });
    } else {
      setPrices({});
    }
  }, [goodIds, cityId]);

  useEffect(() => {
    form.setFieldValue('client_id', clientData.id);
  }, [clientData]);

  const onStoreChange = useCallback(
    (storeId) => {
      form.setFieldValue('store_id', storeId);

      if (isEmpty(products)) {
        return;
      }

      const res = products.map((product) => {
        return {
          ...product,
          items: product.items.map(({ good_id, ...item }) => {
            const storeData = get(prices, [storeId, 'data', good_id, 'data']);

            return {
              ...item,
              good_id,
              price: storeData.price,
              remains: storeData.remains,
            };
          }),
        };
      });

      form.setFieldValue('products', res);
    },
    [form, products, prices],
  );

  const applyPromoCode = async () => {
    await ApiClient.call('get', `orders/check-promo-code/${promoCodeId}`, { sum })
      .data(({ data }) => setPromoCodeDiscount(data))
      .catch(({ data }) =>
        setErrorData((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            ...data.errors,
          },
        })),
      );
  };

  const handleClientDelete = () => {
    setClientData({});
    setIsCustomerRecipient(false);

    form.setFieldsValue({
      recipient_name: '',
      recipient_phone_number: '',
      recipient_gender: '',
    });

    form.setFieldValue('client_id', '');
  };

  const handleRecipient = () => {
    const isRecipient = !isCustomerRecipient;
    setIsCustomerRecipient(isRecipient);

    form.setFieldsValue(
      isRecipient
        ? {
            recipient_name: clientData.name,
            recipient_phone_number: clientData.phone_number,
            recipient_gender: clientData.gender,
          }
        : { recipient_name: '', recipient_phone_number: '', recipient_gender: '' },
    );
  };

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={id ? t('orders.list_page_edit_page') : t('orders.list_page_create_page')}
          buttons={[
            !id && (
              <SaveButton
                type="primary"
                key={0}
                onClick={() => {
                  if (paymentType === ORDER_PAYMENT_TYPE_ACQUIRING) {
                    form.setFieldValue('payment_status', ORDER_PAYMENT_STATUS_PENDING);
                  }
                  form.submit();
                }}
              >
                {t('orders.fields.save')} <CheckOutlined style={{ fontWeight: 700 }} />
              </SaveButton>
            ),
          ]}
        />
      </CardToolbox>

      <FontsStyle>
        <Main>
          <Form initialValues={initialValues} form={form} onFinish={handleSubmit}>
            <Row gutter={[12, 12]}>
              <Col span={6}>
                <Title icon={<UserOutlined />} title="orders.cards_title.personal_info" />
                <PersonalInfo errorData={get(errorData, 'errors')} />
              </Col>

              <Col span={8}>
                <GoodsForm cityId={cityId} storeId={storeId} onStoreChange={onStoreChange} errorData={get(errorData, 'errors')} />
              </Col>

              <Col span={10}>
                <Title icon={<ShopOutlined />} title="orders.cards_title.store_and_availability" />
                <OrderCard>
                  <ProductsInventory prices={prices} products={products} cityId={cityId} storeId={storeId} onStoreChange={onStoreChange} />
                </OrderCard>
              </Col>

              <Col span={6}>
                <Title icon={<UserOutlined />} title="orders.cards_title.customer" />
                <ClientPhoneSearch
                  name="client_id"
                  resource="clients/search"
                  error={get(errorData, 'errors.client_id')}
                  clientId={clientId}
                  clientData={clientData}
                  changeClientData={setClientData}
                  searchIcon
                  onDelete={handleClientDelete}
                />
              </Col>

              <Col span={18}>
                <PaymentDetails errorData={get(errorData, 'errors')} paymentType={paymentType} promoCodeId={promoCodeId} applyPromoCode={applyPromoCode} />
              </Col>

              <Col span={14}>
                <Title icon={<TruckOutlined />} title="orders.cards_title.delivery_info" />
                <DeliveryDetails
                  errorData={get(errorData, 'errors')}
                  isCustomerRecipient={isCustomerRecipient}
                  clientData={clientData}
                  handleRecipient={handleRecipient}
                  id={id}
                />
              </Col>

              <Col span={10} style={{ marginTop: '17.5px' }}>
                {deliveryType === ORDER_DELIVERY_TYPE_COURIER && <DeliveryDescription cityId={cityId} sum={sum} error={get(errorData, 'errors')} />}
              </Col>

              {id && (
                <Col span={14} style={{ marginBottom: 30 }}>
                  <Title icon={<LinkOutlined />} title="orders.cards_title.link" />
                  <OrderLinksCard />
                </Col>
              )}
            </Row>
          </Form>
        </Main>
        <OrderSummaryInfo products={products} deliveryPrice={deliveryPrice} manualDiscount={manualDiscount} promoCodeDiscount={promoCodeDiscount} />
      </FontsStyle>
    </>
  );
};

export default withAuthLayout(OrderForm, false);
