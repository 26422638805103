import { useEffect, useState } from 'react';
import { groupBy } from 'lodash/collection';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ApiClient from '@/helpers/apiClient/ApiClient';
import GoodsTable from '@/components/warehouse/GoodTable';

const GoodInventory = ({ id, setOpen }) => {
  const { t } = useTranslation();
  const [data, setData] = useState();

  useEffect(() => {
    ApiClient.call('get', `warehouse/goods/${id}/inventory`).data(({ data }) => {
      const groupByCityID = groupBy(data, 'city_id');

      // eslint-disable-next-line no-unused-vars
      const updateData = Object.entries(groupByCityID).map(([_, storeData]) => {
        return {
          city_name: storeData[0].city_name,
          stores: storeData.map((item) => {
            return {
              store_id: item.store_id,
              store_name: item.store_name,
              price: item.price,
              remains: item.remains,
            };
          }),
        };
      });

      setData(updateData);
    });
  }, [id]);

  return (
    <>
      <GoodsTable data={data} />
      <Button className="action-button" onClick={() => setOpen(true)}>
        {t('warehouse.goods.data_1C')}
      </Button>
    </>
  );
};

GoodInventory.propTypes = {
  id: PropTypes.string,
  setOpen: PropTypes.func,
};

export default GoodInventory;
