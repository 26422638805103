import { Button } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withAuthLayout from '@/layouts/withAuthLayout';
import { CardToolbox, Main } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import Table from '@/widgets/Table/Table';

function FaqsElementIndex() {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        title: t('faqs.fields.city_id'),
        dataIndex: ['city', 'name'],
        key: 'city_id',
      },
      {
        title: t('faqs.fields.title'),
        dataIndex: 'title',
        key: 'title',
      },
    ],
    [],
  );

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('faqs.list_page_header')}
          buttons={[
            <Button className="btn-add_new" size="default" type="primary" key="1">
              <Link to="/cms/main-page/faqs/create">+ {t('faqs.create_button')}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Table route="cms/main-page/faqs" resource="main-page/additional-faqs" columns={columns} permissionNamespace="cms.additional_faqs" />
      </Main>
    </>
  );
}

export default withAuthLayout(FaqsElementIndex);
