import React from 'react';
import { Col, Row } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { PaginationStyled } from '@/components/Product/style';
import ReviewCard from '@/components/Product/ReviewCard';
import ApiClient from '@/helpers/apiClient/ApiClient';

const ReviewsPage = ({ reviewsData, setReviewsData, total, page, perPage, setPerPage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handlePageChange = (newPage, newPageSize) => {
    setPerPage(newPageSize);
  };

  const onApproveComment = (review) => {
    return (onFinish) => {
      const { id, moderated } = review;

      if (moderated) {
        return;
      }

      ApiClient.call('post', `/products/reviews/${id}/submit`, {}, review)
        .data(() => {
          setReviewsData((prev) => {
            return prev.map((review) => (review.id === id ? { ...review, moderated: true } : review));
          });
        })
        .finally(() => {
          onFinish();
        });
    };
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        {reviewsData.map((review) => (
          <Col key={review.id} span={12}>
            <ReviewCard review={review} onApprove={onApproveComment(review)} />
          </Col>
        ))}
      </Row>

      <PaginationStyled
        total={total}
        showTotal={(total) => (
          <span className="total">
            {t('products.reviews_page.total')} {total}
          </span>
        )}
        current={page}
        pageSize={perPage}
        onChange={handlePageChange}
        showSizeChanger
        onShowSizeChange={(current, size) => {
          navigate(`?page=${current}`);
          setPerPage(size);
        }}
        pageSizeOptions={['6', '12', '18', '24']}
        itemRender={(page, type) => {
          const pageLink = `?page=${page}`;
          const pageText = type === 'next' ? '>' : type === 'prev' ? '<' : page;

          return <Link to={pageLink}>{pageText}</Link>;
        }}
      />
    </div>
  );
};

ReviewsPage.propTypes = {
  reviewsData: PropTypes.array,
  setReviewsData: PropTypes.func,
  total: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  setPerPage: PropTypes.func,
};

export default ReviewsPage;
