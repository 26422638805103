import { Col, Form, Row } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { last } from 'lodash/array';
import { forEach, isArray, set, get } from 'lodash';
import { PageHeader } from '@/components/page-headers/page-headers';
import { Main } from '@/container/styled';
import { Cards } from '@/components/cards/frame/cards-frame';
import { FormWrapper } from '@/container/pages/style';
import useFormValues from '@/hooks/useFormValues';
import withAuthLayout from '@/layouts/withAuthLayout';
import { Button } from '@/components/buttons/buttons';
import FormSelectAsync from '@/form/FormSelectAsync';
import FormSubmit from '@/form/FormSubmit';
import MoneyInput from '@/widgets/MoneyInput';
import { ActionWrapper } from '@/components/steps/style';
import priceFormat from '@/helpers/priceFormat';
import AsyncSelect from '@/widgets/AsyncSelect';
import { ModalCardContainer, ProductsContainer, RecommendedProductCard, SetCardContainer } from '@/container/pages/cms/recommendedProducts/styled';

const SetCard = ({ value = {}, name = 0, removeSet, errors, open, onOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <SetCardContainer title={`${t('recommended_products.min_sum')} - ${priceFormat(value.min_sum)}`} errors={errors}>
      <ModalCardContainer
        title={t('recommended_products.product_list')}
        centered
        open={open}
        width={1400}
        onCancel={onClose}
        footer={[
          <Button type="primary" onClick={onClose} key="1">
            {t('recommended_products.success')}
          </Button>,
          <Button onClick={onClose} key="2">
            {t('recommended_products.close')}
          </Button>,
        ]}
      >
        <Form.Item
          label={t('recommended_products.min_sum')}
          name={[name, 'min_sum']}
          validateStatus={get(errors, `min_sum`) ? 'error' : null}
          help={get(errors, `min_sum`)}
        >
          <MoneyInput />
        </Form.Item>

        <Form.List name={[name, 'variants']}>
          {(productsFields, { add: addProduct, remove: removeProduct }) => {
            return (
              <div>
                {productsFields.map((productField) => (
                  <ProductsContainer key={productField.name}>
                    <Row gutter={24}>
                      <Col span={18}>
                        <Form.Item
                          label={t('recommended_products.fields.variant_id')}
                          name={[productField.name, 'product_variant_id']}
                          validateStatus={get(errors, `variants.${productField.name}.product_variant_id`) ? 'error' : null}
                          help={get(errors, `variants.${productField.name}.product_variant_id`)}
                        >
                          <AsyncSelect resource="products/search-variants" />
                        </Form.Item>
                      </Col>

                      <Col span={6}>
                        <Button type="danger" onClick={() => removeProduct(productField.name)} className="detele-btn">
                          {t('recommended_products.delete_product')}
                        </Button>
                      </Col>
                    </Row>
                  </ProductsContainer>
                ))}
                <Button type="primary" onClick={() => addProduct({ product_variant_id: null })}>
                  {t('recommended_products.add_product')}
                </Button>
              </div>
            );
          }}
        </Form.List>
        <div className="delete-all-products">
          <Button type="danger" onClick={() => removeSet(name)}>
            {t('recommended_products.delete_products_list')}
          </Button>
        </div>
      </ModalCardContainer>

      <Row justify="space-between">
        <Col>
          <Button type="primary" onClick={onOpen}>
            {t('recommended_products.edit')}
          </Button>
        </Col>
        <Col>
          <Button
            type="danger"
            onClick={() => {
              removeSet(name);
            }}
          >
            {t('recommended_products.delete')}
          </Button>
        </Col>
      </Row>
    </SetCardContainer>
  );
};

SetCard.propTypes = {
  value: propTypes.object,
  name: propTypes.number.isRequired,
  removeSet: propTypes.func.isRequired,
  errors: propTypes.object,
  open: propTypes.bool,
  onOpen: propTypes.func,
  onClose: propTypes.func,
};

const transformErrors = (errors) => {
  const result = {};

  forEach(errors, (messages, key) => {
    if (isArray(messages) && messages.length > 0) {
      set(result, key, messages);
    }
  });

  return result;
};

const RecommendedProductsForm = () => {
  const { t } = useTranslation();
  const { id, form, loading, handleSubmit, errors } = useFormValues('recommended-lists');
  const [activeCard, setActiveCard] = useState(null);

  const transformedErrors = useMemo(() => {
    return transformErrors(errors);
  }, [errors]);

  return (
    <>
      <PageHeader ghost title={id ? t('recommended_products.edit_page_header') : t('recommended_products.create_page_header')} />
      <Main>
        <Cards headless>
          <Row justify="center">
            <Col span={24}>
              <FormWrapper>
                <Form style={{ width: '100%' }} form={form} layout="vertical" onFinish={handleSubmit}>
                  <Col span={24}>
                    <FormSelectAsync name="city_id" resource="cities/search" label={t('recommended_products.fields.city_id')} error={errors.city_id} required />
                  </Col>

                  <Form.List name="sets">
                    {(setsFields, { remove: removeSet, add }) => (
                      <Row gutter={[16, 16]} style={{ marginBottom: 30 }}>
                        {[...setsFields]
                          .sort((a, b) => (form.getFieldValue(['sets', a.name, 'min_sum']) > form.getFieldValue(['sets', b.name, 'min_sum']) ? 1 : -1))
                          .map(({ name, key }) => {
                            return (
                              <Col span={8} key={name}>
                                <Form.Item name={name}>
                                  <SetCard
                                    name={name}
                                    removeSet={removeSet}
                                    errors={get(transformedErrors, `sets.${key}`)}
                                    open={activeCard === name}
                                    onOpen={() => setActiveCard(name)}
                                    onClose={() => setActiveCard(null)}
                                  />
                                </Form.Item>
                              </Col>
                            );
                          })}
                        <Col span={8}>
                          <RecommendedProductCard
                            onClick={() => {
                              setActiveCard(last(setsFields) ? last(setsFields).name + 1 : 0);
                              add({
                                min_sum: null,
                              });
                            }}
                          >
                            <div className="recommended-product-card-btn">
                              <FeatherIcon icon="plus-square" size={30} className="icon" />
                              {t('recommended_products.add_list_products')}
                            </div>
                          </RecommendedProductCard>
                        </Col>
                      </Row>
                    )}
                  </Form.List>

                  <ActionWrapper>
                    <FormSubmit loading={loading} />
                  </ActionWrapper>
                </Form>
              </FormWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default withAuthLayout(RecommendedProductsForm);
