import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PageHeader } from '@/components/page-headers/page-headers';
import { CardToolbox, Main } from '@/container/styled';
import withAuthLayout from '@/layouts/withAuthLayout';
import Table from '@/widgets/Table/Table';

const RecommendedProductsElementIndex = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        title: t('recommended_products.fields.city_id'),
        dataIndex: ['city', 'name'],
        key: 'city_id',
      },
    ],
    [],
  );

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('recommended_products.list_page_header')}
          buttons={[
            <Button className="btn-add_new" size="default" type="primary" key="1">
              <Link to="/cms/recommended-lists/create">+ {t('recommended_products.create_button')}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Table route="cms/recommended-lists" resource="recommended-lists" columns={columns} permissionNamespace="cms.recommended_lists" />
      </Main>
    </>
  );
};

export default withAuthLayout(RecommendedProductsElementIndex);
