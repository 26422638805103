import { Col, Form, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withAuthLayout from '@/layouts/withAuthLayout';
import { PageHeader } from '@/components/page-headers/page-headers';
import { Main } from '@/container/styled';
import { Cards } from '@/components/cards/frame/cards-frame';
import { ActionsWrapper, FormWrapper } from '@/container/pages/style';
import FormInput from '@/form/FormInput';
import FormSubmit from '@/form/FormSubmit';
import useFormValues from '@/hooks/useFormValues';

const TaxCodesForm = () => {
  const { t } = useTranslation();

  const { id, form, loading, handleSubmit, errors } = useFormValues('warehouse/tax-codes');

  return (
    <>
      <PageHeader ghost title={id ? t('warehouse.tax_codes.header_title_edit') : t('warehouse.tax_codes.header_title_create')} />
      <Main>
        <Cards headless>
          <Row justify="center">
            <Col xl={12} md={16} xs={24}>
              <FormWrapper>
                <Form style={{ width: '100%' }} form={form} layout="vertical" onFinish={handleSubmit}>
                  <FormInput name="name" label={t('warehouse.tax_codes.tax_name')} required error={errors.name} />
                  <FormInput name="code" label={t('warehouse.tax_codes.tax_code')} required error={errors.code} />

                  <ActionsWrapper>
                    <FormSubmit loading={loading} />
                  </ActionsWrapper>
                </Form>
              </FormWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default withAuthLayout(TaxCodesForm);
