import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Button, Col, Row } from 'antd';
import FeatherIcon from 'feather-icons-react';
import classNames from 'classnames';
import moment from 'moment';
import { StringParam, useQueryParam } from 'use-query-params';
import { CardToolbox, Main, TopToolBox } from '../../../../styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import withAuthLayout from '@/layouts/withAuthLayout';
import Table from '@/widgets/Table/Table';
import { Tag } from '@/components/tags/tags';
import useUrlParams from '@/hooks/useUrlParams';
import { AutoComplete } from '@/components/autoComplete/autoComplete';
import TextFilter from '@/widgets/Table/TextFilter';
import { DD_MM_Y, HH_mm } from '@/constants/momentFormat';

const DISPLAY_TYPE_TABLE = 'table';
const DISPLAY_TYPE_GRID = 'grid';

const ProductIndex = () => {
  const { t } = useTranslation();
  const [displayType] = useQueryParam('display', StringParam);
  const { setSort } = useUrlParams();
  const navigate = useNavigate();

  useEffect(() => {
    setSort('updated_at', 'descend');
  }, []);

  const columns = React.useMemo(
    () => [
      {
        title: t('products.sku'),
        dataIndex: 'sku',
        key: 'sku',
        width: '50px',
        filterDropdown: (props) => <TextFilter {...props} />,
      },
      {
        title: t('products.last_order'),
        dataIndex: 'updated_at',
        key: 'updated_at',
        render: (value) => {
          const date = moment(value);
          return (
            <span>
              <p style={{ marginBottom: 0 }}>{date.format(DD_MM_Y)}</p>
              <p>{date.format(HH_mm)}</p>
            </span>
          );
        },
        sorter: true,
      },
      {
        title: t('products.moderated'),
        dataIndex: 'approved',
        key: 'approved',
        render: (value) => <span className={classNames('status-text', value ? 'active' : 'deactivate')}>{value ? 'Так' : 'Ні'}</span>,
        filters: [
          {
            text: 'Так',
            value: true,
          },
          {
            text: 'Ні',
            value: false,
          },
        ],
        filterMultiple: false,
        sorter: true,
      },
      {
        title: t('products.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
      },
      {
        title: t('products.categories'),
        dataIndex: 'categories',
        key: 'categories',
        render: (value) => {
          return value.map((category, i) => {
            return (
              <span key={i} style={{ marginBottom: 10 }}>
                <Tag color="gold">{category.name}</Tag>
              </span>
            );
          });
        },
      },
    ],
    [],
  );

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={displayType === DISPLAY_TYPE_TABLE ? t('products.list_page_header_table') : t('products.list_page_header_grid')}
          buttons={[
            <Button className="btn-add_new" size="default" type="primary" key="1">
              <Link to="/cms/products/product-positions/create">+ {t('products.create_button')}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Row gutter={24}>
          <Col xs={24}>
            <TopToolBox>
              <Row gutter={0}>
                <Col xxl={8} lg={12} xs={24}>
                  <AutoComplete
                    placeholder={t('products.search')}
                    searchUrl="products/search"
                    onSelect={(value) => navigate(`/cms/products/product-positions/${value}`)}
                    width="100%"
                    patterns
                  />
                </Col>
                <Col xxl={16} xs={24}>
                  <div className="product-list-action d-flex justify-content-end align-items-center">
                    {window.innerWidth > 575 && (
                      <div className="product-list-action__viewmode">
                        <Link to={`?display=${DISPLAY_TYPE_GRID}`} className={classNames({ active: displayType === DISPLAY_TYPE_GRID })}>
                          <FeatherIcon icon="grid" size={16} />
                        </Link>
                        <Link to={`?display=${DISPLAY_TYPE_TABLE}`} className={classNames({ active: displayType === DISPLAY_TYPE_TABLE })}>
                          <FeatherIcon icon="list" size={16} />
                        </Link>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </TopToolBox>
          </Col>
        </Row>

        <Table columns={columns} route="cms/products/product-positions" resource="products" permissionNamespace="cms.products" />
      </Main>
    </>
  );
};

export default withAuthLayout(ProductIndex);
