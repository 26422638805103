import { Button, message } from 'antd';
import { CopyOutlined, ArrowRightOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LinkInputContainer, LinkInputStyle } from '@/components/Order/styled';

const LinkInput = ({ value, label }) => {
  const { t } = useTranslation();
  const handleCopy = () => {
    if (value) {
      navigator.clipboard.writeText(value);
      message.success(t('orders.fields.copy_message'));
    }
  };

  return (
    <>
      <LinkInputContainer>
        <span className="label">{label}</span>
        <LinkInputStyle readOnly value={value} />
        <Button icon={<CopyOutlined />} onClick={handleCopy} />
        <a className="navigate" href={value} target="_blank" rel="noreferrer" role="button" aria-label="Перейти">
          <ArrowRightOutlined />
        </a>
      </LinkInputContainer>
    </>
  );
};

LinkInput.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
};

export default LinkInput;
