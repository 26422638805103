import { Table } from 'antd';
import propTypes from 'prop-types';
import priceFormat from '@/helpers/priceFormat';

const expandedRowRender = (record) => {
  const columns = [
    {
      dataIndex: 'store_name',
      key: 'store_name',
      width: '40%',
    },
    {
      dataIndex: 'price',
      key: 'price',
      render: (price) => <span style={{ color: price ? 'black' : 'red' }}>{priceFormat(price, '₴')}</span>,
      width: '30%',
    },
    {
      dataIndex: 'remains',
      key: 'remains',
      width: '30%',
      render: (remains) => <span style={{ color: remains ? 'black' : 'red' }}>{remains} шт.</span>,
    },
  ];

  return <Table columns={columns} dataSource={record.stores} pagination={false} showHeader={false} rowKey="store_name" />;
};

const GoodsTable = ({ data = [] }) => {
  const columns = [
    {
      dataIndex: 'city_name',
      key: 'city_name',
    },
  ];

  return (
    <Table
      rowKey="city_name"
      dataSource={data}
      showHeader={false}
      columns={columns}
      pagination={false}
      expandable={{
        expandedRowRender,
      }}
    />
  );
};

GoodsTable.propTypes = {
  data: propTypes.array,
};

export default GoodsTable;
