import React, { Fragment, useCallback, useState } from 'react';
import { Button, Col, Empty, Modal, Row } from 'antd';
import PropTypes from 'prop-types';
import { DeleteOutlined, EyeOutlined, FileImageTwoTone, FolderAddTwoTone, PlusOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import { useTranslation } from 'react-i18next';
import { PreviewImageVariantStyled, VariantCardFileStyled, VariantImagesRowStyled, ViewImageColStyled } from '@/components/Product/style';
import useFileUploader, { MAX_FILES } from '@/hooks/useFileUpload';
import ProgressBar from '@/components/Product/ProgressBar';

const VariantFileListItem = ({ file, togglePrimary, isActive, onPreview, handleDelete, loading }) => {
  const imageUrl = file.url || (file.originFileObj && URL.createObjectURL(file.originFileObj));

  return (
    <div>
      <VariantCardFileStyled active={Number(isActive)} primary={Number(file.is_primary)}>
        <img src={imageUrl} alt={file.name} />

        {!file.uploaded && <ProgressBar file={file} style={{ marginLeft: 10 }} />}
        <div className="card-file-actions">
          <Button onClick={() => togglePrimary(file)} icon={file.is_primary ? <StarFilled /> : <StarOutlined />} className="main" />
          <Button icon={<EyeOutlined />} onClick={() => onPreview(file)} className="view" />
          <Button icon={<DeleteOutlined />} onClick={() => handleDelete(file.uid)} className="delete" disabled={loading} />
        </div>
      </VariantCardFileStyled>
    </div>
  );
};

VariantFileListItem.propTypes = {
  file: PropTypes.object,
  togglePrimary: PropTypes.func,
  isActive: PropTypes.bool,
  onPreview: PropTypes.func,
  handleDelete: PropTypes.func,
  loading: PropTypes.bool,
};

const VariantImages = ({ value = [], onChange }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const { previewSrc, fileList, primarySrc, handleChange, onPreview, toggleIsPrimary, onUpload, handleDelete, filesUploaded, hasErrors, loading } =
    useFileUploader(value, 'products');

  const onSave = useCallback(() => {
    if (filesUploaded) {
      onChange(fileList.map(({ id, url, is_primary }) => ({ id, url, is_primary })));
    }
    setOpen(false);
  }, [filesUploaded, fileList, hasErrors]);

  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        width={900}
        title={filesUploaded ? t('products.variant_images.title_add') : t('products.variant_images.title_edit')}
        footer={
          filesUploaded ? (
            <Button onClick={onSave}>{t('products.variant_images.save')}</Button>
          ) : (
            <Button onClick={onUpload} disabled={loading} loading={loading}>
              {loading ? t('products.variant_images.uploading') : t('products.variant_images.uploaded')}
            </Button>
          )
        }
      >
        <Row gutter={[15, 30]}>
          <Col span={24}>
            <Dragger beforeUpload={() => false} multiple fileList={fileList} onChange={handleChange} showUploadList={false}>
              <FolderAddTwoTone style={{ fontSize: '75px' }} />
              <p className="ant-upload-text">{t('products.variant_images.dragger_message')}</p>
            </Dragger>
          </Col>

          <Col span={12}>
            <VariantImagesRowStyled gutter={[10, 10]}>
              <h3 className="title">{t('products.variant_images.title_list_file')}</h3>
              <span className="upload-qty">
                {t('products.variant_images.added')} {fileList.length}/{MAX_FILES}
              </span>

              <div className="container-items">
                {fileList.map((file) => {
                  return (
                    <Col span={24} key={file.uid || file.id}>
                      <VariantFileListItem
                        file={file}
                        togglePrimary={toggleIsPrimary}
                        isActive={file.url === previewSrc}
                        onPreview={onPreview}
                        handleDelete={handleDelete}
                        loading={loading}
                      />
                    </Col>
                  );
                })}
              </div>

              {fileList.length === 0 && (
                <div className="empty-wrapper">
                  <Empty />
                </div>
              )}
            </VariantImagesRowStyled>
          </Col>
          <Col span={12}>
            <ViewImageColStyled>
              <h3 className="title">{t('products.variant_images.review_images')}</h3>
              {previewSrc ? (
                <img src={previewSrc} alt="previewSrc" />
              ) : (
                <div className="not-view-image">
                  <FileImageTwoTone style={{ fontSize: '75px' }} />
                  <span>{t('products.variant_images.not_view_image_message')}</span>
                </div>
              )}
            </ViewImageColStyled>
          </Col>
        </Row>
      </Modal>

      <PreviewImageVariantStyled>
        {primarySrc ? (
          <button className="view" onClick={() => setOpen(true)} type="button">
            <img src={primarySrc} alt="view" />
          </button>
        ) : (
          <button className="add" onClick={() => setOpen(true)} type="button" aria-label="added image">
            <PlusOutlined />
          </button>
        )}
      </PreviewImageVariantStyled>
    </>
  );
};

VariantImages.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default VariantImages;
