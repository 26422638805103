import { AutoComplete, Spin } from 'antd';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { MFIcon } from '@million-flowers/mf-icon-component';
import ApiClient from '@/helpers/apiClient/ApiClient';
import { AsyncAutoCompleteWrapperStyled } from '@/components/Product/style';

const AsyncAutoComplete = ({ resource, value, onChange, ...props }) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const fetchOptionsAsync = async (searchValue) => {
    setFetching(true);

    try {
      const response = await ApiClient.call('get', resource, { q: searchValue });
      const data = response.data || [];
      const transformedOptions = data.map(({ label, icon, value }) => ({
        value,
        label: (
          <div className="custom-options">
            <span>{label}</span>
            <MFIcon icon={icon} />
          </div>
        ),
        textLabel: label,
        icon,
      }));

      setOptions(transformedOptions);
    } catch (error) {
      console.error('Помилка завантаження опцій', error);
    }
    setFetching(false);
  };

  const handleSelect = (value) => {
    const option = options.find((opt) => opt.value === value);
    setSelectedOption(option);
    onChange(value);
  };

  return (
    <AsyncAutoCompleteWrapperStyled>
      <AutoComplete
        value={selectedOption ? selectedOption.textLabel : value}
        onSearch={fetchOptionsAsync}
        onSelect={handleSelect}
        onChange={(val) => {
          if (!val) {
            setSelectedOption(null);
          }
          onChange(val);
        }}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        options={options}
        style={{ width: '100%' }}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        {...props}
      />
      {selectedOption && (
        <div className="suffix-icon">
          <MFIcon icon={selectedOption.icon} />
        </div>
      )}
    </AsyncAutoCompleteWrapperStyled>
  );
};

AsyncAutoComplete.propTypes = {
  resource: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default AsyncAutoComplete;
