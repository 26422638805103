import { useEffect, useState } from 'react';
import { Empty } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { floor, get, min, sumBy } from 'lodash';
import { ItemInventoryStyle, ExpandedRowRenderItemPriceRemainStyle } from '@/components/Order/styled';
import priceFormat from '@/helpers/priceFormat';

const expandedRowRenderProduct = (record) => {
  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
    },
    {
      dataIndex: 'price',
      key: 'price',
      render: (item) =>
        item !== null ? (
          <span className={`value ${item !== '-' && item !== 0 ? 'bg-success' : 'bg-danger'}`}>{item === '-' ? item : priceFormat(item, '₴')}</span>
        ) : (
          <span className="value" style={{ background: 'yellow' }}>
            ???
          </span>
        ),
    },
    {
      dataIndex: 'remains',
      key: 'remains',
      render: (text, record) =>
        text !== null ? (
          <span className={`value ${record.available ? 'bg-success' : 'bg-danger'}`}>{text} шт</span>
        ) : (
          <span className="value" style={{ background: 'yellow' }}>
            ???
          </span>
        ),
    },
  ];

  return (
    <ExpandedRowRenderItemPriceRemainStyle
      rowKey="name"
      dataSource={record.products}
      pagination={false}
      columns={columns}
      available={record.products.some(({ available }) => available)}
    />
  );
};

const ProductsInventory = ({ products, prices, cityId = '', storeId, onStoreChange }) => {
  const [data, setData] = useState([]);

  const columns = [
    {
      dataIndex: 'store_name',
      key: 'store_name',
    },
    {
      key: 'action',
      fixed: 'right',
      render: (text, record) => {
        const isSelect = Number(record.store_id) === Number(storeId);

        return isSelect ? (
          <CheckOutlined className="checked" />
        ) : (
          <button
            type="button"
            className="select-btn"
            onClick={() => onStoreChange(record.store_id)}
            disabled={record.products.some(({ available }) => !available)}
          >
            Обрати
          </button>
        );
      },
    },
  ];

  useEffect(() => {
    const data = Object.entries(prices).map(([store_id, storeData]) => {
      return {
        store_id,
        store_name: storeData.store_name,
        products: products.map((product, key) => {
          const children = product.items.map((item, i) => ({
            key: `${key}-${i}`,
            ...item,
            price: get(prices, [store_id, 'data', item.good_id, 'data', 'price']),
            remains: get(prices, [store_id, 'data', item.good_id, 'data', 'remains']),
            available: get(prices, [store_id, 'data', item.good_id, 'data', 'remains']) >= item.qty,
          }));
          const price = sumBy(children, (elem) => {
            return elem.price * elem.qty * product.count;
          });
          const remains = floor(
            min(
              children.map((elem) => {
                return elem.remains / elem.qty;
              }),
            ),
          );

          return {
            name: product.name,
            price,
            remains,
            available: remains >= product.count,
            children,
          };
        }),
      };
    });
    setData(data);
  }, [products, prices, cityId]);

  return (
    <ItemInventoryStyle
      rowKey="store_id"
      dataSource={data}
      pagination={false}
      columns={columns}
      locale={{ emptyText: <Empty /> }}
      rowClassName={({ products }) => (products.some((item) => !item.available) ? 'bg-danger' : 'bg-success')}
      expandable={{
        expandedRowRender: expandedRowRenderProduct,
      }}
    />
  );
};

ProductsInventory.propTypes = {
  products: PropTypes.array,
  prices: PropTypes.object,
  cityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onStoreChange: PropTypes.func,
};

export default ProductsInventory;
