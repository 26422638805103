import { Col, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import ColorPicker from '@/widgets/ColorPicker';
import ApiClient from '@/helpers/apiClient/ApiClient';
import MoneyInput from '@/widgets/MoneyInput';

const DeliveryZoneEditor = ({ zone, index, onChange, errors }) => {
  const { t } = useTranslation();
  const [availableColors, setAvailableColors] = useState([]);

  console.log(zone);

  useEffect(() => {
    ApiClient.call('get', 'cities/delivery-zone-colors').data(setAvailableColors);
  }, []);

  return (
    <Form layout="vertical">
      <Form.Item label={t('delivery_zones.fields.color')} style={{ display: 'block' }}>
        <ColorPicker
          value={zone.color}
          onChange={({ hex }) => {
            onChange(zone.id, 'color', hex);
          }}
          colorSet={availableColors}
        />
      </Form.Item>

      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label={t('delivery_zones.fields.delivery_price')}
            required
            validateStatus={get(errors, `zones.${index}.delivery_price`) ? 'error' : null}
            help={get(errors, `zones.${index}.delivery_price`)}
          >
            <MoneyInput value={zone.delivery_price} onChange={(value) => onChange(zone.id, 'delivery_price', value)} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('delivery_zones.fields.free_delivery_from_sum')}
            validateStatus={get(errors, `zones.${index}.free_delivery_from_sum`) ? 'error' : null}
            help={get(errors, `zones.${index}.free_delivery_from_sum`)}
          >
            <MoneyInput value={zone.free_delivery_from_sum} onChange={(value) => onChange(zone.id, 'free_delivery_from_sum', value)} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label={t('delivery_zones.fields.info_text')}
        required
        validateStatus={get(errors, `zones.${index}.info_text`) ? 'error' : null}
        help={get(errors, `zones.${index}.info_text`)}
      >
        <Input.TextArea value={zone.info_text} onChange={(e) => onChange(zone.id, 'info_text', e.target.value)} />
      </Form.Item>
    </Form>
  );
};

DeliveryZoneEditor.propTypes = {
  zone: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.any,
};

export default DeliveryZoneEditor;
