import { useState, useMemo, useCallback, useEffect } from 'react';
import { message } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import ApiClient from '@/helpers/apiClient/ApiClient';

export const MAX_FILES = 5;

export const PROGRESS_STATUSES = {
  uploading: 'active',
  done: 'success',
  error: 'exception',
  remove: '',
};

const useFileUploader = (value, directory = null) => {
  const { t } = useTranslation();
  const [previewSrc, setPreviewSrc] = useState(value.length ? value[0].url : '');
  const [fileList, setFileList] = useState(value.map((el) => ({ ...el, uid: uuidv4(), uploaded: true })));
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback(({ fileList: newFileList, file }) => {
    if (newFileList.length > MAX_FILES) {
      message.error(t('products.limited_file_message'));
      return;
    }

    const data = [...newFileList];
    const src = URL.createObjectURL(file);
    data.splice(newFileList.length - 1, 1, { ...newFileList[newFileList.length - 1], url: src });

    setFileList(data);
    setPreviewSrc(src);
  }, []);

  const onPreview = useCallback(async (file) => {
    let src = file.url || (file.originFileObj && URL.createObjectURL(file.originFileObj));
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    setPreviewSrc(src);
  }, []);

  const uploadFile = useCallback(async (file) => {
    const formData = new FormData();
    formData.append('file', file.originFileObj);
    if (directory) {
      formData.append('directory', directory);
    }

    try {
      setFileList((prevList) => prevList.map((f) => (f.uid === file.uid ? { ...f, status: 'uploading', progress: 0, uploaded: false } : f)));

      const { id, url } = await ApiClient.call('post', 'pictures', {}, formData, {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 99;
          setFileList((prevList) => prevList.map((f) => (f.uid === file.uid ? { ...f, progress } : f)));
        },
      }).getData();

      setFileList((prevList) => prevList.map((f) => (f.uid === file.uid ? { status: 'done', id, url, is_primary: Boolean(f.is_primary), progress: 100 } : f)));

      setPreviewSrc(url);
    } catch (data) {
      setFileList((prevList) => prevList.map((f) => (f.uid === file.uid ? { ...f, status: 'error', response: data.message } : f)));
      throw new Error(data.message);
    }
  }, []);

  const onUpload = useCallback(async () => {
    try {
      setLoading(true);
      const uploadPromises = fileList.filter((el) => el.originFileObj).map(uploadFile);
      await Promise.all(uploadPromises);
      setPreviewSrc('');
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [fileList, uploadFile]);

  const handleDelete = useCallback((fileUid) => {
    setFileList((prevList) => prevList.filter((file) => file.uid !== fileUid));
    setPreviewSrc('');
  }, []);

  const toggleIsPrimary = useCallback((file) => {
    setFileList((prevList) =>
      prevList.map((el) => ({
        ...el,
        is_primary: el.uid === file.uid,
      })),
    );
  }, []);

  const hasErrors = useMemo(() => {
    return fileList.some((el) => el.status === 'error');
  }, [fileList]);

  const primarySrc = useMemo(() => {
    return value.find((el) => el.is_primary)?.url || '';
  }, [value]);

  const filesUploaded = useMemo(() => {
    return fileList.every((el) => el.id);
  }, [fileList]);

  useEffect(() => {
    if (hasErrors) {
      message.warning(t('products.upload_warning_message'));
    }
  }, [hasErrors]);

  return {
    previewSrc,
    fileList,
    setFileList,
    primarySrc,
    filesUploaded,
    handleChange,
    onPreview,
    uploadFile,
    onUpload,
    handleDelete,
    toggleIsPrimary,
    hasErrors,
    loading,
  };
};

export default useFileUploader;
