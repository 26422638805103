import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { NavLink, Route, Routes, useLocation } from 'react-router-dom';
import { last } from 'lodash/array';
import React from 'react';
import { Main } from '../../../styled';
import withAuthLayout from '@/layouts/withAuthLayout';
import useFormValues from '@/hooks/useFormValues';
import { PageHeader } from '@/components/page-headers/page-headers';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { ReviewsTabStyled } from '@/components/Product/style';
import StoreDetailsForm from '@/components/Store/StoreDetailsForm';
import CalendarForm from '@/components/Store/CalendarForm';

const StoreForm = () => {
  const { t } = useTranslation();
  const { form, id, loading, handleSubmit, errors } = useFormValues('stores');

  const { pathname } = useLocation();
  const activeKey = last(pathname.split('/'));

  const tabs = [
    {
      label: <NavLink to="">{t('stores.tabs.store_form_title')}</NavLink>,
      key: id,
    },
    {
      label: (
        <NavLink to="calendar">
          <ReviewsTabStyled>
            <span>{t('stores.tabs.store_calendar_title')}</span>
          </ReviewsTabStyled>
        </NavLink>
      ),
      key: 'calendar',
    },
  ];

  return (
    <>
      <PageHeader ghost title={t('stores.list_page_header')} />

      <Main>
        {id ? (
          <>
            <Tabs items={tabs} activeKey={activeKey} />
            <Routes>
              <Route
                path=""
                element={
                  <StoreDetailsForm form={form} handleFinish={handleSubmit} id={id} errors={errors} cityId={form.getFieldValue('city_id')} loading={loading} />
                }
              />
              <Route path="calendar" element={<CalendarForm storeId={id} />} />
            </Routes>
          </>
        ) : (
          <StoreDetailsForm form={form} handleFinish={handleSubmit} id={id} errors={errors} cityId={form.getFieldValue('city_id')} loading={loading} />
        )}
      </Main>
    </>
  );
};

export default withAuthLayout(StoreForm);
