import moment from 'moment';
import { useState } from 'react';
import { YYYY_MM_DD } from '@/constants/momentFormat';

export default function () {
  const [range, setRange] = useState([moment().format(YYYY_MM_DD), moment().format(YYYY_MM_DD)]);

  const handleRangeChange = (value) => {
    if (value) {
      setRange(value.map((date) => date.format(YYYY_MM_DD)));
    }
  };

  const clearRange = () => {
    setRange([]);
  };

  return {
    range,
    handleRangeChange,
    clearRange,
  };
}
