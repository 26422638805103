import { Button, Modal, Form, Input, Col, Badge, Checkbox } from 'antd';
import { useState } from 'react';
import { CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { get, has } from 'lodash';
import { useTranslation } from 'react-i18next';
import { PropertiesInfoMessage, PropertiesListItemStyled, PropertiesRowStyled } from '@/components/Product/style';
import AsyncAutoComplete from '@/components/Product/AsyncAutoComplete';

const VariantsProperties = ({ value = [], onChange, errors }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    form.setFieldsValue({ properties: value || [] });
    setIsModalOpen(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onChange(values.properties);
        setIsModalOpen(false);
      })
      .catch((info) => {
        console.error('Validation failed:', info);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (propertyIndex, key, checked) => {
    const values = form.getFieldValue(['properties', propertyIndex, 'values']);

    form.setFieldValue(
      ['properties', propertyIndex, 'values'],
      values.map((el, i) => {
        return {
          ...el,
          is_general: checked && key === i,
        };
      }),
    );
  };

  return (
    <>
      <Button type="dashed" onClick={showModal} style={{ width: '100%' }}>
        {value?.length ? (
          <Badge count={value?.length} style={{ position: 'initial', marginLeft: 2 }}>
            {t('products.variants.properties_modal.edit')}
          </Badge>
        ) : (
          t('products.variants.properties_modal.add')
        )}
      </Button>

      <Modal
        title={t('products.variants.properties_modal.title')}
        open={isModalOpen}
        onOk={handleOk}
        okText={t('products.variants.properties_modal.save')}
        onCancel={handleCancel}
        width={700}
      >
        <PropertiesInfoMessage>{t('products.variants.properties_modal.info_message')}</PropertiesInfoMessage>
        <Form form={form} layout="vertical">
          <Form.List name="properties">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name }) => (
                  <PropertiesRowStyled key={key} gutter={10}>
                    <Col span={8}>
                      <Form.Item
                        name={[name, 'label']}
                        label={t('products.variants.properties_modal.fields.name')}
                        rules={[{ required: true }]}
                        validateStatus={has(errors, `${name}.label`) ? 'error' : undefined}
                        help={get(errors, `${name}.label`)}
                      >
                        <AsyncAutoComplete resource="product-properties/search" />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.List name={[name, 'values']}>
                        {(fields, { add, remove }) => (
                          <PropertiesListItemStyled>
                            {fields.map(({ name: valueIndex }) => (
                              <div key={valueIndex}>
                                <Form.Item name={[valueIndex, 'value']}>
                                  <Input
                                    size="small"
                                    prefix={
                                      <Form.Item name={[valueIndex, 'is_general']} valuePropName="checked">
                                        <Checkbox onChange={(e) => handleCheckboxChange(name, valueIndex, e.target.checked)} />
                                      </Form.Item>
                                    }
                                    suffix={
                                      <button onClick={() => remove(valueIndex)} type="button" className="clear-btn" aria-label="Close">
                                        <CloseOutlined />
                                      </button>
                                    }
                                  />
                                </Form.Item>
                              </div>
                            ))}
                            <span className="label">{t('products.variants.properties_modal.fields.value')}</span>
                            <button onClick={() => add({ value: '', is_general: false })} className="add-btn" type="button">
                              <PlusOutlined />
                              <span>{t('products.variants.properties_modal.add_value')}</span>
                            </button>
                          </PropertiesListItemStyled>
                        )}
                      </Form.List>
                    </Col>
                    <Button icon={<DeleteOutlined />} onClick={() => remove(name)} danger />
                  </PropertiesRowStyled>
                ))}
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  {t('products.variants.properties_modal.add_properties')}
                </Button>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

VariantsProperties.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  errors: PropTypes.array,
};

export default VariantsProperties;
