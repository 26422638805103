import styled from 'styled-components';
import { Button, Card, Checkbox, DatePicker, Drawer, Form, Input, InputNumber, List, Table, TimePicker, Typography, Col, Row } from 'antd';

const { Text } = Typography;

const colors = {
  gray: '#7D8592',
  borderColor: '#d8e0f0',
  boxShadow: 'rgba(184, 200, 224, 0.22)',
  borderCard: 'rgba(0, 0, 0, 0.07)',
  white: '#fff',
  backgroundButton: '#191919',
  black: '#000',
  blue: '#3f8cff',
  social: '#eaf2fc',
};

const fonts = {
  primary: "'Nunito Sans', sans-serif",
  textSize: '12px',
  prefixSize: '10px',
  buttonSize: '14px',
  drawerTitle: '16px',
};

const FontsStyle = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600&family=Inter:wght@400;600&family=Avenir:wght@400;600&display=swap');
`;

const OrderCard = styled(Card)`
  border: ${(props) => (props.error ? '1px solid red' : `1px solid ${colors.borderCard}`)};
  border-radius: 5px;

  .ant-card-body {
    padding: 10px 8px !important;
  }

  .ant-card-actions > li {
    margin: 0 !important;
  }

  .add-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: none;
    padding: 8px 0;
    background: ${colors.backgroundButton};
    border-radius: 0 0 5px 5px;
    cursor: pointer;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -5px;
      left: 0;
      right: 0;
      height: 10px;
      background: ${colors.white};
      border-radius: 0 0 5px 5px;
      z-index: 0;
    }

    span {
      color: ${colors.white};
      font-family: ${fonts.primary};
      font-weight: 700;
      font-size: ${fonts.buttonSize};
      margin-left: 5px;
    }
  }

  .ant-empty-image {
    height: 55px;
    margin: 0;
  }

  .ant-empty-description {
    font-size: ${fonts.textSize};
    font-family: ${fonts.primary};
    color: ${colors.gray};
  }

  .ant-table-empty {
    height: fit-content;
  }

  .line {
    background: ${colors.borderCard};
    margin: 5px 0 10px 0;
  }

  .calc-delivery-price {
    width: 100%;
    font-family: ${fonts.primary};
  }
`;

const OrderFormItem = styled(Form.Item)`
  margin-bottom: 0 !important;

  .ant-form-item-control-input .ant-picker {
    padding: 10px 5px 0 !important;
  }

  .ant-form-item-control-input {
    min-height: auto !important;
  }
  .ant-form-item-explain-error {
    font-size: ${fonts.prefixSize};
    font-family: ${fonts.primary};
  }
`;

const TitleCard = styled(Text)`
  color: ${colors.gray};
  font-family: ${fonts.primary};
  font-weight: 700;
  font-size: ${fonts.textSize};
  line-height: 1;
  margin-bottom: 5px;
  margin-left: 5px;
  display: block;

  .title {
    margin-left: 5px;
  }
`;

const InputStyle = styled(Input)`
  border: 1px solid ${colors.borderColor};
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 ${colors.boxShadow};
  background: #fff;
  padding: 10px 5px 0 5px;
  position: relative;
  font-weight: 400;
  font-size: ${fonts.textSize};
  line-height: 2;
  font-family: ${fonts.primary};

  .ant-input {
    color: ${colors.gray};
    padding-left: 0 !important;
  }

  .ant-input-prefix {
    font-weight: 700;
    font-size: ${fonts.prefixSize};
    color: ${colors.gray};
    position: absolute;
    top: 3px;
    left: 5px;
    z-index: 2;
    line-height: 1;
  }
`;

const SelectStyle = styled.div`
  width: 100%;
  position: relative;
  font-family: ${fonts.primary};

  .ant-select-dropdown {
    border-radius: 0 !important;
    background-color: #fff;
    padding: 0 !important;
  }

  .prefix {
    font-weight: 700;
    font-size: ${fonts.prefixSize};
    color: ${colors.gray};
    position: absolute;
    top: 2px;
    left: 5px;
    z-index: 2;
  }

  .ant-select {
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 ${colors.boxShadow};
    background: #fff;
    font-weight: 400;
    font-size: ${fonts.textSize};
    line-height: 2;
    color: ${colors.gray};

    .ant-select-selector {
      padding: 0 !important;
      height: 36px !important;
    }

    .ant-select-selection-item {
      padding-left: 5px !important;
      font-weight: 400;
      font-size: 12px;
    }

    .ant-select-arrow {
      right: 5px;
    }
    .ant-select-clear {
      right: 5px;
    }
    .ant-select-selection-placeholder {
      padding: 0 18px 0 5px;
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 5px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 43px;
  }

  .ant-select-dropdown {
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
  }
`;

const ListStyle = styled(List.Item)`
  padding: 0 !important;
  border-bottom: none !important;
  margin-bottom: 8px;
  position: relative;
  gap: 5px;

  .count {
    margin-left: auto;
    white-space: nowrap;
  }

  .ant-list-item-action {
    display: flex;
    gap: 10px;
    margin: 0 0 0 7px;

    li {
      padding: 0 !important;
      line-height: 1;
      font-size: 14px;
    }
    .edit {
      color: ${colors.blue};
    }

    .delete {
      color: red;
    }
  }

  .ant-list-item-action-split {
    display: none;
  }

  button {
    border: none;
    padding: 0;
    background: transparent;
    cursor: pointer;
  }
`;

const ItemInventoryStyle = styled(Table)`
  font-family: ${fonts.primary};
  border-collapse: separate;
  border-spacing: 10px;

  table {
    border-spacing: 0 5px;
  }

  thead {
    display: none;
  }

  .ant-table-tbody > tr > td {
    border: none;
  }

  .ant-table-tbody {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .ant-table-wrapper {
    border-spacing: 3px;
  }

  .ant-table-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-table-cell {
    padding: 0 !important;
  }

  .ant-table-row {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    max-width: fit-content;
    border-radius: 4px;
    padding: 4px;

    .ant-table-cell {
      border-bottom: none;
      padding: 0;
      font-size: ${fonts.textSize};
      line-height: 1;
      white-space: nowrap;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: transparent;
  }

  .ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
    display: inline-flex;
    float: none;
    vertical-align: sub;
    background: ${colors.backgroundButton};
    color: ${colors.white};
    border: none;
    width: 13px;
    height: 13px;

    &:before {
      top: 6px;
      right: 3px;
      left: 3px;
      height: 1px;
    }

    &:after {
      top: 3px;
      bottom: 3px;
      left: 6px;
      width: 1px;
    }
  }

  .ant-empty-image {
    height: 55px;
    margin: 0;
  }

  .ant-empty-description {
    font-size: ${fonts.textSize};
  }

  .ant-table-empty {
    height: fit-content;
  }

  .select-btn {
    font-family: ${fonts.primary};
    font-weight: 700;
    font-size: ${fonts.prefixSize};
    line-height: 1;
    background-color: ${colors.black};
    color: ${colors.white};
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;

    &:hover {
      background-color: ${colors.white};
      color: ${colors.black};
      border: 1px solid ${colors.borderColor};
    }

    &:disabled {
      background-color: ${colors.borderColor};
      color: ${colors.gray};
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  .ant-table-cell-fix-right {
    position: absolute !important;
  }

  .checked {
    font-size: 16px;
    color: ${colors.blue};
    margin-right: 20px;
  }
`;

const ExpandedRowRenderPriceRemainsStyle = styled(Table)`
  .ant-table {
    margin: -16px -16px -16px 0 !important;
  }

  .ant-table-row {
    gap: 10px;
  }

  .ant-table-tbody {
    margin-left: 15px;
  }

  .ant-table-tbody > tr:not(.ant-table-expanded-row):not(.ant-table-expanded-row-level-1)::before {
    content: '-';
    font-size: 20px;
    line-height: 1;
  }

  .value {
    padding: 4px;
    border-radius: 4px;
    margin-left: -3px;
    font-weight: 700;
    font-size: ${fonts.textSize};
    line-height: 1;
    color: ${colors.black};
  }
`;

const ExpandedRowRenderItemPriceRemainStyle = styled(Table)`
  .ant-table-row {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    max-width: fit-content;
    border-radius: 4px;
    padding: 0;
  }

  .ant-table {
    margin: -16px -16px -16px 16px !important;
  }

  .ant-table-cell.ant-table-cell-with-append {
    display: flex !important;
    align-items: center !important;
    padding: 4px !important;
    border-radius: 5px !important;
    background: ${(props) => (props.available ? '#91ECB2' : '#F6999B')};

    .ant-table-row-indent {
      display: none !important;
    }

    .ant-table-row-expand-icon.ant-table-row-expand-icon-spaced {
      display: none !important;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${(props) => (props.remains ? '#F6999B' : '#91ECB2')} !important;
  }

  .ant-table-tbody > tr:not(.ant-table-expanded-row):not(.ant-table-expanded-row-level-1) {
    position: relative;

    &:before {
      content: '-';
      position: absolute;
      left: -10px;
      font-size: 20px;
    }
  }

  .ant-table-tbody {
    position: relative;

    &:before {
      top: -4px;
    }
  }

  .ant-table-row-indent + .ant-table-row-expand-icon {
    margin-top: 0 !important;
    margin-right: 5px !important;
  }

  .ant-table-row-expand-icon {
    display: inline-flex !important;
    background-color: ${colors.backgroundButton} !important;
    color: ${colors.white} !important;
    border: none !important;
    width: 13px !important;
    height: 13px !important;

    &:before {
      content: '';
      position: absolute;
      top: 6px;
      right: 3px;
      left: 3px;
      height: 1px;
      background-color: ${colors.white};
    }

    &:after {
      content: '';
      position: absolute;
      top: 3px;
      bottom: 3px;
      left: 6px;
      width: 1px;
      background-color: ${colors.white};
    }
  }

  .value {
    padding: 4px;
    border-radius: 5px;
    font-family: ${fonts.primary};
    font-size: ${fonts.textSize};
    line-height: 1;
    font-weight: 700;
  }

  .item-name {
    max-width: 100px;
    white-space: normal;
    word-wrap: break-word;
  }

  .ant-spin-container {
    background: ${colors.white};
  }

  .ant-table-row-level-1 {
    margin-left: 17px !important;
  }
`;

const CheckboxStyle = styled(Checkbox)`
  font-family: ${fonts.primary};
  font-weight: 600;
  font-size: ${fonts.textSize} !important;
  color: ${colors.gray} !important;

  .ant-checkbox-inner {
    border-radius: 5px;
    width: 14px;
    height: 14px;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    transform: rotate(45deg) scale(0.9) translate(-70%, -50%);
  }
`;

const DataPickerContainer = styled.div`
  position: relative;

  .prefix {
    font-weight: 700;
    font-size: ${fonts.prefixSize};
    color: ${colors.gray};
    position: absolute;
    top: 2px;
    left: 5px;
    z-index: 2;
  }
`;

const DataPickerStyle = styled(DatePicker)`
  padding: 10px 5px 0 !important;
  font-size: ${fonts.textSize};
  font-family: ${fonts.primary};

  .ant-form-item-control-input .ant-picker {
    padding: 10px 5px 0 !important;
  }

  .ant-picker-input > input {
    font-size: ${fonts.textSize};
    font-family: ${fonts.primary};
    line-height: 2;
    color: ${colors.gray};
  }
`;

const TimePickerContainer = styled.div`
  position: relative;

  .prefix {
    font-weight: 700;
    font-size: ${fonts.prefixSize};
    color: ${colors.gray};
    position: absolute;
    top: 2px;
    left: 5px;
    z-index: 2;
  }
`;

const TimePickerStyle = styled(TimePicker)`
  padding: 10px 5px 0 !important;
  font-size: ${fonts.textSize};
  font-family: ${fonts.primary};

  .ant-picker-input > input {
    font-size: ${fonts.textSize};
    font-family: ${fonts.primary};
    line-height: 2;
    color: ${colors.gray};
  }
`;

const TotalInfoContainer = styled.div`
  position: sticky;
  bottom: 0;
  background: white;
  width: 100%;
  transition: width 0.4s linear;
  z-index: 2;
  display: flex;

    .wrapper-block {
      list-style: none;
      width: 30%;
      padding: 12px 20px;
      border: 1px solid ${colors.borderColor};

      .title-block {
        font-family: ${fonts.primary};
        font-weight: 700;
        font-size: ${fonts.textSize};
        line-height: 1.25;
        color: ${colors.black};
        display: inline-flex;
        gap: 8px;
      }

      .list-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;  
      }
        
      .item-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-family: ${fonts.primary};
          
        .item-name {
           font-weight: 700;
            line-height: 1;
        }
          
        .products-name {
            line-height: 1;
            font-size: 12px;
        }
        .products-price {
            font-size: 12px;
            line-height: 1;
            font-weight: 700;
            white-space: nowrap;
        }  

        .dots {
          flex: 1 0 0;
          border-bottom: 2px dotted rgb(0, 0, 0);
          height: 10px;
          margin: 0 0.4em;
        }

        .item-price {
          font-weight: 700;
          line-height: 1;
          white-space: nowrap;
        }
          
          .item-elem {
              margin-left: 10px;
          }
      }
    }
    
    .total-price {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .price {
        font-family: ${fonts.primary};
        font-size: 24px;
        font-weight: 700;
        line-height: 1;
        color: ${colors.blue};
        padding: 8px;
        background-color: #f5f9ff;
        border: 1px solid ${colors.borderColor};
        border-radius: 4px;
        width: fit-content;
      }
    }
  }
`;

const CheckboxContainer = styled.div`
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translateY(-50%);
  z-index: 2;

  .ant-form-item-control-input {
    min-height: 0;
  }
`;

const FooterDataPicker = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 10px;

  .time-range-container {
    display: flex;
    padding: 10px 5px;
    border: 1px solid #d8e0f0;
    border-radius: 5px;
    position: relative;

    .time-range-input {
      width: 50px;
      outline: none;
      font-family: ${fonts.primary};
      font-size: 10px;
      color: #000;
      padding-left: 20px;
      line-height: 1;
      border: none;
      cursor: pointer;
    }

    .time-range-input-from {
      position: absolute;
      font-family: ${fonts.primary};
      font-weight: 700;
      font-size: 10px;
      color: #7d8592;
      top: 50%;
      transform: translateY(-50%);
    }

    .time-range-input-to {
      position: absolute;
      font-family: ${fonts.primary};
      font-weight: 700;
      font-size: 10px;
      color: #7d8592;
      top: 50%;
      right: 38px;
      transform: translateY(-50%);
    }

    .active {
      color: ${colors.blue};
    }

    .next-time {
      width: 10px;
      height: 10px;
      padding: 0;
      border: none;
      background: #d8e0f0;
      cursor: pointer;
      color: ${colors.gray};
      position: relative;
      border-radius: 3px;

      .arrow {
        position: absolute;
        top: 34%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 10px;
      }
    }
  }
`;

const SearchClientInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .message {
    margin: 15px 0 5px 0;
    font-family: ${fonts.primary};
    font-size: 10px;
    line-height: 1.2;
    text-align: center;
    color: #c5ccd7;
    max-width: 180px;
  }

  .user-card {
    border-radius: 4px;
    background: #f5f9ff;
    padding: 6px 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    margin-top: 10px;

    .icon {
      color: ${colors.blue};
    }

    .user-name {
      font-family: ${fonts.primary};
      font-weight: 700;
      font-size: 10px;
      line-height: 1.2;
      color: ${colors.gray};
      max-width: 180px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .user-controls {
      margin-left: auto;
      display: flex;
      gap: 5px;

      button {
        background: transparent;
        border: none;
        outline: none;
        padding: 0;
        cursor: pointer;
      }

      .edit {
        color: ${colors.blue};
      }
      .delete {
        color: red;
      }
    }
  }

  .user-not-found {
    margin-top: 10px;
  }
`;

const ClientAsyncSelectStyle = styled.div`
  width: 100%;
  position: relative;
  font-family: ${fonts.primary};

  .prefix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8px;
    color: ${colors.gray};
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .ant-select-item-empty {
    padding: 5px 0 !important;
  }

  .ant-select {
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 ${colors.boxShadow};
    background: #fff;
    font-weight: 400;
    font-size: ${fonts.textSize};
    line-height: 2;
    color: ${colors.gray};

    .ant-select-dropdown-empty {
      color: red !important;
    }

    .ant-select-selector {
      padding: 0 !important;
      height: 36px !important;

      .ant-select-selection-search {
        left: 25px !important;
      }
      .ant-select-selection-search-input {
        height: 35px !important;
      }
    }

    .ant-select-selection-item {
      padding-left: 25px !important;
      font-weight: 400;
      font-size: 12px;
      line-height: 3;
    }

    .ant-select-arrow {
      right: 5px;
    }
    .ant-select-clear {
      right: 5px;
    }
    .ant-select-selection-placeholder {
      padding-left: 25px !important;
      line-height: 3;
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 5px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 43px;
  }

  .ant-form-item-explain-error {
    font-size: ${fonts.prefixSize};
    font-family: ${fonts.primary};
  }
`;

const UserEditorModalStyle = styled(Drawer)`
  z-index: 1009 !important;

  .ant-drawer-body {
    overflow-x: hidden;
  }

  .ant-drawer-header {
    background: #f8fbff;
    border: none;
  }

  .ant-drawer-title {
    font-family: ${fonts.primary};
    font-weight: 700;
    font-size: ${fonts.drawerTitle};
    line-height: 1;
    color: ${colors.black};
  }

  .social-container {
    background: ${colors.social};
    margin-top: 12px;
    padding: 20px 0;
    width: 380px;
    margin-left: -25px !important;

    .padding-left {
      padding-left: 25px !important;
    }

    .padding-right {
      padding-right: 25px !important;
    }

    .social-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: ${colors.white};
      border-radius: 4px;
      padding: 5px;

      .value {
        font-family: ${fonts.primary};
        font-weight: 700;
        font-size: ${fonts.prefixSize};
        line-height: 1.2;
        color: ${colors.gray};

        img {
          margin-right: 5px;
        }
      }
      .controls {
        display: flex;
        gap: 5px;

        button {
          background: transparent;
          border: none;
          outline: none;
          padding: 0;
        }
      }
    }

    .add-name {
      position: absolute;
      padding: 0;
      width: 12px;
      height: 12px;
      font-size: 10px;
      line-height: 0.5;
      color: ${colors.white};
      background: ${colors.blue};
      border: none;
      border-radius: 3px;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
      z-index: 2;
      cursor: pointer;
    }
  }

  .buttons-container {
    margin-top: 12px;

    .button {
      font-family: ${fonts.primary};
      font-weight: 700;
      font-size: ${fonts.prefixSize};
    }
    .save {
      color: ${colors.white};
    }
    .delete {
      color: ${colors.gray};
    }
  }

  .edit-container {
    display: flex;
    align-items: center;
    gap: 30px;

    .edit-input {
      margin-bottom: 0 !important;
      padding: 0 5px;
    }

    .save-change {
      font-family: ${fonts.primary};
      font-size: ${fonts.prefixSize};
    }
  }
`;

const GoodFormModalStyle = styled(Drawer)`
  .ant-drawer-title {
    font-family: ${fonts.primary};
    font-weight: 700;
    font-size: ${fonts.drawerTitle};
    color: ${colors.black};
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-content-wrapper {
    transform: ${(props) => props.childIsOpen && 'translateX(0)!important'};
  }

  .ant-form-item-explain-error {
    font-size: ${fonts.prefixSize};
    font-family: ${fonts.primary};
  }

  .form-modal-row {
    padding: 10px 20px 20px 20px;
    margin-right: 0 !important;
    margin-left: 0 !important;
    border: 1px solid ${colors.borderColor};
  }

  .form-controls-btns {
    padding: 20px;
    margin-right: 0 !important;
    margin-left: 0 !important;
    border: 1px solid ${colors.borderColor};
    position: fixed;
    bottom: 0;
    width: 400px;
    display: flex;
    justify-content: end;

    .form-controls-btns-container {
      display: flex;
      gap: 13px;
    }

    .btn {
      border: 1px solid #d8e0f0;
      border-radius: 3px;
      padding: 0 8px;
      box-shadow: 0 6px 12px 0 rgba(63, 140, 255, 0.1);
      background: #fff;
      font-family: ${fonts.primary};
      font-weight: 700;
      font-size: ${fonts.textSize};
      color: ${colors.blue};
    }
    .delete {
      color: red;
    }

    .save-and-continue {
      &:after {
        content: '+';
        margin-left: 5px;
      }
    }

    .save {
      background: ${colors.blue};
      color: ${colors.white};
      &:after {
        content: '\\2713';
        margin-left: 5px;
      }
    }
  }

  .title {
    font-family: ${fonts.primary};
    font-weight: 700;
    font-size: ${fonts.drawerTitle};
    color: ${colors.black};
  }

  .create-position-btn {
    font-family: ${fonts.primary};
    font-weight: 700;
    font-size: ${fonts.textSize};
    line-height: 1.2;
    color: ${colors.blue};
    width: 100%;

    &:after {
      content: '+';
      position: absolute;
      background: ${colors.blue};
      color: ${colors.white};
      padding: 0 4px;
      border-radius: 5px;
      margin-left: 5px;
    }
  }
`;

const ImageUploadStyle = styled.div`
  .ant-upload {
    padding: 0;
  }

  .ant-upload-drag {
    height: 110px;
    border: none;
  }

  .ant-upload.ant-upload-drag .ant-upload-btn {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-upload-drag-container {
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
    justify-content: center !important;
    align-items: center !important;
    font-family: ${fonts.primary};
    font-weight: 700;
    font-size: 10px;
    line-height: 1.2;
    text-align: center;
    color: rgba(63, 140, 255, 0.4);
    padding: 7px;
    width: 100%;

    .upload-img {
      max-width: 30px;
    }

    .description-text {
      max-width: 150px;
    }
  }

  .uploaded-image {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;

    img {
      width: 99px;
      height: 99px;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    button {
      border-radius: 4px;
      border: 1px solid ${colors.borderCard};
      outline: none;
      background: ${colors.white};
      font-family: ${fonts.primary};
      font-weight: 700;
      font-size: ${fonts.textSize};
      line-height: 1.2;
      color: ${colors.gray};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      padding: 14px 39px;
      cursor: pointer;

      &:hover {
        .change-icon.anticon svg {
          color: ${colors.blue};
        }
        .delete-icon.anticon svg {
          color: red;
        }
      }
    }

    .change-btn {
      &:hover {
        color: ${colors.blue};
      }
    }

    .delete-button {
      &:hover {
        color: red;
      }
    }
  }
`;

const ItemFormStyled = styled('div')`
  margin-bottom: 5px;

  .ant-drawer-header {
    background: #e5f0ff;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .title {
    font-family: ${fonts.primary};
    font-weight: 700;
    font-size: ${fonts.drawerTitle};
    color: ${colors.black};
    line-height: 1.4;
  }

  .ant-drawer-title {
    font-family: ${fonts.primary};
    font-weight: 700;
    font-size: ${fonts.drawerTitle};
    color: ${colors.black};
  }

  .form-modal-row {
    padding: 10px 20px 20px;
    margin-right: 0 !important;
    margin-left: 0 !important;
    border: 1px solid ${colors.borderColor};
  }

  .save {
    width: 100%;
    font-family: ${fonts.primary};
    font-size: ${fonts.textSize};
    font-weight: 700;
  }

  .ant-drawer-content-wrapper {
    transform: translate(-400px);
    transition: all 0.3s ease !important;
  }
  .ant-drawer-content-wrapper-hidden {
    overflow: hidden !important;
    display: block !important;
    transform: translate(400px);
  }

  .ant-drawer-panel-motion-left-enter-start,
  .ant-drawer-panel-motion-left-appear-start,
  .ant-drawer-panel-motion-left-leave-start {
    transition: all 0.3s ease !important;
  }

  .ant-empty-image {
    height: 55px;
    margin: 0;
  }

  .ant-empty-description {
    font-size: ${fonts.textSize};
    font-family: ${fonts.primary};
    color: ${colors.gray};
  }

  .ant-table-empty {
    height: fit-content;
  }
`;

const CommentsTextAreaStyle = styled.div`
  position: relative;

  .ant-input {
    border: 1px solid ${colors.borderColor};
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 ${colors.boxShadow};
    background: #fff;
    padding: 17px 5px 0 5px;
    position: relative;
    font-weight: 400;
    font-size: ${fonts.textSize};
    line-height: 1;
    font-family: ${fonts.primary};
    resize: none;
    height: 75px;
    color: ${colors.gray};
  }

  .prefix {
    font-family: ${fonts.primary};
    font-weight: 700;
    font-size: ${fonts.prefixSize};
    color: ${colors.gray};
    position: absolute;
    top: 2px;
    left: 5px;
    z-index: 2;
  }
`;

const GoodFormListItem = styled(List.Item)`
  background: ${colors.white};
  padding: 8px;
  border-radius: 4px;
  border: ${(props) => (props.error ? '1px solid red' : `1px solid ${colors.borderColor}`)};
  margin-bottom: 5px;
  position: relative;

  & {
    border-bottom: ${(props) => (props.error ? '1px solid red' : `1px solid ${colors.borderColor}`)};
  }

  &:last-child {
    border-bottom: ${(props) => (props.error ? '1px solid red' : `1px solid ${colors.borderColor}`)};
  }

  .ant-list-item-action {
    margin-left: 10px;
    display: flex;
    gap: 5px;

    button {
      border: none;
      background: transparent;
      padding: 0;
      cursor: pointer;
    }
  }

  .ant-list-item-action > li {
    padding: 0;

    &:first-child {
      padding-left: 5px;
    }
  }

  .ant-list-item-action-split {
    display: none;
  }

  .item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;

    span {
      font-family: ${fonts.primary};
      font-weight: 700;
      font-size: ${fonts.prefixSize};
      line-height: 1.2;
      color: ${(props) => (props.error ? 'red' : `${colors.gray}`)};

      &:last-of-type {
        font-weight: 600;
      }
    }

    .item-line {
      position: absolute;
      right: -5px;
      width: 1px;
      height: 40px;
      background-color: ${colors.borderColor};
    }
  }

  .edit {
    color: ${colors.blue};
  }

  .delete {
    color: red;
  }
`;

const GoodsAsyncSelectStyle = styled.div`
  .ant-select-dropdown {
    border-radius: 0 !important;
    background-color: #fff;
    padding: 0 !important;
  }

  .ant-select-item-group {
    font-family: ${fonts.primary};
    font-weight: 700;
    background: rgb(243, 242, 242);
  }

  .ant-select-item-option-grouped {
    padding-left: 20px !important;
  }

  .ant-select-item.ant-select-item-option-selected[aria-selected='true'] {
    position: relative;

    &::before {
      content: '✓';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      color: #1890ff;
      font-size: 16px;
      line-height: 1;
    }
  }

  .products-desc {
    display: flex;
    width: 100%;
    font-family: ${fonts.primary};
    font-size: ${fonts.textSize};

    &.disabled {
      opacity: 0.4;
    }

    .value {
      background: #f8f8f8;
      padding: 2px 5px;
      border-radius: 5px;
      border: 1px solid ${colors.borderColor};
    }

    .price-and-remains {
      margin-left: auto;
      display: flex;
      gap: 10px;

      .price {
        font-weight: 700;
      }

      .remains {
        font-weight: 700;
      }

      .danger {
        color: red;
      }
    }
  }

  .divider-head {
    font-size: ${fonts.textSize};
    font-family: ${fonts.primary};
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 0;
    border-width: 2px;
    border-color: ${colors.blue};
    color: ${colors.gray};
  }

  .type-container {
    padding: 0 10px 5px 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-bottom: 2px solid rgba(63, 140, 255, 0.5);
  }
`;

const ClientPhoneSearchContainer = styled.div`
  .ant-select-dropdown {
    border-radius: 0 !important;
    background-color: #fff;
    padding: 0 !important;
    box-shadow: none !important;
  }

  .ant-select-item-empty {
    padding: 5px 0 !important;
  }

  .not-found-container {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .not-found-message {
      font-family: 'Nunito Sans', sans-serif;
      font-size: 10px;
      line-height: 1.2;
      text-align: center;
      color: #7d8592;
    }
    .create-user-btn {
      font-family: 'Nunito Sans', sans-serif;
      background: black;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      font-weight: 700;
      font-size: 10px;
      line-height: 100%;
      text-align: center;
      color: #fff;
      padding: 3px 0;
    }
  }
`;

const InputNumberStyle = styled(InputNumber)`
  padding: 10px 5px 0;
  font-size: 12px;
  line-height: 2;
  height: auto;
  width: 100%;

  .ant-input {
    color: ${colors.gray};
  }

  .ant-input-number-input {
    padding: 0;
    height: auto;
  }

  .ant-input-number-handler-up,
  .ant-input-number-handler-down {
    transform: none !important;
    border-radius: 4px;
    background-color: #eaf2fc;
    border: none !important;

    &:hover {
      height: 50% !important;
      box-shadow: 0 0 4px 0 ${colors.blue};
    }
  }

  .ant-input-number-handler-wrap {
    width: 14px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    top: -8px;
  }

  .ant-input-number-handler-wrap:hover .ant-input-number-handler {
    height: 50%;
  }

  .ant-input-number-handler {
    padding: 7px;
  }

  .ant-input-number-handler-up-inner,
  .ant-input-number-handler-down-inner {
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px !important;
    color: ${colors.blue};
    margin: 0;
  }

  .ant-input-number-prefix {
    font-weight: 700;
    font-size: ${fonts.prefixSize};
    color: ${colors.gray};
    position: absolute;
    top: 2px;
    left: 5px;
    z-index: 2;
    line-height: 1;
  }

  .ant-input-number {
    padding: 0;
    color: ${colors.gray};
  }
`;

const SaveButton = styled(Button)`
  font-family: ${fonts.primary};
  box-shadow: #00000038 0 0 20px 5px;
`;

const ErrorMessage = styled.span`
  position: ${(props) => (props.position ? 'absolute' : 'initial')};
  top: 17px;
  font-family: ${fonts.primary};
  font-size: 10px;
  color: red;
`;

const NeedCallModalErrorMessage = styled.p`
  color: red;
  font-weight: 600;
`;

const NeedCallModalWrapperButton = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
`;

const CreateOrderFooterContainer = styled(Row)`
  background: #ffffff;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  z-index: 2;
  border: 1px solid #f6f6f6;
`;

const CreateOrderFooterElement = styled(Col)`
  position: relative;
  padding: 20px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: #f6f6f6;
  }

  &:last-child::before {
    content: none;
  }
`;

const CreateOrderFormTitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  color: #000;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  .icon {
    color: #72825e;
  }
`;

const CreateOrderFormList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 85px;
  overflow-y: auto;
  padding-right: 16px;
  box-sizing: content-box;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #cce3ad;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #505b40;
    border-radius: 10px;
  }

  li {
    display: flex;
    justify-content: space-between;
    position: relative;

    span {
      position: relative;
      z-index: 2;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.25;
      color: #000;
      background: #fff;
      padding-right: 5px;
    }

    span:last-of-type {
      padding-left: 5px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 5px;
      border-bottom: 2px dotted black;
      z-index: 1;
    }
  }
`;

const CreateOrderFormPrice = styled.span`
  font-weight: 700;
  font-size: 32px;
  line-height: 1.25;
  color: #000;
`;

const LinkInputContainer = styled.div`
  display: flex;
  align-items: center;

  .label {
    font-family: ${fonts.primary};
    font-size: 10px;
    margin-right: 5px;
    font-weight: 700;
    color: ${colors.gray};
  }

  button {
    height: 36px;
    margin-left: 5px;
    color: ${colors.gray};
  }

  .navigate {
    width: 32px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid ${colors.borderColor};
    color: ${colors.gray};
    margin-left: 5px;
    transition: all 0.3s ease;

    &:hover {
      color: #8c94ff;
      border-color: #8c94ff;
    }
  }
`;

const LinkInputStyle = styled(Input)`
  padding: 7.59px 5px;
  border: 1px solid ${colors.borderColor};
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 ${colors.boxShadow};
  background: #fff;
  font-weight: 400;
  font-size: ${fonts.textSize};
  font-family: ${fonts.primary};
  color: ${colors.gray};
`;

const ShortModalList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 0 0 20px;
  list-style: none;
  counter-reset: item;

  .product-items {
    position: relative;

    &::before {
      content: counter(item) '.';
      counter-increment: item;
      position: absolute;
      top: 1px;
      left: -20px;
      font-weight: bold;
    }

    .good-items {
      font-size: 12px;
    }

    .price {
      text-align: center;
      white-space: nowrap;
    }

    .qty {
      text-align: end;
      white-space: nowrap;

      strong {
        margin-left: 10px;
      }
    }
  }
`;

const NameColorCommentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .name-and-color_container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .color-container {
      font-size: 10px;
      padding: 0 5px;
      font-weight: bold;
      border-radius: 5px;
      color: #d46b08;
      border: 1px solid #ffd591;
      background: #fff7e6;
    }
  }

  .comment-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 30px;

    .show-comment {
      padding: 0 5px;
      font-size: 10px;
      height: fit-content;
    }
  }
`;

const CommentTableBlock = styled.div`
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export {
  FontsStyle,
  OrderCard,
  OrderFormItem,
  TitleCard,
  InputStyle,
  SelectStyle,
  ListStyle,
  ItemInventoryStyle,
  ExpandedRowRenderPriceRemainsStyle,
  ExpandedRowRenderItemPriceRemainStyle,
  CheckboxStyle,
  DataPickerContainer,
  DataPickerStyle,
  TimePickerContainer,
  TimePickerStyle,
  TotalInfoContainer,
  CheckboxContainer,
  FooterDataPicker,
  SearchClientInputContainer,
  ClientAsyncSelectStyle,
  UserEditorModalStyle,
  GoodFormModalStyle,
  InputNumberStyle,
  ImageUploadStyle,
  ItemFormStyled,
  CommentsTextAreaStyle,
  GoodFormListItem,
  GoodsAsyncSelectStyle,
  ClientPhoneSearchContainer,
  SaveButton,
  ErrorMessage,
  NeedCallModalErrorMessage,
  NeedCallModalWrapperButton,
  CreateOrderFooterContainer,
  CreateOrderFormTitle,
  CreateOrderFormList,
  CreateOrderFormPrice,
  CreateOrderFooterElement,
  LinkInputContainer,
  LinkInputStyle,
  ShortModalList,
  NameColorCommentContainer,
  CommentTableBlock,
};
