import React, { useId } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { OrderFormItem, SelectStyle } from '@/components/Order/styled';

const OrderFormItemSelect = ({ prefix, name, options, error, required = false, disabled = false }) => {
  const containerID = useId();
  return (
    <SelectStyle id={containerID}>
      <span className="prefix">{prefix}</span>
      <OrderFormItem required={required} validateStatus={error ? 'error' : false} help={error} name={name}>
        <Select options={options} getPopupContainer={() => document.getElementById(containerID)} disabled={disabled} />
      </OrderFormItem>
    </SelectStyle>
  );
};

OrderFormItemSelect.propTypes = {
  prefix: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  options: PropTypes.array,
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default OrderFormItemSelect;
