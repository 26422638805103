import { useEffect } from 'react';
import { Avatar, Popover } from 'antd';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import Heading from '../../heading/heading';
import { useAuth } from '@/helpers/userContext';
import { getGravatarUrl } from '@/helpers/getGravatarImageUrl';

function AuthInfo() {
  const { user, logout } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.' || e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <figcaption>
            <Heading as="h5">{user.full_name}</Heading>
            <p>{user.role_name}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="/account">
              <FeatherIcon icon="user" /> {t('profile')}
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={logout} to="#">
          <FeatherIcon icon="log-out" /> {t('signout')}
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src={getGravatarUrl(user.email)} />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
}

export default AuthInfo;
