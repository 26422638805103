import { Input } from 'antd';
import PropTypes from 'prop-types';
import { CommentsTextAreaStyle } from '@/components/Order/styled';

const { TextArea } = Input;

const OrderCommentsTextArea = ({ prefix, ...props }) => {
  return (
    <CommentsTextAreaStyle>
      <TextArea {...props} row={4} />
      <span className="prefix">{prefix}</span>
    </CommentsTextAreaStyle>
  );
};

OrderCommentsTextArea.propTypes = {
  prefix: PropTypes.string,
};

export default OrderCommentsTextArea;
