import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { OrderFormItem, InputStyle } from '@/components/Order/styled';

const OrderPhoneInput = ({ name, prefix, error }) => {
  return (
    <OrderFormItem name={name} validateStatus={error ? 'error' : false} help={error}>
      <InputMask mask="+38(099)999-99-99" maskPlaceholder="">
        <InputStyle prefix={prefix} />
      </InputMask>
    </OrderFormItem>
  );
};

OrderPhoneInput.propTypes = {
  name: PropTypes.string,
  prefix: PropTypes.string,
  error: PropTypes.array,
};

export default OrderPhoneInput;
