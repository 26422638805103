import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReviewModalStyled } from '@/components/Product/style';
import ApiClient from '@/helpers/apiClient/ApiClient';
import ReviewCard from '@/components/Product/ReviewCard';

const ReviewModal = ({ reviewId, onCancel, onSuccess }) => {
  const { t } = useTranslation();
  const [reviewData, setReviewData] = useState({});

  useEffect(() => {
    if (!reviewId) return;

    ApiClient.call('get', `product-reviews/${reviewId}`).data(({ data }) => {
      setReviewData(data);
    });
  }, [reviewId]);

  const onApproveComment = (onFinish) => {
    const { id, moderated } = reviewData;

    if (moderated) {
      return;
    }

    ApiClient.call('post', `/products/reviews/${id}/submit`, {}, reviewData)
      .data(() => {
        setReviewData((prev) => {
          return prev.id === id ? { ...prev, moderated: true } : prev;
        });
      })
      .finally(() => {
        onFinish();
        onSuccess();
      });
  };

  return (
    <ReviewModalStyled
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>Відгук</span>
          <Link to={`/cms/products/product-positions/${reviewData.product_id}`} target="_blank" className="link-product">
            {t('products.product_reviews.product_link')}
          </Link>
        </div>
      }
      open={Boolean(reviewId)}
      footer={null}
      width={700}
      onCancel={onCancel}
    >
      <ReviewCard review={reviewData} onApprove={onApproveComment} />
    </ReviewModalStyled>
  );
};

ReviewModal.propTypes = {
  reviewId: PropTypes.number,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
};

export default ReviewModal;
