import React, { useEffect, useId, useMemo, useState } from 'react';
import { Divider, Select } from 'antd';
import CheckableTag from 'antd/es/tag/CheckableTag';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { get } from 'lodash';
import ApiClient from '@/helpers/apiClient/ApiClient';
import { GoodsAsyncSelectStyle, SelectStyle } from '@/components/Order/styled';
import priceFormat from '@/helpers/priceFormat';
import { BOX, FLOWER, PAPER, PLANT, POSTCARD, SWEET, TAPE, TOY } from '@/constants/goods';

const productTypes = [
  { value: FLOWER },
  { value: TOY },
  { value: POSTCARD },
  { value: PLANT },
  { value: SWEET },
  { value: PAPER },
  { value: TAPE },
  { value: BOX },
];

const GoodsAsyncSelect = ({ value, onChange, prefix, storeId, cityId, onStoreChange, setName, setPrice }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [currProductType, setCurrProductType] = useState(FLOWER);

  const containerID = useId();

  const onSearch = useMemo(() => {
    const loadOptions = (text = '') => {
      setLoading(true);
      ApiClient.call('get', `warehouse/goods/search-with-inventory`, { city_id: cityId, types: currProductType ? [currProductType] : null, q: text })
        .data(({ data }) => {
          setOptions(data);
        })
        .catch(() => {
          setOptions([]);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return debounce(loadOptions, 300);
  }, [cityId, currProductType]);

  useEffect(() => {
    onSearch();
  }, [onSearch]);

  useEffect(() => {
    if (value && storeId) {
      setSelected({ value: `${value}-${storeId}` });
    } else {
      setSelected(null);
    }
  }, [value, storeId]);

  useEffect(() => {
    if (value) {
      ApiClient.call('get', `warehouse/goods/search-with-inventory`, { id: value, city_id: cityId }).data(({ data }) => {
        setCurrProductType(get(data, '0.type'));
        setOptions(data);
      });
    }
  }, [value, cityId]);

  return (
    <SelectStyle>
      <GoodsAsyncSelectStyle id={containerID}>
        <Select
          showSearch
          loading={loading}
          filterOption={false}
          onSearch={onSearch}
          dropdownRender={(menu) => (
            <>
              <Divider orientation="left" className="divider-head">
                {t('orders.product_type')}
              </Divider>
              <div className="type-container">
                {productTypes.map(({ value }) => (
                  <CheckableTag
                    key={value}
                    checked={currProductType === value}
                    onClick={() => {
                      setCurrProductType(value);
                    }}
                  >
                    {t(`orders.products_types.${value}`)}
                  </CheckableTag>
                ))}
              </div>
              {menu}
            </>
          )}
          getPopupContainer={() => document.getElementById(containerID)}
          onChange={(selected) => {
            if (!selected) {
              onChange(null);
              onSearch();
              setPrice(undefined);
              setName('');
            } else {
              const [good_id, store_id, price] = selected.key.split('-');
              onChange(good_id);
              onStoreChange(store_id);
              setPrice(price);
              setName(selected.title);
            }
          }}
          allowClear
          value={selected}
          labelInValue
          optionLabelProp="title"
        >
          {options.map(({ id, name, inventories }) => {
            return (
              <Select.OptGroup label={name} key={id}>
                {inventories.map(({ store_id, store_name, price, remains }) => {
                  const disabled = remains <= 0;
                  return (
                    <Select.Option value={`${id}-${store_id}`} key={`${id}-${store_id}-${price}`} title={name} disabled={disabled}>
                      <div className={classNames('products-desc', { disabled })}>
                        <span className="value store-name">{store_name}</span>
                        <div style={{ marginLeft: 'auto' }} className="price-and-remains">
                          <span className={classNames('value remains', { danger: disabled })}>{remains} шт</span>
                          <span className="value price">{priceFormat(price, '₴')} / шт</span>
                        </div>
                      </div>
                    </Select.Option>
                  );
                })}
              </Select.OptGroup>
            );
          })}
        </Select>
      </GoodsAsyncSelectStyle>

      <span className="prefix">{prefix}</span>
    </SelectStyle>
  );
};

GoodsAsyncSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  prefix: PropTypes.string,
  cityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onStoreChange: PropTypes.func,
  setName: PropTypes.func,
  setPrice: PropTypes.func,
};

export default GoodsAsyncSelect;
