import { DeleteOutlined, DropboxOutlined, FormOutlined, ShopTwoTone, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { get, has, values } from 'lodash';
import { ItemFormStyled, OrderFormItem, GoodFormModalStyle, ErrorMessage, GoodFormListItem } from '@/components/Order/styled';
import InputOrder from '@/components/Order/InputOrder';
import OrderCommentsTextArea from '@/components/Order/OrderCommentsTextArea';
import GoodsAsyncSelect from '@/components/Order/GoodsAsyncSelect';
import OrderMoneyInput from '@/components/Order/OrderMoneyInput';

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: "${label} є обов'язковим!",
};

const hiddenLabel = { style: { display: 'none' } };

const getErrorMessages = (errorData, index, itemIndex) => {
  const itemErrors = get(errorData, `products.${index}.items.${itemIndex}`);
  const messages = itemErrors ? values(itemErrors) : [];

  return messages.map((messageError, idx) => (
    <ErrorMessage key={idx} position style={{ top: `${22 + idx * 22}px` }}>
      {messageError}
    </ErrorMessage>
  ));
};

const ItemForm = ({ value = {}, onChange, onClose, onRemove, cityId, storeId, onStoreChange, errorData, indexProduct, index }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const cancel = () => {
    if (value.isNew) {
      onRemove();
    }
    onClose();
  };

  const submit = (values) => {
    onChange(values);
    onClose();
  };

  return (
    <ItemFormStyled>
      <Form initialValues={value} form={form} validateMessages={validateMessages} onFinish={submit}>
        <Row gutter={[10, 10]} className="form-modal-row">
          <Col span={24}>
            <OrderFormItem name="good_id" label={t('orders.order_modal.good')} rules={[{ required: true }]} labelCol={hiddenLabel}>
              <GoodsAsyncSelect
                prefix={t('orders.order_modal.good')}
                storeId={storeId}
                cityId={cityId}
                onStoreChange={onStoreChange}
                setName={(name) => {
                  form.setFieldValue('name', name);
                }}
                setPrice={(price) => {
                  form.setFieldValue('price', price);
                }}
              />
            </OrderFormItem>
          </Col>

          <Col span={24}>
            <InputOrder
              name="name"
              label={t('orders.order_modal.name')}
              prefix={t('orders.order_modal.name')}
              rules={[{ required: true }]}
              error={get(errorData, `products.${indexProduct}.items.${index}.name`)}
            />
          </Col>

          <Col span={12}>
            <InputOrder
              prefix={t('orders.order_modal.count')}
              type="number"
              name="qty"
              label={t('orders.order_modal.count')}
              rules={[{ required: true }]}
              error={get(errorData, `products.${indexProduct}.items.${index}.remains`)}
            />
          </Col>

          <Col span={12}>
            <OrderFormItem
              name="price"
              label={t('orders.order_modal.price')}
              labelCol={hiddenLabel}
              validateStatus={has(errorData, `products.${indexProduct}.items.${index}.price`) ? 'error' : false}
              help={get(errorData, `products.${indexProduct}.items.${index}.price`)}
            >
              <OrderMoneyInput prefix={t('orders.order_modal.price')} />
            </OrderFormItem>
          </Col>
          <Col span={24}>
            <InputOrder name="color" prefix={t('orders.order_modal.color')} />
          </Col>
          <Col span={24}>
            <OrderFormItem name="comment">
              <OrderCommentsTextArea prefix={t('orders.order_modal.comment')} />
            </OrderFormItem>
          </Col>
          <Col span={12}>
            <Button type="primary" className="save" onClick={form.submit}>
              {t('orders.order_modal.save')}
            </Button>
          </Col>
          <Col span={12}>
            <Button type="secondary" className="save" onClick={cancel}>
              {t('orders.order_modal.cancel')}
            </Button>
          </Col>
        </Row>
      </Form>
    </ItemFormStyled>
  );
};

ItemForm.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onRemove: PropTypes.func,
  cityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onStoreChange: PropTypes.func,
  errorData: PropTypes.object,
  indexProduct: PropTypes.number,
  index: PropTypes.number,
};

const GoodFormModal = ({ value, index, onClose, onChange, onDelete, cityId, storeId, errorData }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [activeItem, setActiveItem] = useState(null);
  const [tempStore, setTempStore] = useState(storeId);

  useEffect(() => {
    form.setFieldsValue(value);
  }, [value, form]);

  useEffect(() => {
    setTempStore(storeId);
  }, [storeId]);

  const editItem = useCallback(
    (name, onRemove) => {
      setActiveItem({
        index: name,
        value: form.getFieldValue(['items', name]),
        onChange: (values) => form.setFieldValue(['items', name], values),
        onRemove,
      });
    },
    [form],
  );

  const cancel = () => {
    if (value.isNew) {
      onDelete();
    }
    onClose();
  };

  return (
    <GoodFormModalStyle
      forceRender
      title={
        <>
          <ShopTwoTone style={{ marginRight: '10px' }} />
          {t('orders.order_modal.store')}
        </>
      }
      width={400}
      open={Boolean(value)}
      onClose={cancel}
      destroyOnClose
    >
      <Form
        preserve={false}
        form={form}
        initialValues={value}
        validateMessages={validateMessages}
        validateTrigger="onSubmit"
        onFinish={(values) => {
          onChange(values, tempStore);
          onClose();
        }}
      >
        <Row gutter={[10, 10]} className="form-modal-row">
          <Col span={24}>
            <div className="title">
              <DropboxOutlined style={{ marginRight: '10px', color: '#3f8cff' }} />
              <span className="title">{t('orders.order_modal.good')}</span>
            </div>
          </Col>
          <Col span={16}>
            <InputOrder prefix={t('orders.order_modal.name')} name="name" label={t('orders.order_modal.name')} rules={[{ required: true }]} />
          </Col>
          <Col span={8}>
            <InputOrder prefix={t('orders.order_modal.count')} type="number" name="count" rules={[{ required: true }]} label={t('orders.order_modal.count')} />
          </Col>
        </Row>

        <Row gutter={[10, 10]} className="form-modal-row" style={{ background: '#f8fbff' }}>
          <Col span={24}>
            <div className="title">
              <UnorderedListOutlined style={{ marginRight: '10px', color: '#3f8cff' }} />
              <span className="title">{t('orders.order_modal.position_list')}</span>
            </div>
          </Col>
          <Col span={24}>
            <Form.List
              name="items"
              rules={[
                {
                  type: 'array',
                  validator: async (_, items) => {
                    if (!items || items.length === 0) {
                      return Promise.reject(new Error(t('orders.order_modal.reject_message')));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              {(fields, { remove, add }, { errors }) => {
                return (
                  <>
                    {fields.map(({ name }) => {
                      if (form.getFieldValue(['items', name, 'isNew'])) return;

                      return (
                        <GoodFormListItem
                          key={name}
                          error={get(errorData, `products.${index}.items.${name}`)}
                          actions={[
                            <button type="button" onClick={() => editItem(name, () => remove(name))} aria-label="Edit product" key={0}>
                              <FormOutlined className="edit" />
                            </button>,
                            <button type="button" onClick={() => remove(name)} aria-label="Delete product" key={1}>
                              <DeleteOutlined className="delete" />
                            </button>,
                          ]}
                        >
                          <div className="item-container">
                            <span>{form.getFieldValue(['items', name, 'name'])}</span>
                            <span>{form.getFieldValue(['items', name, 'qty'])} шт</span>
                            <em className="item-line" />
                          </div>
                          {getErrorMessages(errorData, index, name)}
                        </GoodFormListItem>
                      );
                    })}
                    {activeItem === null && (
                      <Col span={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Button
                          htmlType="button"
                          className="create-position-btn"
                          onClick={() => {
                            const index = fields.length;
                            add({ isNew: true }, index);
                            editItem(index, () => remove(index));
                          }}
                        >
                          {t('orders.order_modal.add_position')}
                        </Button>
                      </Col>
                    )}
                    <Form.ErrorList errors={errors} />
                  </>
                );
              }}
            </Form.List>
          </Col>
        </Row>
      </Form>

      {activeItem ? (
        <ItemForm
          cityId={cityId}
          storeId={tempStore}
          onStoreChange={setTempStore}
          onClose={() => setActiveItem(null)}
          {...activeItem}
          errorData={errorData}
          indexProduct={index}
        />
      ) : (
        <Row gutter={[10, 10]} className="form-controls-btns">
          <div className="form-controls-btns-container">
            <Button
              className="btn delete"
              onClick={() => {
                onDelete();
                onClose();
              }}
            >
              <DeleteOutlined />
            </Button>

            <Button className="btn save" onClick={form.submit}>
              {t('orders.order_modal.save')}
            </Button>
          </div>
        </Row>
      )}
    </GoodFormModalStyle>
  );
};

GoodFormModal.propTypes = {
  onClose: PropTypes.func,
  value: PropTypes.any,
  index: PropTypes.number,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  cityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorData: PropTypes.object,
};

export default GoodFormModal;
