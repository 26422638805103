import styled from 'styled-components';
import { Form } from 'antd';

const GoodsFormStyle = styled(Form)`
  .ant-form-item-explain {
    margin-top: 2px;
    font-size: 10px;
  }
`;

const GoodsFormList = styled.div`
  border: 1px solid #e3e6ef;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
  height: 100%;

  .title {
    font-size: 14px;
  }
`;

const GoodsFormListItem = styled.div`
  background: #f2f4f6;
  padding: 10px;
  display: flex;
  gap: 10px;
  align-items: end;
  justify-content: space-evenly;

  .goods-form-item {
    margin-bottom: 0 !important;

    .ant-form-item-control-input {
      min-height: auto;
    }

    .ant-form-item-label {
      padding: 0;
      font-size: 10px;
    }

    .ant-form-item-label > label {
      font-size: 12px;
    }
  }

  .ant-btn {
    height: 29px;
    padding: 0 8px;
  }
`;

const GoodsTableWrapper = styled.div`
  margin: 20px 0;
  position: relative;

  .action-button {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

const GoodsModalTitle = styled.h2`
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
`;

export { GoodsFormStyle, GoodsFormList, GoodsFormListItem, GoodsTableWrapper, GoodsModalTitle };
