import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import withAuthLayout from '@/layouts/withAuthLayout';
import { PageHeader } from '@/components/page-headers/page-headers';
import { CardToolbox, Main, TopToolBox } from '@/container/styled';
import Table from '@/widgets/Table/Table';
import { AutoComplete } from '@/components/autoComplete/autoComplete';

const GoodsIndex = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo(() => [{ title: t('warehouse.goods.name_goods'), dataIndex: 'name', key: 'name' }], []);

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('warehouse.goods.header_title')}
          buttons={[
            <Button className="btn-add_new" size="default" type="primary" key="1">
              <Link to="/warehouse/goods/create">+ {t('warehouse.goods.create_button')}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Row gutter={24}>
          <Col xs={8}>
            <TopToolBox>
              <AutoComplete
                placeholder={t('warehouse.goods.search_to_name')}
                searchUrl="warehouse/goods/search"
                onSelect={(value) => navigate(`/warehouse/goods/${value}`)}
                width="100%"
                patterns
              />
            </TopToolBox>
          </Col>
        </Row>
        <Table route="warehouse/goods" resource="warehouse/goods" columns={columns} permissionNamespace="warehouse.goods" />
      </Main>
    </>
  );
};

export default withAuthLayout(GoodsIndex);
