import { useTranslation } from 'react-i18next';
import { Button, Col, Empty, Row } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { DeleteOutlined, EyeOutlined, FileImageTwoTone, FolderAddTwoTone, StarFilled, StarOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { CardProductImagesStyled, ProductCardFileStyled, ProductImagesPreviewStyled, ProductImagesRowStyled } from '@/components/Product/style';
import useFileUploader, { MAX_FILES } from '@/hooks/useFileUpload';
import ProgressBar from '@/components/Product/ProgressBar';

const ProductFileListItem = ({ file, togglePrimary, isActive, onPreview, handleDelete, loading }) => {
  const imageUrl = file.url || (file.originFileObj && URL.createObjectURL(file.originFileObj));

  return (
    <div>
      <ProductCardFileStyled active={Number(isActive)} primary={Number(file.is_primary)}>
        <img src={imageUrl} alt={file.name} />

        {!file.uploaded && <ProgressBar file={file} />}

        <div className="card-file-actions">
          <Button onClick={() => togglePrimary(file)} icon={file.is_primary ? <StarFilled /> : <StarOutlined />} className="main" />
          <Button icon={<EyeOutlined />} onClick={() => onPreview(file)} className="view" />
          <Button icon={<DeleteOutlined />} onClick={() => handleDelete(file.uid)} className="delete" disabled={loading} />
        </div>
      </ProductCardFileStyled>
    </div>
  );
};

ProductFileListItem.propTypes = {
  file: PropTypes.object,
  togglePrimary: PropTypes.func,
  isActive: PropTypes.bool,
  onPreview: PropTypes.func,
  handleDelete: PropTypes.func,
  loading: PropTypes.bool,
};

const ProductImages = ({ value = [], onChange }) => {
  const { t } = useTranslation();

  const { previewSrc, fileList, handleChange, onPreview, filesUploaded, toggleIsPrimary, handleDelete, onUpload, hasErrors, loading } = useFileUploader(
    value,
    'products',
  );

  useEffect(() => {
    if (filesUploaded) {
      onChange(fileList.map(({ id, url, is_primary }) => ({ id, url, is_primary })));
    }
  }, [filesUploaded, fileList, hasErrors]);

  return (
    <CardProductImagesStyled
      title={t('products.image')}
      actions={
        filesUploaded
          ? []
          : [
              <Button onClick={onUpload} disabled={loading} loading={loading}>
                {loading ? t('products.variant_images.uploading') : t('products.variant_images.save')}
              </Button>,
            ]
      }
    >
      <Row gutter={[15, 30]}>
        <Col span={12}>
          <Dragger beforeUpload={() => false} multiple showUploadList={false} onChange={handleChange} fileList={fileList}>
            <FolderAddTwoTone style={{ fontSize: '75px' }} />
            <p className="ant-upload-text" style={{ padding: '0 10px' }}>
              {t('products.variant_images.dragger_message')}
            </p>
          </Dragger>
        </Col>
        <Col span={12}>
          <ProductImagesPreviewStyled>
            <h3 className="preview-title">{t('products.variant_images.review_images')}</h3>

            {previewSrc ? (
              <img src={previewSrc} alt="buket" style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
            ) : (
              <div className="not-view-image">
                <FileImageTwoTone style={{ fontSize: '75px' }} />
                <span>{t('products.variant_images.not_view_image_message')}</span>
              </div>
            )}
          </ProductImagesPreviewStyled>
        </Col>
        <Col span={24}>
          <ProductImagesRowStyled gutter={[16, 16]}>
            <h3 className="upload-title">{t('products.variant_images.title_list_file')}</h3>
            <span className="upload-qty">
              {t('products.variant_images.added')} {fileList.length}/{MAX_FILES}
            </span>

            {fileList.map((file, i) => {
              return (
                <Col span={8} key={i}>
                  <ProductFileListItem
                    file={file}
                    togglePrimary={toggleIsPrimary}
                    isActive={file.url === previewSrc}
                    onPreview={onPreview}
                    handleDelete={handleDelete}
                    loading={loading}
                  />
                </Col>
              );
            })}

            {fileList.length === 0 && (
              <div className="empty-wrapper">
                <Empty />
              </div>
            )}
          </ProductImagesRowStyled>
        </Col>
      </Row>
    </CardProductImagesStyled>
  );
};

ProductImages.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default ProductImages;
