import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import propTypes from 'prop-types';
import priceFormat from '@/helpers/priceFormat';

const PaymentInfoCard = ({ order }) => {
  const { t } = useTranslation();

  const paymentDetails = [
    {
      label: t('orders.type'),
      value: t(`orders.payment_types.${order.payment_type}`),
    },
    {
      label: t('orders.status'),
      value: t(`orders.payment_statuses.${order.payment_status}`),
    },
    ...(order.delivery_price ? [{ label: t('orders.delivery'), value: priceFormat(order.delivery_price) }] : []),
    ...(order.discount_amount ? [{ label: t('orders.discount'), value: `- ${priceFormat(order.discount_amount)}` }] : []),
    {
      label: t('orders.total_sum'),
      value: <strong>{priceFormat(order.sum)}</strong>,
    },
  ];

  return (
    <Card title={t('orders.payment')} style={{ height: '100%' }}>
      <ul>
        {paymentDetails.map((detail, index) => (
          <li key={index} className="d-flex justify-content-between mb-20">
            <strong>{detail.label}</strong>
            <span>{detail.value}</span>
          </li>
        ))}
      </ul>
    </Card>
  );
};

PaymentInfoCard.propTypes = {
  order: propTypes.object,
};

export default PaymentInfoCard;
