import { Table } from 'antd';
import React, { useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import withAuthLayout from '@/layouts/withAuthLayout';
import { CardToolbox, Main } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import usePaginator from '@/hooks/usePaginator';
import { checkboxOperations } from '@/constants/checkboxErrorLogs';
import { DD_MM_Y_HH_mm_ss } from '@/constants/momentFormat';

const InvoiceCheckboxErrorsIndex = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, fetch } = usePaginator(`invoices/${id}/checkbox-errors`);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const columns = React.useMemo(
    () => [
      {
        title: t('fops.checkbox_errors.id'),
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: t('fops.checkbox_errors.message'),
        dataIndex: 'message',
        key: 'message',
      },
      {
        title: t('fops.checkbox_errors.operation'),
        dataIndex: 'operation',
        key: 'operation',
        render: (value) => <span>{checkboxOperations[value]}</span>,
      },
      {
        title: t('fops.checkbox_errors.amount'),
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: t('fops.checkbox_errors.updated_at'),
        dataIndex: 'updated_at',
        key: 'updated_at',
        render: (value) => <span>{moment(value).format(DD_MM_Y_HH_mm_ss)}</span>,
      },
    ],
    [],
  );

  return (
    <>
      <CardToolbox>
        <PageHeader ghost title={t('invoices.checkbox_errors_page_title')} />
      </CardToolbox>

      <Main>
        <Table
          columns={columns}
          dataSource={data}
          rowKey="id"
          permissionNamespace="accounting.invoices"
          actionOptions={{ isEditable: false, isDeletable: false }}
        />
      </Main>
    </>
  );
};

export default withAuthLayout(InvoiceCheckboxErrorsIndex);
