const HH_mm = 'HH:mm';
const DD_MM_Y_HH_mm = 'DD.MM.Y HH:mm';
const DD_MM_Y_HH_mm_ss = 'DD.MM.Y HH:mm:ss';
const DD_MM_Y = 'DD.MM.Y';
const DD_MM_YYYY = 'DD.MM.YYYY';
const D_MMMM_YYYY = 'D MMMM YYYY';
const YYYY_MM_DD = 'YYYY-MM-DD';
const YYYY_MM_DD_HH_mm_ss = 'YYYY-MM-DD HH:mm:ss';

export { HH_mm, DD_MM_Y_HH_mm, DD_MM_Y_HH_mm_ss, DD_MM_YYYY, DD_MM_Y, YYYY_MM_DD, D_MMMM_YYYY, YYYY_MM_DD_HH_mm_ss };
