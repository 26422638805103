import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { InputNumberStyle } from '@/components/Order/styled';

const OrderMoneyInput = ({ value, onChange, prefix, ...rest }) => {
  const handleChange = (value) => {
    onChange(value ? value * 100 : null);
  };

  const displayValue = useMemo(() => {
    if (parseInt(value)) {
      return value / 100;
    }

    return value === 0 ? value : '';
  }, [value]);

  return (
    <InputNumberStyle
      prefix={prefix}
      {...rest}
      value={displayValue}
      type="number"
      onChange={handleChange}
      min={0}
      controls={{
        upIcon: <UpOutlined />,
        downIcon: <DownOutlined />,
      }}
    />
  );
};

OrderMoneyInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  prefix: PropTypes.string,
};

export default OrderMoneyInput;
