import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'antd';
import { ProfileAuthorBox } from './style';
import Heading from '@/components/heading/heading';
import { Cards } from '@/components/cards/frame/cards-frame';
import { useAuth } from '@/helpers/userContext';
import { getGravatarUrl } from '@/helpers/getGravatarImageUrl';

function AuthorBox() {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <ProfileAuthorBox>
      <Cards headless>
        <div className="author-info">
          <div className="info">
            <Avatar src={getGravatarUrl(user.email)} size={100} className="author-image" />
            <Heading as="h4">{user.full_name}</Heading>
            <p>{user.role_name}</p>
          </div>
        </div>
        <nav className="settings-menmulist">
          <ul>
            <li>
              <NavLink to="/account/profile">
                <FeatherIcon icon="user" size={14} />
                {t('account.edit_profile')}
              </NavLink>
            </li>
            <li>
              <NavLink to="/account/password">
                <FeatherIcon icon="lock" size={14} />
                {t('account.change_password')}
              </NavLink>
            </li>
            <li>
              <NavLink to="/account/notification">
                <FeatherIcon icon="bell" size={14} />
                {t('account.notifications')}
              </NavLink>
            </li>
          </ul>
        </nav>
      </Cards>
    </ProfileAuthorBox>
  );
}

AuthorBox.propTypes = {};

export default AuthorBox;
