import { Card, Col, Form, Input, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from '@/components/buttons/buttons';
import AsyncSelect from '@/widgets/AsyncSelect';
import GeoClient from '@/helpers/apiClient/GeoClient';
import DrawZoneModal from '@/components/City/DrawZoneModal';
import LocalityTable from '@/components/City/LocalityTable';
import ApiClient from '@/helpers/apiClient/ApiClient';

const LocalityForm = ({ cityId }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({});
  const [drawZoneMapOpen, setDrawZoneMapOpen] = useState(false);

  useEffect(() => {
    ApiClient.call('get', `/cities/${cityId}/region-data`).data(({ data }) => form.setFieldsValue(data));
  }, [cityId]);

  const handleSubmit = (values) =>
    ApiClient.call('post', `cities/${cityId}/region-data`, {}, values)
      .data(({ data }) => {
        form.setFieldsValue(data);
        message.success(t('success_message'));
      })
      .validation((info, err) => {
        setErrors(err);
      });

  return (
    <Form style={{ width: '100%' }} form={form} layout="vertical" onFinish={handleSubmit}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="ext_region_id" validateStatus={errors.ext_region_id ? 'error' : false} help={errors.ext_region_id} label="Область">
                  <AsyncSelect
                    resource="regions"
                    client={GeoClient}
                    onChange={(value, option) => {
                      form.setFieldsValue({
                        ext_region_id: value,
                        region_name: option ? option.label : '',
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item name="region_name" hidden>
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                {cityId && (
                  <Form.Item label=" " shouldUpdate>
                    <Button
                      type="primary"
                      size="large"
                      style={{ width: '100%' }}
                      onClick={() => {
                        setDrawZoneMapOpen(true);
                      }}
                      disabled={!cityId}
                    >
                      {t('delivery_zones.modal_title')}
                    </Button>
                  </Form.Item>
                )}
              </Col>
              <Col span={24} style={{ display: 'flex', justifyContent: 'end' }}>
                <Button size="large" type="success" htmlType="submit">
                  {t('cities.save')}
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>

        {cityId && drawZoneMapOpen && <DrawZoneModal cityId={cityId} onClose={() => setDrawZoneMapOpen(false)} />}
        <Col span={24}>
          <Card>
            <LocalityTable cityId={cityId} />
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

LocalityForm.propTypes = {
  cityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default LocalityForm;
