import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { OrderCard, OrderFormItem } from '@/components/Order/styled';
import LinkInput from '@/components/Order/LinkInput';

const OrderLinksCard = () => {
  const { t } = useTranslation();

  const fields = [
    { name: 'tracking_url', label: t('orders.tracking') },
    { name: 'payment_url', label: t('orders.payment') },
  ];

  return (
    <OrderCard>
      <Row gutter={[10, 8]}>
        {fields.map(({ name, label }) => (
          <Col span={24} key={name}>
            <OrderFormItem
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return prevValues[name] !== currentValues[name];
              }}
            >
              {({ getFieldValue }) => getFieldValue(name) && <LinkInput value={getFieldValue(name)} label={label} />}
            </OrderFormItem>
          </Col>
        ))}
      </Row>
    </OrderCard>
  );
};

export default OrderLinksCard;
