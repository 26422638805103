const appendPathName = (tree, prefix = '') => {
  return tree.map((el, i) => {
    const path = `${prefix}.${i}`;

    if (el.children) {
      return {
        ...el,
        path,
        children: appendPathName(el.children, `${path}.children`),
      };
    }

    return {
      ...el,
      path: `${prefix}.${i}`,
    };
  });
};

export default appendPathName;
