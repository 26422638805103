import React, { useId } from 'react';
import { Select } from 'antd';
import { SelectStyle } from '@/components/Order/styled';

// eslint-disable-next-line react/prop-types
const OrderSelect = ({ prefix, value, options, onChange }) => {
  const containerID = useId();
  return (
    <SelectStyle id={containerID}>
      <span className="prefix">{prefix}</span>
      <Select options={options} value={value} onChange={onChange} getPopupContainer={() => document.getElementById(containerID)} />
    </SelectStyle>
  );
};

export default OrderSelect;
