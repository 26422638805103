import { useCallback, useEffect } from 'react';
import moment from 'moment/moment';
import { YYYY_MM_DD } from '@/constants/momentFormat';

export default function useFetchStatistics(fetchReceipts, fetchRevenue) {
  useEffect(() => {
    fetchReceipts(moment().format(YYYY_MM_DD), moment().format(YYYY_MM_DD));
    fetchRevenue(moment().format(YYYY_MM_DD), moment().format(YYYY_MM_DD));
  }, []);

  const fetchCallback = useCallback(
    (start, end) => {
      fetchReceipts(start, end);
      fetchRevenue(start, end);
    },
    [fetchReceipts, fetchRevenue],
  );

  return { fetchCallback };
}
