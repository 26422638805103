import styled from 'styled-components';
import { Card, Form, Modal } from 'antd';

const CalendarCard = styled(Card)`
  .ant-picker-calendar-header .ant-select-selector {
    height: 48px !important;
  }

  .ant-picker-calendar-date {
    position: relative !important;
  }

  .ant-picker-calendar-date-value {
    padding-right: 5px;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    padding: 0 17px 5px 0;
  }

  .ant-picker-calendar-date-value {
    position: relative;
    z-index: 999;
  }
`;

const CalendarHeaderStyled = styled.div`
  display: flex;
  justify-content: end;
  gap: 20px;
  margin-bottom: 40px;
`;

const CalendarCell = styled.div`
  font-size: 12px;
  padding: 30px 0 0 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
  background-color: ${({ isWeekend }) => isWeekend && '#ffe7ba'};
  box-shadow: ${({ isWeekend }) => isWeekend && 'inset #ffa940 0px 0px 5px 0px'};
`;

const EditScheduleFormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .schedule-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    background: #f4f5f7;
    padding: 0 10px;
    box-shadow: rgb(212 212 212) 1px 1px 3px 0;
  }

  .select-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #5f63f2;
    color: #ffffff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: #5f63f2 0 0 6px 1px;

    & > span:last-child {
      font-weight: bold;
    }
  }

  .actions {
    display: flex;
    justify-content: end;
    gap: 10px;
  }

  .delete-item {
    margin-left: auto;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .ant-picker-input {
    padding: 5px 5px 5px 10px;

    & > input {
      max-width: 80px;
    }
  }

  .ant-input {
    max-height: 34px;
    max-width: 70px;
  }
`;

const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 40px;

  & > span:last-child {
    font-size: 12px;
    background: #5f63f2;
    padding: 0 5px;
    border-radius: 4px;
    color: #f4f5f7;
  }
`;

const WeekendModalStyled = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    text-align: center;
  }

  .ant-modal-body {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 0 10px 20px 10px;
  }
`;

const TimePickerFormItem = styled(Form.Item)`
  .ant-picker-footer {
    display: none;
  }
`;
export { CalendarCard, CalendarCell, CalendarHeaderStyled, EditScheduleFormStyled, ModalHead, WeekendModalStyled, TimePickerFormItem };
