import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { CardToolbox, Main } from '../../../styled';
import withAuthLayout from '@/layouts/withAuthLayout';
import { PageHeader } from '@/components/page-headers/page-headers';
import Table from '@/widgets/Table/Table';

const RoleIndex = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        title: 'Роль',
        dataIndex: 'name',
        key: 'name',
      },
    ],
    [],
  );

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('role.list_page_header')}
          buttons={[
            <Button className="btn-add_new" size="default" type="primary" key="1">
              <Link to="/administrating/roles/create">+ {t('role.create_button')}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Table columns={columns} route="administrating/roles" resource="roles" permissionNamespace="administrating.roles" />
      </Main>
    </>
  );
};

export default withAuthLayout(RoleIndex);
