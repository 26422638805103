import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import { PageHeader } from '@/components/page-headers/page-headers';
import { Main } from '@/container/styled';
import { Cards } from '@/components/cards/frame/cards-frame';
import { ActionsWrapper, FormWrapper } from '@/container/pages/style';
import FormSubmit from '@/form/FormSubmit';
import useFormValues from '@/hooks/useFormValues';
import withAuthLayout from '@/layouts/withAuthLayout';
import FormInput from '@/form/FormInput';
import FormSelect from '@/form/FormSelect';
import IconInput from '@/components/iconInput/iconInput';
import { PRODUCT_OPTION_TYPE_BUTTON, PRODUCT_OPTION_TYPE_SELECT } from '@/constants/products';

const ProductOptionForm = () => {
  const { t } = useTranslation();
  const { id, form, loading, handleSubmit, errors } = useFormValues('product-options');

  return (
    <>
      <PageHeader ghost title={id && t('products.product_option.title_edit')} />
      <Main>
        <Cards headless>
          <Row justify="center">
            <Col xs={24} md={20} lg={16} xl={12}>
              <FormWrapper>
                <Form style={{ width: '100%' }} form={form} layout="vertical" onFinish={handleSubmit}>
                  <FormInput name="label" label={t('products.product_option.fields.name')} error={errors.label} required />

                  <FormSelect
                    name="type"
                    label={t('products.product_option.fields.type')}
                    required
                    error={errors.type}
                    options={[
                      { value: PRODUCT_OPTION_TYPE_BUTTON, label: t(`products.option_types.${PRODUCT_OPTION_TYPE_BUTTON}`) },
                      { value: PRODUCT_OPTION_TYPE_SELECT, label: t(`products.option_types.${PRODUCT_OPTION_TYPE_SELECT}`) },
                    ]}
                  />

                  <Form.Item name="icon" label={t('products.product_option.fields.icon')} error={errors.icon}>
                    <IconInput />
                  </Form.Item>

                  <ActionsWrapper>
                    <FormSubmit loading={loading} />
                  </ActionsWrapper>
                </Form>
              </FormWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default withAuthLayout(ProductOptionForm);
