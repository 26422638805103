import styled, { css } from 'styled-components';
import { Card, Modal } from 'antd';

const SetCardContainer = styled(Card)`
  width: 100%;
  border: 1px solid #f2f2f2 !important;

  ${(props) =>
    props.errors &&
    css`
      border-color: red !important;
      background-color: #ff634769 !important;

      .ant-card-head {
        background: rgba(255, 99, 71, 0) !important;
        border-color: rgba(255, 99, 71, 0) !important;
      }
    `}
`;

const ModalCardContainer = styled(Modal)`
  .delete-all-products {
    display: flex;
    justify-content: flex-end;
  }
`;

const ProductsContainer = styled.div`
  background-color: #f7f8fa;
  margin-bottom: 30px;
  border-radius: 10px;
  padding: 10px 10px;

  .detele-btn {
    min-height: 48px;
    width: 100%;
  }
`;

const RecommendedProductCard = styled(Card)`
  width: 100%;
  border: 1px solid #f2f2f2 !important;
  min-height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;

  .recommended-product-card-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: bold;
    color: #5f63f2;
    cursor: pointer;

    .icon {
      color: #5f63f2;
    }
  }
`;

export { SetCardContainer, ModalCardContainer, ProductsContainer, RecommendedProductCard };
