import React from 'react';
import { Col, Row } from 'antd';
import { get, has } from 'lodash';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import InputOrder from '@/components/Order/InputOrder';
import OrderPhoneInput from '@/components/Order/OrderPhoneInput';
import OrderFormItemSelect from '@/components/Order/OrderFormItemSelect';
import { ORDER_DELIVERY_TYPE_COURIER, ORDER_DELIVERY_TYPE_SELF, ORDER_GENDER_TYPE_FEMALE, ORDER_GENDER_TYPE_MALE } from '@/constants/orders';
import CustomerIsRecipientCheckbox from '@/components/Order/CustomerIsRecipientCheckbox';
import { OrderCard, OrderFormItem } from '@/components/Order/styled';
import DeliveryDateTimePicker from '@/components/Order/DeliveryDateTimePicker';

const DeliveryDetails = ({ errorData, isCustomerRecipient, clientData, handleRecipient, id }) => {
  const { t } = useTranslation();

  return (
    <OrderCard>
      <Row gutter={[10, 8]} style={{ marginBottom: '10px' }}>
        <Col span={8}>
          <InputOrder prefix={t('orders.fields.name')} name="recipient_name" error={get(errorData, 'recipient_name')} />
        </Col>
        <Col span={8}>
          <OrderPhoneInput name="recipient_phone_number" prefix={t('orders.fields.phone_number')} error={get(errorData, 'recipient_phone_number')} />
        </Col>
        <Col span={8}>
          <OrderFormItemSelect
            prefix={t('orders.fields.delivery_type')}
            name="delivery_type"
            error={get(errorData, 'delivery_type')}
            options={[
              { value: ORDER_DELIVERY_TYPE_COURIER, label: t(`orders.delivery_types.${ORDER_DELIVERY_TYPE_COURIER}`) },
              { value: ORDER_DELIVERY_TYPE_SELF, label: t(`orders.delivery_types.${ORDER_DELIVERY_TYPE_SELF}`) },
            ]}
          />
        </Col>

        <Col span={8}>
          <OrderFormItemSelect
            prefix={t('orders.fields.gender')}
            name="recipient_gender"
            options={[
              { value: ORDER_GENDER_TYPE_MALE, label: t(`orders.gender.${ORDER_GENDER_TYPE_MALE}`) },
              { value: ORDER_GENDER_TYPE_FEMALE, label: t(`orders.gender.${ORDER_GENDER_TYPE_FEMALE}`) },
            ]}
          />
        </Col>

        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          {!id && (
            <CustomerIsRecipientCheckbox
              disabled={!clientData.id}
              labelCheckbox={t('orders.fields.is_recipient_self')}
              value={isCustomerRecipient}
              onChange={handleRecipient}
            />
          )}
        </Col>

        <Col>
          <OrderFormItem name="delivery_data" validateStatus={has(errorData, 'delivery_data') ? 'error' : false} help={get(errorData, 'delivery_data.date')}>
            <DeliveryDateTimePicker />
          </OrderFormItem>
        </Col>
      </Row>
    </OrderCard>
  );
};

DeliveryDetails.propTypes = {
  errorData: propTypes.object,
  isCustomerRecipient: propTypes.bool,
  clientData: propTypes.object,
  handleRecipient: propTypes.func,
  id: propTypes.oneOfType([propTypes.string, propTypes.number]),
};

export default DeliveryDetails;
