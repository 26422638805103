import { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ApiClient from '@/helpers/apiClient/ApiClient';
import { Modal } from '@/components/modals/antd-modals';
import { ORDER_PAYMENT_TYPE_CASH, ORDER_STATUS_NEED_CONFIRMATION } from '@/constants/orders';
import ApproveOrCancelOrderCell from '@/components/Order/ApproveOrCancelOrderCell';
import ProductInfoCard from '@/components/Order/OrderShortModal/ProductInfoCard';
import ClientInfoCard from '@/components/Order/OrderShortModal/ClientInfoCard';
import DeliveryInfoCard from '@/components/Order/OrderShortModal/DeliveryInfoCard';
import PaymentInfoCard from '@/components/Order/OrderShortModal/PaymentInfoCard';

const ShortOrderInfoModal = ({ id, onClose }) => {
  const { t } = useTranslation();

  const [order, setOrder] = useState(null);
  const [clientData, setClientData] = useState(null);

  useEffect(() => {
    ApiClient.call('get', `orders/${id}`).data(({ data }) => setOrder(data));
  }, [id]);

  useEffect(() => {
    if (order?.client_id) {
      ApiClient.call('get', `clients/${order.client_id}`).data(({ data }) => setClientData(data));
    }
  }, [order, id]);

  if (!order) {
    return;
  }

  return (
    <Modal visible title={`Замовлення №${order.billing_number}`} onCancel={onClose} width={1200} footer={null}>
      {order.status === ORDER_STATUS_NEED_CONFIRMATION && order.payment_type === ORDER_PAYMENT_TYPE_CASH && (
        <div className="d-flex justify-content-between mb-30">
          <strong>{t('orders.confirmation')}</strong>
          <div>
            <ApproveOrCancelOrderCell id={order.id} />
          </div>
        </div>
      )}
      <Row gutter={[30, 30]}>
        <Col md={12} xs={24}>
          <ClientInfoCard
            title={t('orders.client')}
            clientInfo={[
              { label: t('orders.client_name'), value: clientData?.name },
              { label: t('orders.client_surname'), value: clientData?.last_name },
              { label: t('orders.client_telephone'), value: clientData?.phone_number },
            ]}
          />
        </Col>

        <Col md={12} xs={24}>
          <ProductInfoCard title={t('orders.product')} products={order.products} itemsSum={order.items_sum} />
        </Col>

        <Col md={12} xs={24}>
          <DeliveryInfoCard order={order} />
        </Col>

        <Col md={12} xs={24}>
          <PaymentInfoCard order={order} />
        </Col>
      </Row>
    </Modal>
  );
};

ShortOrderInfoModal.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ShortOrderInfoModal;
