import { useEffect, useState } from 'react';
import { LinkOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Dropdown } from 'antd';
import ApiClient from '@/helpers/apiClient/ApiClient';
import config from '@/config.json';

const FrontAppLinkByCityDropdown = ({ pathname, disabled }) => {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    ApiClient.call('get', 'cities').data(({ data }) => {
      setCities(data);
    });
  }, []);

  const menu = cities.map((city) => {
    const href = `${config.frontAppUrl}/${city.slug}/${pathname}`;

    return {
      key: city.id,
      label: (
        <a key={city.id} target="_blank" rel="noopener noreferrer" href={href} style={{ fontWeight: 'bold' }}>
          {city.name}
        </a>
      ),
    };
  });

  return (
    <Dropdown menu={{ items: menu }} disabled={disabled}>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <a href="#" onClick={(e) => e.preventDefault()}>
        <LinkOutlined />
      </a>
    </Dropdown>
  );
};

FrontAppLinkByCityDropdown.propTypes = {
  pathname: PropTypes.string.isRequired,
  disabled: PropTypes.any,
};

export default FrontAppLinkByCityDropdown;
