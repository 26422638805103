import { Button, Col, Drawer, Form, Row } from 'antd';
import { useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { DrawerProductListStyled } from '@/components/Product/style';
import FormSelectAsync from '@/form/FormSelectAsync';
import FormInput from '@/form/FormInput';
import { ErrorMessage } from '@/components/Order/styled';

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: "${label} є обов'язковим!",
};

const DrawerVariant = ({ open, onClose, value, onChange, errors = [] }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ items: value || [] });
  }, [value]);

  const onFinish = (values) => {
    const items = values.items || [];
    onChange(items);
    onClose();
  };

  return (
    <Drawer
      title={t('products.variants.drawer.title')}
      forceRender
      width={600}
      onClose={onClose}
      open={open}
      extra={<Button onClick={form.submit}>{t('products.variants.drawer.save_button')}</Button>}
    >
      <Form form={form} onFinish={onFinish} size="small" layout="vertical" validateMessages={validateMessages}>
        <Form.List name="items">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name }) => {
                return (
                  <DrawerProductListStyled
                    key={name}
                    actions={[
                      <Button danger onClick={() => remove(name)} key={1}>
                        {t('products.variants.drawer.delete_button')}
                      </Button>,
                    ]}
                  >
                    <Row gutter={10}>
                      <Col span={24}>
                        <FormSelectAsync
                          resource="warehouse/goods/search"
                          name={[name, 'good_id']}
                          label={t('products.variants.drawer.fields.name')}
                          required
                          rules={[{ required: true }]}
                          error={get(errors, `${name}.good_id`)}
                        />
                      </Col>
                      <Col span={10}>
                        <FormInput
                          name={[name, 'qty']}
                          label={t('products.variants.drawer.fields.qty')}
                          rules={[{ required: true }]}
                          error={get(errors, `${name}.qty`)}
                        />
                      </Col>
                      <Col span={14}>
                        <FormInput name={[name, 'color']} label={t('products.variants.drawer.fields.color')} error={get(errors, `${name}.color`)} />
                      </Col>
                    </Row>
                  </DrawerProductListStyled>
                );
              })}
              {Boolean(errors.length) && <ErrorMessage style={{ fontSize: '14px', fontFamily: 'Inter, sans-serif' }}>Помилка валідації</ErrorMessage>}
              <Button style={{ width: '100%' }} icon={<PlusOutlined />} onClick={() => add()}>
                {t('products.variants.drawer.add_button')}
              </Button>
            </>
          )}
        </Form.List>
      </Form>
    </Drawer>
  );
};

DrawerVariant.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  value: PropTypes.array,
  onChange: PropTypes.func,
  errors: PropTypes.any,
};

export default DrawerVariant;
