export const ORDER_STATUS_NEED_CONFIRMATION = 'NEED_CONFIRMATION';
export const ORDER_STATUS_PROCESSING = 'PROCESSING';
export const ORDER_STATUS_DONE = 'DONE';
export const ORDER_STATUS_CANCELLED = 'CANCELLED';

export const ORDER_STAGE_IN_QUE_FOR_PACKING = 'IN_QUE_FOR_PACKING';
export const ORDER_STAGE_BOUQUET_READY = 'BOUQUET_READY';
export const ORDER_STAGE_DELIVERING = 'DELIVERING';
export const ORDER_STAGE_COMPLETED = 'COMPLETED';

export const ORDER_PAYMENT_STATUS_PENDING = 'PENDING';
export const ORDER_PAYMENT_STATUS_PAID = 'PAID';
export const ORDER_PAYMENT_STATUS_FAILED = 'FAILED';
export const ORDER_PAYMENT_STATUS_REVERSED = 'REVERSED';

export const ORDER_PAYMENT_TYPE_CASH = 'CASH';
export const ORDER_PAYMENT_TYPE_ACQUIRING = 'ACQUIRING';

export const ORDER_DELIVERY_TYPE_COURIER = 'COURIER';
export const ORDER_DELIVERY_TYPE_SELF = 'SELF';

export const ORDER_SOURCE_TYPE_WEBSITE = 'WEBSITE';
export const ORDER_SOURCE_TYPE_PHONE = 'PHONE';
export const ORDER_SOURCE_TYPE_MESSENGER = 'MESSENGER';
export const ORDER_SOURCE_TYPE_SOCIAL_NETWORK = 'SOCIAL_NETWORK';
export const ORDER_SOURCE_TYPE_OFFLINE = 'OFFLINE';

export const ORDER_SOCIAL_NETWORK_TELEGRAM = 'TELEGRAM';
export const ORDER_SOCIAL_NETWORK_INSTAGRAM = 'INSTAGRAM';
export const ORDER_SOCIAL_NETWORK_FACEBOOK = 'FACEBOOK';
export const ORDER_SOCIAL_NETWORK_VIBER = 'VIBER';

export const ORDER_GENDER_TYPE_MALE = 'MALE';
export const ORDER_GENDER_TYPE_FEMALE = 'FEMALE';
