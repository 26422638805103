import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { SyncOutlined } from '@ant-design/icons';
import CitiesRevenue from '@/components/statistic/siteStatistic/citiesRevenue';
import withAuthLayout from '@/layouts/withAuthLayout';
import { Main } from '@/container/styled';
import CardChunk from '@/components/statistic/siteStatistic/cardChunk';
import ApiClient from '@/helpers/apiClient/ApiClient';
import Calendar from '@/components/statistic/siteStatistic/calendar';
import { HeaderWrapper } from '@/components/statistic/siteStatistic/calendar/styled';
import TopProduct from '@/components/statistic/siteStatistic/topProduct';
import TopCities from '@/components/statistic/siteStatistic/topCities';
import useDateRange from '@/hooks/useDateRange';
import { PageHeader } from '@/components/page-headers/page-headers';
import { YYYY_MM_DD } from '@/constants/momentFormat';

const replaceFields = (orders) => ({ rate: orders.rate, value: orders.value, labels: Object.keys(orders.prev), data: Object.values(orders.prev) });

const SiteStatistic = () => {
  const { t } = useTranslation();

  const [siteData, setSiteData] = useState({ count: {}, avg: {}, clients: {}, revenue: {} });
  const [isLoading, setIsLoading] = useState(false);

  const { startDate, endDate, range, setRange } = useDateRange();

  const fetchData = () => {
    setIsLoading(true);
    ApiClient.call('get', 'site-statistic/stats', { start: moment(startDate).format(YYYY_MM_DD), end: moment(endDate).format(YYYY_MM_DD) })
      .data(({ orders }) => {
        setSiteData({
          count: replaceFields(orders.count),
          avg: replaceFields(orders.avg),
          clients: replaceFields(orders.clients),
          revenue: replaceFields(orders.revenue),
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [range]);

  return (
    <div>
      <HeaderWrapper>
        <PageHeader ghost title={t('site_statistic.list_page_header')} />
        <div className="actions-container">
          <Calendar range={range} setRange={setRange} startDate={startDate} endDate={endDate} />
          <Button type="ghost" className="update" onClick={fetchData}>
            <SyncOutlined /> {t('site_statistic.update_button')}
          </Button>
        </div>
      </HeaderWrapper>

      <Main>
        <Row gutter={[25, 25]}>
          <Col xxl={6} md={12} sm={12} xs={24}>
            <CardChunk title={t('site_statistic.card_title.orders')} cardData={siteData.count} isLoading={isLoading} fixedValue={0} />
          </Col>
          <Col xxl={6} md={12} sm={12} xs={24}>
            <CardChunk title={t('site_statistic.card_title.revenue')} cardData={siteData.revenue} isLoading={isLoading} />
          </Col>
          <Col xxl={6} md={12} sm={12} xs={24}>
            <CardChunk title={t('site_statistic.card_title.avg')} cardData={siteData.avg} isLoading={isLoading} />
          </Col>
          <Col xxl={6} md={12} sm={12} xs={24}>
            <CardChunk title={t('site_statistic.card_title.clients')} cardData={siteData.clients} isLoading={isLoading} fixedValue={0} />
          </Col>
          <Col xxl={24} xs={24}>
            <TopProduct />
          </Col>
        </Row>

        <div style={{ margin: '25px 0' }}>
          <Row gutter={[25, 25]}>
            <Col xl={12} xs={24}>
              <TopCities />
            </Col>
            <Col xl={12} xs={24}>
              <CitiesRevenue />
            </Col>
          </Row>
        </div>
      </Main>
    </div>
  );
};

export default withAuthLayout(SiteStatistic);
