import { Button, Modal, Popconfirm, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import { DeleteOutlined } from '@ant-design/icons';
import { SeoCard, SeoTableActions } from '@/container/pages/cms/categories/style';
import ApiClient from '@/helpers/apiClient/ApiClient';
import CategoriesFormSeo from '@/components/Categories/CategoriesFormSEO';

const CategoriesSeo = ({ categoryId }) => {
  const { t } = useTranslation();
  const [seo, setSeo] = useState([]);
  const [activeId, setActiveId] = useState(false);

  useEffect(() => {
    ApiClient.call('get', `categories/${categoryId}/seo`).data(({ data }) => {
      setSeo(data);
    });
  }, [categoryId]);

  const handleDelete = (seo_id) => {
    ApiClient.call('delete', `categories/${categoryId}/seo/${seo_id}`).then(() => {
      setSeo((prev) => prev.filter((item) => item.id !== seo_id));
    });
  };

  const onCreate = (data) => {
    setSeo((prev) => [...prev, data]);
    setActiveId(false);
  };

  const onUpdate = (data) => {
    setSeo((prev) => prev.map((item) => (item.id === data.id ? data : item)));
    setActiveId(false);
  };

  const columns = [
    { title: t('categories.seo.city_name'), dataIndex: 'city_name', key: 'city_name' },
    { title: t('categories.seo.title'), dataIndex: 'title', key: 'title', align: 'center' },
    {
      title: t('categories.seo.actions'),
      key: 'actions',
      align: 'end',
      render: (_, record) => {
        return (
          <SeoTableActions>
            <Button icon={<FeatherIcon icon="edit" size={16} />} onClick={() => setActiveId(record.id)} className="action-button" />
            <Popconfirm title={t('categories.seo.delete_popconfirm')} onConfirm={() => handleDelete(record.id)} okText={t('categories.seo.ok_popconfirm')}>
              <Button danger icon={<DeleteOutlined size={16} />} className="action-button" />
            </Popconfirm>
          </SeoTableActions>
        );
      },
    },
  ];

  return (
    <SeoCard>
      <Button type="dashed" onClick={() => setActiveId(null)} className="add-button">
        {t('categories.seo.add_button')}
      </Button>

      <Table columns={columns} dataSource={seo} rowKey="id" pagination={false} className="seo-table" />

      <Modal open={activeId !== false} onCancel={() => setActiveId(false)} footer={null} width={1000} destroyOnClose>
        <CategoriesFormSeo id={activeId} categoryId={categoryId} onCreate={onCreate} onUpdate={onUpdate} />
      </Modal>
    </SeoCard>
  );
};

CategoriesSeo.propTypes = {
  categoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CategoriesSeo;
