import React from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';

const FormInput = ({ label, name, type = 'text', placeholder, required, addonBefore, addonAfter, disabled, error, rules, ...props }) => {
  return (
    <Form.Item required={required} validateStatus={error ? 'error' : false} help={error} name={name} label={label} rules={rules}>
      {type === 'password' ? (
        <Input.Password placeholder={placeholder} disabled={disabled} {...props} />
      ) : (
        <Input addonBefore={addonBefore} addonAfter={addonAfter} disabled={disabled} placeholder={placeholder} {...props} />
      )}
    </Form.Item>
  );
};

FormInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  disabled: PropTypes.bool,
  required: PropTypes.any,
  addonBefore: PropTypes.any,
  addonAfter: PropTypes.any,
  rules: PropTypes.array,
};

export default FormInput;
