import { StringParam, useQueryParams } from 'use-query-params';
import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { YYYY_MM_DD } from '@/constants/momentFormat';

export default function useDateRange(startName = 'startDate', endName = 'endDate') {
  const [query, setQuery] = useQueryParams({
    [startName]: StringParam,
    [endName]: StringParam,
  });

  const [range, setRange] = useState({
    datePickerInternational: null,
    dateRangePicker: {
      selection: {
        [startName]: query[startName] ? moment(query[startName]).toDate() : moment().toDate(),
        [endName]: query[endName] ? moment(query[endName]).toDate() : moment().toDate(),
        key: 'selection',
      },
    },
  });

  const {
    dateRangePicker: {
      selection: { [startName]: startDate, [endName]: endDate },
    },
  } = range;

  useEffect(() => {
    if (startDate && endDate) {
      setQuery({ startDate: moment(startDate).format(YYYY_MM_DD), endDate: moment(endDate).format(YYYY_MM_DD) });
    }
  }, [startDate, endDate]);

  return { startDate, endDate, setRange, range };
}
