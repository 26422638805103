import { Button, Col, Row } from 'antd';
import { has, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import OrderFormItemSelect from '@/components/Order/OrderFormItemSelect';
import { ErrorMessage, OrderFormItem } from '@/components/Order/styled';
import OrderMoneyInput from '@/components/Order/OrderMoneyInput';
import ExtendedGoodSelect from '@/components/Order/ExtendedGoodSelect';
import InputOrder from '@/components/Order/InputOrder';
import {
  ORDER_PAYMENT_STATUS_FAILED,
  ORDER_PAYMENT_STATUS_PAID,
  ORDER_PAYMENT_STATUS_PENDING,
  ORDER_PAYMENT_STATUS_REVERSED,
  ORDER_PAYMENT_TYPE_ACQUIRING,
  ORDER_PAYMENT_TYPE_CASH,
} from '@/constants/orders';

const PaymentDetails = ({ errorData, paymentType, promoCodeId, applyPromoCode }) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: '17.5px' }}>
      <Row gutter={[10, 8]} style={{ marginBottom: '10px' }}>
        <Col span={8}>
          <OrderFormItemSelect
            prefix={t('orders.fields.payment_type')}
            name="payment_type"
            error={get(errorData, 'payment_type')}
            options={[
              { value: ORDER_PAYMENT_TYPE_CASH, label: t(`orders.payment_types.${ORDER_PAYMENT_TYPE_CASH}`) },
              { value: ORDER_PAYMENT_TYPE_ACQUIRING, label: t(`orders.payment_types.${ORDER_PAYMENT_TYPE_ACQUIRING}`) },
            ]}
          />
        </Col>
        <Col span={8}>
          <OrderFormItem name="manual_discount">
            <OrderMoneyInput prefix={t('orders.fields.manual_discount')} />
          </OrderFormItem>
        </Col>
        <Col span={8}>
          <ExtendedGoodSelect
            prefix={t('orders.fields.promo_code')}
            name="promo_code_id"
            resource="promo-codes/search"
            error={get(errorData, 'promo_code_id')}
          />
          {has(errorData, 'sum') && <ErrorMessage>{t('orders.promo_code_error')}</ErrorMessage>}
        </Col>
      </Row>
      <Row gutter={[10, 8]}>
        <Col span={8}>
          <OrderFormItemSelect
            prefix={t('orders.fields.payment_status')}
            name="payment_status"
            error={get(errorData, 'payment_status')}
            disabled={paymentType === ORDER_PAYMENT_TYPE_ACQUIRING}
            options={[
              { value: ORDER_PAYMENT_STATUS_PENDING, label: t(`orders.payment_statuses.${ORDER_PAYMENT_STATUS_PENDING}`) },
              { value: ORDER_PAYMENT_STATUS_PAID, label: t(`orders.payment_statuses.${ORDER_PAYMENT_STATUS_PAID}`) },
              { value: ORDER_PAYMENT_STATUS_FAILED, label: t(`orders.payment_statuses.${ORDER_PAYMENT_STATUS_FAILED}`) },
              { value: ORDER_PAYMENT_STATUS_REVERSED, label: t(`orders.payment_statuses.${ORDER_PAYMENT_STATUS_REVERSED}`) },
            ]}
          />
        </Col>
        <Col span={8}>
          <InputOrder prefix={t('orders.fields.comment')} name="comment" />
        </Col>
        <Col span={8}>
          <Button style={{ width: '100%', height: '100%', fontFamily: 'Nunito Sans, sans-serif' }} disabled={!promoCodeId} onClick={() => applyPromoCode()}>
            {t('orders.apply_promocode')}
          </Button>
        </Col>
      </Row>
      {paymentType === ORDER_PAYMENT_TYPE_ACQUIRING && (
        <Row gutter={[10, 8]} style={{ marginTop: '10px' }}>
          <Col span={8}>
            <ExtendedGoodSelect name="fop_id" prefix={t('orders.fops')} resource="fops/search" error={get(errorData, 'fop_id')} />
          </Col>
        </Row>
      )}
    </div>
  );
};

PaymentDetails.propTypes = {
  errorData: propTypes.object,
  paymentType: propTypes.string,
  promoCodeId: propTypes.oneOfType([propTypes.string, propTypes.number]),
  applyPromoCode: propTypes.func,
};

export default PaymentDetails;
