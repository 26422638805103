import { Card } from 'antd';
import propTypes from 'prop-types';

const ClientInfoCard = ({ title, clientInfo }) => {
  return (
    <Card title={title} style={{ height: '100%' }}>
      <ul>
        {clientInfo.map((info, index) => (
          <li key={index} className="d-flex justify-content-between mb-20">
            <strong>{info.label}</strong>
            <span>{info.value}</span>
          </li>
        ))}
      </ul>
    </Card>
  );
};

ClientInfoCard.propTypes = {
  title: propTypes.string,
  clientInfo: propTypes.array,
};

export default ClientInfoCard;
