import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import React from 'react';
import useFormValues from '@/hooks/useFormValues';
import { PageHeader } from '@/components/page-headers/page-headers';
import { Main } from '@/container/styled';
import { Cards } from '@/components/cards/frame/cards-frame';
import { ActionsWrapper, FormWrapper } from '@/container/pages/style';
import FormSubmit from '@/form/FormSubmit';
import withAuthLayout from '@/layouts/withAuthLayout';
import { ImageCard } from '@/container/pages/cms/sliderElements/style';
import FormFile from '@/form/FormFile';
import useUploadImage from '@/hooks/useUploadImage';
import FormInput from '@/form/FormInput';
import FormSelectAsync from '@/form/FormSelectAsync';

const SliderElementForm = () => {
  const { t } = useTranslation();
  const { id, form, loading, model, handleSubmit, errors } = useFormValues('main-page/slider-elements', true);
  const previewMobile = useUploadImage(Form.useWatch('mobile_file', form), model.mobile_src);
  const previewDesktop = useUploadImage(Form.useWatch('desktop_file', form), model.desktop_src);

  return (
    <>
      <PageHeader ghost title={id ? t('slider_elements.edit_page_header') : t('slider_elements.create_page_header')} />
      <Main>
        <Cards headless>
          <Row justify="center">
            <Col xs={24} md={20} lg={16} xl={12}>
              <FormWrapper>
                <Form style={{ width: '100%' }} form={form} layout="vertical" onFinish={handleSubmit}>
                  <FormInput name="title" label={t('slider_elements.fields.title')} error={errors.title} required />

                  <FormFile accept="image/*" name="mobile_file" label={t('slider_elements.fields.mobile_src')} error={errors.mobile_file} required />
                  <FormFile accept="image/*" name="desktop_file" label={t('slider_elements.fields.desktop_src')} error={errors.desktop_file} required />
                  <FormInput name="link" label={t('slider_elements.fields.link')} error={errors.link} required />
                  <FormSelectAsync multiple resource="cities/search" name="cities" label={t('slider_elements.fields.cities')} error={errors.cities} required />

                  <Row gutter={24}>
                    {previewMobile && (
                      <Col md={12} xs={24}>
                        <ImageCard>
                          <p className="type">mobile</p>
                          <figure>
                            <img src={previewMobile} alt="mobile" />
                          </figure>
                        </ImageCard>
                      </Col>
                    )}
                    {previewDesktop && (
                      <Col md={12} xs={24}>
                        <ImageCard>
                          <p className="type">desktop</p>
                          <figure>
                            <img src={previewDesktop} alt="desktop" />
                          </figure>
                        </ImageCard>
                      </Col>
                    )}
                  </Row>
                  <ActionsWrapper>
                    <FormSubmit loading={loading} />
                  </ActionsWrapper>
                </Form>
              </FormWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default withAuthLayout(SliderElementForm);
