import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { CalendarCell } from '@/components/Store/style';
import { YYYY_MM_DD } from '@/constants/momentFormat';
import EditScheduleForm from '@/components/Store/EditScheduleForm';

const DateCell = ({ date, schedule, selectedValue, setSchedule, storeId }) => {
  const { t } = useTranslation();
  const selectedDate = date.format(YYYY_MM_DD);
  const selectedSchedule = schedule.find((item) => item.date === selectedDate);
  const [open, setOpen] = useState(false);

  return (
    <>
      <EditScheduleForm
        selectedValue={selectedValue}
        schedule={schedule.find((item) => item.date === selectedValue.format(YYYY_MM_DD))}
        setSchedule={setSchedule}
        storeId={storeId}
        open={open}
        setOpen={setOpen}
      />
      <CalendarCell onClick={() => setOpen(!open)} isWeekend={selectedSchedule?.is_weekend}>
        <div>
          {selectedSchedule && selectedSchedule.ranges.length ? (
            <ul>
              {selectedSchedule.ranges.map(({ start, end }, i) => {
                return (
                  <li key={i}>
                    {start} - {end}
                  </li>
                );
              })}
            </ul>
          ) : selectedSchedule?.is_weekend ? (
            <p>{t('stores.weekend')}</p>
          ) : (
            <p>{t('stores.default_value')}</p>
          )}
        </div>
      </CalendarCell>
    </>
  );
};

DateCell.propTypes = {
  date: propTypes.object,
  selectedValue: propTypes.object,
  schedule: propTypes.array,
  setSchedule: propTypes.func,
  storeId: propTypes.oneOfType([propTypes.string, propTypes.number]),
};

export default DateCell;
