import { Card, Checkbox, Col, Form, InputNumber, Row, Tooltip } from 'antd';
import FontAwesome from 'react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormSelectAsync from '@/form/FormSelectAsync';
import FormInput from '@/form/FormInput';
import useFormValues from '@/hooks/useFormValues';
import { FormWrapper } from '@/container/pages/style';
import { Button } from '@/components/buttons/buttons';

const CityDetailsForm = () => {
  const { t } = useTranslation();
  const { form, handleSubmit, errors } = useFormValues('cities');

  return (
    <FormWrapper>
      <Form
        style={{ width: '100%' }}
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          is_default: false,
        }}
      >
        <Row>
          <Col span={24}>
            <Card>
              <Row gutter={16}>
                <Col span={8}>
                  <FormSelectAsync name="billing_city_id" label={t('cities.fields.billing_city_id')} resource="billing/get-cities" required />
                </Col>
                <Col span={8}>
                  <FormInput name="name" label={t('cities.fields.name')} required />
                </Col>
                <Col span={8}>
                  <FormInput name="slug" label={t('cities.fields.slug')} error={errors.slug} placeholder="vn" required />
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="orders_for_delivery_limit"
                    label={t('cities.fields.orders_for_delivery_limit')}
                    validateStatus={errors.orders_for_delivery_limit ? 'error' : null}
                    help={errors.orders_for_delivery_limit}
                  >
                    <InputNumber style={{ width: '100%' }} min={1} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <FormInput name="instagram_link" label={t('cities.fields.instagram_link')} error={errors.instagram_link} required />
                </Col>
                <Col span={8}>
                  <FormInput name="phone" label={t('cities.fields.phone')} error={errors.phone} required />
                </Col>
                <Col span={8}>
                  <FormInput name="email" label={t('cities.fields.email')} error={errors.email} required />
                </Col>
                <Col span={8}>
                  <Form.Item name="is_default" valuePropName="checked" label=" ">
                    <Checkbox>
                      {t('cities.fields.is_default')}{' '}
                      <Tooltip title={t('cities.default_city_explain')}>
                        <FontAwesome name="question-circle-o" className="super-crazy-colors" />
                      </Tooltip>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24} style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button size="large" type="success" htmlType="submit">
                    {t('cities.save')}
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
    </FormWrapper>
  );
};

export default CityDetailsForm;
