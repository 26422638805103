import styled from 'styled-components';
import { Card } from 'antd';

const InfoCategories = styled(Card)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-card-body {
    flex-direction: column;
    display: flex;
    align-items: center;
    padding: 10px 25px !important;
  }

  .info-text {
    font-size: 14px;
    margin: 0 0 5px 0;
  }

  .example-add-category {
    width: 100%;
    height: auto;
    background: #d9d9d9;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-self: center;
    justify-content: space-between;

    &:after {
      content: 'Додати категорію';
      width: calc(40% + 10px);
      height: 20px;
      font-size: 8px;
      background: #fff;
      padding: 0 5px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:before {
      content: '';
      display: block;
      width: 50%;
      height: 20px;
      background: #fff;
      border-radius: 4px;
      border: 1px solid #e3e6ef;
      margin-right: 5px;
    }
  }

  .example-move-btn {
    background: #d9d9d9
      url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+)
      no-repeat center;
    width: 32px;
    height: 32px;
    border: 1px solid #aaa;
  }

  .example-expand-btn {
    background: #ffffff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #000;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: #999;
      width: 16px;
      height: 3px;
    }

    &::before {
      transform: rotate(90deg);
    }

    &::after {
      transform: rotate(0deg);
    }
  }

  .example-save-btn {
    width: 80px;
    height: 30px;
    background: #5f63f2;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: 'Зберегти';
      color: #fff;
    }
  }
`;

const CategoriesTreeStyled = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .categories-add-container {
    width: 40%;
    display: flex;
    align-self: end;
    background: #d9d9d9;
    padding: 10px;
    border-radius: 10px;
    gap: 20px;

    .add-categories-btn {
      font-size: 14px;
      background: #fff;
    }
  }

  .row-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.error-border {
      border: 1px solid red;
      border-radius: 4px;
      padding: 5px;
    }

    .error-message {
      color: red;
      font-size: 12px;
      margin-top: 4px;
    }
  }

  .ant-btn {
    background: transparent;
    border: none;
    outline: none;
    font-size: 20px;
    height: auto;
  }

  .rst__rowContents {
    padding: 0 0 0 10px;
  }

  .rst__rowLabel {
    padding-right: 15px;
  }

  .rst__rowToolbar {
    background: #d9d9d9;
    padding: 4px;
  }

  .row-with-error {
    box-shadow: 0 0 0 2px red;
  }

  .rst__rowSubtitle {
    color: red;
  }

  .add-btn {
    color: #ffffff;

    &:hover {
      color: #f4f5f7;
    }
  }

  .edit-btn {
    color: #5f63f2;

    &:hover {
      color: #878dfdff;
    }
  }
`;

const SeoTableActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  width: 100%;

  .action-button {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SeoCard = styled(Card)`
  position: relative;

  .seo-table {
    margin-top: 40px;
  }

  .add-button {
    position: absolute;
    top: 15px;
    right: 25px;
  }
`;

export { InfoCategories, CategoriesTreeStyled, SeoTableActions, SeoCard };
