import React, { useCallback, useState } from 'react';
import { AutoComplete, Button, Col, Form, Modal, Tag } from 'antd';
import { get, has } from 'lodash';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';
import { useClickAway } from '@uidotdev/usehooks';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ColorPickerStyled, OptionsButtonTagStyled, PropertiesRowStyled } from '@/components/Product/style';
import AsyncAutoComplete from '@/components/Product/AsyncAutoComplete';

const OptionColorPicker = ({ availableColors, value, onChange }) => {
  const [active, setActive] = useState(false);
  const pickerRef = useClickAway(() => setActive(false));
  const [ignoreClick, setIgnoreClick] = useState(false);

  const togglePicker = useCallback(() => setActive((prev) => !prev), []);

  const handleChange = (color) => {
    if (color && color.hex) {
      onChange(color.hex);
      setIgnoreClick(true);
    }
  };

  const handleClickInsidePicker = () => {
    if (ignoreClick) {
      setIgnoreClick(false);
      setActive(true);
    }
  };

  return (
    <ColorPickerStyled>
      <button className="color-button" type="button" onClick={togglePicker} aria-label="Choose a color">
        <span className="view-color" style={{ background: value || '' }} />
      </button>
      <div
        className={classNames('popover', { active })}
        ref={pickerRef}
        onClick={handleClickInsidePicker}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setActive(false);
          }
        }}
        tabIndex={0}
        role="button"
        aria-label="Close overlay"
      >
        <div className="overlay" />
        <div className="picker-container">
          <SketchPicker color={value || '#ffffff'} onChange={handleChange} presetColors={availableColors} />
        </div>
      </div>
    </ColorPickerStyled>
  );
};

OptionColorPicker.propTypes = {
  availableColors: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

const OptionsVariants = ({ value = [], onChange, errors }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    form.setFieldsValue({ option_values: value || [] });
    setIsModalOpen(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onChange(values.option_values);
        setIsModalOpen(false);
      })
      .catch((info) => {
        console.error('Validation failed:', info);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hasValue = value.some(({ value }) => value !== '');

  return (
    <>
      {hasValue ? (
        <OptionsButtonTagStyled onClick={showModal}>
          <div className="edit-overlay" />
          <span className="edit">{t('products.variants.option_modal.edit')}</span>
          <div className="option-tags-container">
            {value.map(({ value }, idx) => {
              return value !== '' ? <Tag key={idx}>{value}</Tag> : null;
            })}
          </div>
        </OptionsButtonTagStyled>
      ) : (
        <Button type="dashed" onClick={showModal} style={{ width: '100%' }}>
          {t('products.variants.option_modal.add')}
        </Button>
      )}

      <Modal
        title={t('products.variants.option_modal.title')}
        open={isModalOpen}
        onOk={handleOk}
        okText={t('products.variants.option_modal.save')}
        onCancel={handleCancel}
        width={700}
        inert
      >
        <Form form={form} layout="vertical">
          <Form.List name="option_values">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name }) => {
                  return (
                    <PropertiesRowStyled key={key} gutter={10}>
                      <Col span={10}>
                        <Form.Item
                          name={[name, 'label']}
                          label={t('products.variants.option_modal.fields.name')}
                          rules={[{ required: true }]}
                          validateStatus={has(errors, `${name}.label`) ? 'error' : undefined}
                          help={get(errors, `${name}.label`)}
                        >
                          <AsyncAutoComplete resource="product-options/search" />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          name={[name, 'value']}
                          label={t('products.variants.option_modal.fields.value')}
                          rules={[{ required: true }]}
                          validateStatus={has(errors, `${name}.value`) ? 'error' : undefined}
                          help={get(errors, `${name}.value`)}
                        >
                          <AutoComplete
                            options={value.map((option) => ({
                              value: option.value,
                            }))}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          name={[name, 'color']}
                          label={t('products.variants.option_modal.fields.color')}
                          validateStatus={has(errors, `${name}.color`) ? 'error' : undefined}
                          help={get(errors, `${name}.color`)}
                        >
                          <OptionColorPicker presetColors={[]} />
                        </Form.Item>
                      </Col>
                      <Button icon={<DeleteOutlined />} onClick={() => remove(name)} danger />
                    </PropertiesRowStyled>
                  );
                })}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    {t('products.variants.option_modal.add_options')}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

OptionsVariants.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  errors: PropTypes.array,
};

export default OptionsVariants;
