import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from '@/components/buttons/buttons';

const FormSubmit = ({ loading = false, disabled = false }) => {
  const { t } = useTranslation();

  return (
    <Button htmlType="submit" type="primary" loading={loading} disabled={disabled}>
      {loading ? `${t('processing')}...` : t('save')}
    </Button>
  );
};

FormSubmit.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FormSubmit;
