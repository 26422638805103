import React, { useId } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from '@/widgets/AsyncSelect';
import { OrderFormItem, SelectStyle } from '@/components/Order/styled';

const ExtendedGoodSelect = ({ name, prefix, error, resource, params, disabled, multiple = false, required = false }) => {
  const containerID = useId();
  return (
    <SelectStyle id={containerID}>
      <OrderFormItem name={name} required={required} validateStatus={error ? 'error' : false} help={error}>
        <AsyncSelect
          resource={resource}
          params={params}
          disabled={disabled}
          multiple={multiple}
          getPopupContainer={() => document.getElementById(containerID)}
        />
      </OrderFormItem>
      <span className="prefix">{prefix}</span>
    </SelectStyle>
  );
};

ExtendedGoodSelect.propTypes = {
  name: PropTypes.string,
  prefix: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  resource: PropTypes.string.isRequired,
  params: PropTypes.object,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
};

export default ExtendedGoodSelect;
