import {
  BILLING_GOOD_TYPE_BOX,
  BILLING_GOOD_TYPE_FLOWER,
  BILLING_GOOD_TYPE_PAPER,
  BILLING_GOOD_TYPE_PLANT,
  BILLING_GOOD_TYPE_POSTCARD,
  BILLING_GOOD_TYPE_SWEET,
  BILLING_GOOD_TYPE_TAPE,
  BILLING_GOOD_TYPE_TOY,
} from '@/constants/products';

export const FLOWER = 'FLOWER';
export const PLANT = 'PLANT';
export const TOY = 'TOY';
export const SWEET = 'SWEET';
export const POSTCARD = 'POSTCARD';
export const PAPER = 'PAPER';
export const TAPE = 'TAPE';
export const BOX = 'BOX';

export const productTypeGoods = {
  [FLOWER]: BILLING_GOOD_TYPE_FLOWER,
  [PLANT]: BILLING_GOOD_TYPE_PLANT,
  [TOY]: BILLING_GOOD_TYPE_TOY,
  [SWEET]: BILLING_GOOD_TYPE_SWEET,
  [POSTCARD]: BILLING_GOOD_TYPE_POSTCARD,
  [PAPER]: BILLING_GOOD_TYPE_PAPER,
  [TAPE]: BILLING_GOOD_TYPE_TAPE,
  [BOX]: BILLING_GOOD_TYPE_BOX,
};
