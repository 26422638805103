import { Col, Popconfirm, Row } from 'antd';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useState } from 'react';
import StarRating from '@/components/Product/StartRating';
import { ApproveButtonStyled, CommentsTableStyled } from '@/components/Product/style';

const ApproveButton = ({ onApprove, approved }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <Popconfirm
      title={t('products.reviews_page.confirm_message')}
      onConfirm={() => {
        setLoading(true);
        onApprove(() => setLoading(false));
      }}
      okText={t('cities.fields.on_ok')}
    >
      <ApproveButtonStyled approve={String(approved)} loading={loading} icon={approved ? <CheckOutlined className="icon" /> : null} disabled={approved}>
        {approved ? t('products.reviews_page.confirmed') : t('products.reviews_page.confirm')}
      </ApproveButtonStyled>
    </Popconfirm>
  );
};

ApproveButton.propTypes = {
  onApprove: PropTypes.func,
  approved: PropTypes.bool,
};

const ReviewCard = ({ review, onApprove }) => {
  const { t } = useTranslation();

  return (
    <CommentsTableStyled>
      <ApproveButton onApprove={onApprove} approved={review.moderated} />
      <Row gutter={[16, 16]}>
        <Col className="rating-and-create" span={24}>
          <StarRating rate={review.rate} />
          <span className="created-at">{moment(review.created_at).locale('ua').format('LLLL')}</span>
        </Col>
        <Col span={24} className="personal-info-wrapper">
          <span className="title">{t('products.reviews_page.personal_info')}</span>
          <div className="personal-info-container">
            <div>
              <h6>{t('products.reviews_page.author_name')}:</h6>
              <span className="text">{review.author_name}</span>
            </div>
            <div>
              <h6>{t('products.reviews_page.author_email')}:</h6>
              {review.author_email ? <span>{review.author_email}</span> : <span style={{ color: '#d6ab00' }}>Немає інформації</span>}
            </div>
            <div>
              <h6>{t('products.reviews_page.author_phone')}:</h6>
              {review.author_phone ? <span>{review.author_phone}</span> : <span style={{ color: '#d6ab00' }}>Немає інформації</span>}
            </div>
          </div>
        </Col>
        <Col span={24} className="personal-info-comment">
          <span className="title">{t('products.reviews_page.comment')}</span>
          {review.content ? (
            <div className="comment-text">{review.content}</div>
          ) : (
            <p className="not-comment">{t('products.reviews_page.not_comment_message')}</p>
          )}
        </Col>
      </Row>
    </CommentsTableStyled>
  );
};

ReviewCard.propTypes = {
  review: PropTypes.object.isRequired,
  onApprove: PropTypes.func.isRequired,
};

export default ReviewCard;
