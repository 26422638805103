import { useCallback, useState } from 'react';
import { DeleteOutlined, DropboxOutlined, FormOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Empty, Form, message } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import PropTypes from 'prop-types';
import { get, has } from 'lodash';
import { OrderCard, ListStyle, TitleCard, ErrorMessage } from '@/components/Order/styled';
import GoodFormModal from '@/components/Order/GoodFormModal';

const GoodsForm = ({ cityId, storeId, onStoreChange, errorData }) => {
  const { t } = useTranslation();
  const form = useFormInstance();
  const [activeItem, setActiveItem] = useState(null);

  const editItem = useCallback(
    (name, onDelete) => {
      setActiveItem({
        index: name,
        value: form.getFieldValue(['products', name]),
        onChange: (values, newStore) => {
          if (storeId !== newStore) {
            onStoreChange(newStore);
          }
          form.setFieldValue(['products', name], values);
        },
        onDelete,
      });
    },
    [form, onStoreChange, storeId],
  );

  return (
    <>
      <GoodFormModal
        cityId={cityId}
        storeId={storeId}
        errorData={errorData}
        {...activeItem}
        onClose={() => {
          setActiveItem(null);
        }}
      />
      <TitleCard>
        <DropboxOutlined />
        <span className="title">{t('orders.fields.product')}</span>
      </TitleCard>
      <Form.List name="products">
        {(fields, { add, remove }) => (
          <OrderCard
            error={has(errorData, 'products') || undefined}
            actions={[
              <button
                type="button"
                className="add-button"
                key={0}
                onClick={() => {
                  if (cityId) {
                    const index = fields.length;
                    add({ name: '', count: 1, image: null, items: [], isNew: true }, index);
                    editItem(index, () => remove(index));
                  } else {
                    message.warning(t('orders.fields.select_city_message'));
                  }
                }}
              >
                <PlusOutlined style={{ fontSize: '16px', fontWeight: 900 }} />
                <span>{t('orders.fields.add_product')}</span>
              </button>,
            ]}
          >
            {fields.length ? (
              fields.map((field) => {
                if (form.getFieldValue(['products', field.name, 'isNew'])) return;

                return (
                  <ListStyle
                    key={field.name}
                    actions={[
                      <button key={0} type="button" aria-label="Edit product" onClick={() => editItem(field.name, () => remove(field.name))}>
                        <FormOutlined className="edit" />
                      </button>,
                      <button key={1} type="button" aria-label="Delete product" onClick={() => remove(field.name)}>
                        <DeleteOutlined className="delete" />
                      </button>,
                    ]}
                  >
                    <span
                      style={{
                        color: has(errorData, `products.${field.key}`) && Array.isArray(errorData.products) && 'red',
                      }}
                    >
                      {form.getFieldValue(['products', field.name, 'name'])}
                    </span>

                    <span className="count">{form.getFieldValue(['products', field.name, 'count'])} шт</span>

                    <ErrorMessage position>
                      {has(errorData, `products.${field.key}`) && Array.isArray(errorData.products) ? t('orders.fields.validation_message') : null}
                    </ErrorMessage>
                  </ListStyle>
                );
              })
            ) : (
              <Empty />
            )}
          </OrderCard>
        )}
      </Form.List>
      {has(errorData, 'products') && !Array.isArray(errorData.products) ? <ErrorMessage>{get(errorData, 'products')}</ErrorMessage> : null}
    </>
  );
};

GoodsForm.propTypes = {
  cityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onStoreChange: PropTypes.func,
  errorData: PropTypes.object,
};

export default GoodsForm;
