import { useTranslation } from 'react-i18next';
import { Checkbox, Col, Form, Row } from 'antd';
import PropTypes from 'prop-types';
import { FormWrapper } from '@/container/pages/style';
import { PRODUCT_TYPE_BUNDLE } from '@/constants/products';
import { Cards } from '@/components/cards/frame/cards-frame';
import FormInput from '@/form/FormInput';
import FormSelectAsync from '@/form/FormSelectAsync';
import FormTextArea from '@/form/FormTextArea';
import ProductImages from '@/components/Product/ProductImages';
import OptionsListItem from '@/components/Product/OptionsListItem';
import TableVariants from '@/components/Product/TableVariants';

const ProductDetailsForm = ({ form, id, handleSubmit, errors }) => {
  const { t } = useTranslation();

  return (
    <FormWrapper>
      <Form
        style={{ width: '100%' }}
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          type: PRODUCT_TYPE_BUNDLE,
          approved: true,
          categories: [],
          description: '',
          pictures: [],
          name: '',
          options: [],
          variants: [],
        }}
      >
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <div className="segment-block">
              <div className="segment-content">
                <Cards title={t('products.details')}>
                  <FormInput name="name" label={t('products.name')} error={errors.name} required placeholder="Троянда аваланч" />
                  <FormSelectAsync name="categories" label={t('products.categories')} resource="categories/search" multiple error={errors.categories} />
                  <FormTextArea name="description" label={t('products.description')} error={errors.description} placeholder="sdafa" />
                  <Form.Item name="approved" valuePropName="checked">
                    <Checkbox>{t('products.moderated')}</Checkbox>
                  </Form.Item>
                </Cards>
              </div>
            </div>
          </Col>

          <Col span={12}>
            <Form.Item name="pictures" noStyle>
              <ProductImages />
            </Form.Item>
          </Col>

          <Col span={24}>
            <div className="segment-block">
              <div className="segment-content">
                <OptionsListItem />
              </div>
            </div>
          </Col>

          <Col span={24}>
            <div className="segment-block">
              <div className="segment-content">
                <TableVariants errors={errors} id={id} />
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </FormWrapper>
  );
};

ProductDetailsForm.propTypes = {
  form: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
};

export default ProductDetailsForm;
