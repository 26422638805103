import React from 'react';
import { CreditCardOutlined, InfoCircleOutlined, ProfileOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { sumBy } from 'lodash';
import { TotalInfoContainer } from '@/components/Order/styled';
import priceFormat from '@/helpers/priceFormat';

const OrderSummaryInfo = ({ products = [], promoCodeDiscount, manualDiscount, deliveryPrice }) => {
  const { t } = useTranslation();

  const itemsSum = sumBy(products, (product) => {
    return (
      sumBy(product.items, (elem) => {
        return elem.price * elem.qty;
      }) * product.count
    );
  });

  const totalDiscount = priceFormat((Number(manualDiscount) || 0) + (Number(promoCodeDiscount) || 0), '');
  const totalPrice = priceFormat(Math.max(0, itemsSum - (promoCodeDiscount || 0) - (manualDiscount || 0) + (deliveryPrice || 0)), '');

  return (
    <TotalInfoContainer>
      <div className="wrapper-block" style={{ width: '45%' }}>
        <div className="title-block">
          <ProfileOutlined />
          {t('orders.fields.your_order')}
        </div>
        <ul className="list-container">
          {products
            .filter((el) => !el.isNew)
            .map((product, i) => {
              const price = sumBy(product.items, (elem) => {
                return elem.price * elem.qty * product.count;
              });

              return (
                <li className="item-list" key={i}>
                  <ul className="list-container">
                    <li className="item-list">
                      <span className="item-name">{product.name}</span>
                      <span className="dots" />
                      <span className="item-price">{priceFormat(price)}</span>
                    </li>
                    {product.items.map((item, i) => (
                      <li className="item-list item-elem" key={i}>
                        <span className="products-name">
                          - {item.name} ({item.qty} шт)
                        </span>
                        <span className="dots" />
                        <span className="products-price">{priceFormat(item.price)}</span>
                      </li>
                    ))}
                  </ul>
                </li>
              );
            })}
        </ul>
      </div>
      <div className="wrapper-block">
        <div className="title-block">
          <InfoCircleOutlined />
          {t('orders.fields.additional_information')}
        </div>
        <ul>
          <li className="item-list">
            <span>{t('orders.fields.items_sum')}</span>
            <span className="dots" />
            <span className="item-price">{priceFormat(itemsSum)}</span>
          </li>
          <li className="item-list">
            <span>{t('orders.fields.delivery_cost')}</span>
            <span className="dots" />
            <span className="item-price">
              {priceFormat(deliveryPrice || 0, '')} {t('orders.fields.uah')}
            </span>
          </li>
          <li className="item-list">
            <span>{t('orders.fields.discount')}</span>
            <span className="dots" />
            <span className="item-price">
              -{totalDiscount} {t('orders.fields.uah')}
            </span>
          </li>
        </ul>
      </div>
      <div className="wrapper-block" style={{ width: '25%' }}>
        <div className="total-price">
          <div className="title-block">
            <CreditCardOutlined />
            {t('orders.fields.sum_order')}
          </div>
          <div className="price">
            {totalPrice} {t('orders.fields.uah')}
          </div>
        </div>
      </div>
    </TotalInfoContainer>
  );
};

OrderSummaryInfo.propTypes = {
  deliveryPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  products: PropTypes.array,
  promoCodeDiscount: PropTypes.number,
  manualDiscount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default OrderSummaryInfo;
