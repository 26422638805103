import { Button, Modal, Popconfirm, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { DeleteOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { SeoCard, SeoTableActions } from '@/container/pages/cms/categories/style';
import ApiClient from '@/helpers/apiClient/ApiClient';
import CityFormSeo from '@/components/City/CityFormSEO';

const CitySEO = ({ cityId }) => {
  const { t } = useTranslation();
  const [seo, setSeo] = useState([]);
  const [activeId, setActiveId] = useState(false);

  useEffect(() => {
    ApiClient.call('get', `cities/${cityId}/seo`).data(({ data }) => {
      setSeo(data);
    });
  }, [cityId]);

  const handleDelete = (seo_id) => {
    ApiClient.call('delete', `cities/${cityId}/seo/${seo_id}`).then(() => {
      setSeo((prev) => prev.filter((item) => item.id !== seo_id));
    });
  };

  const onSuccess = (data) => {
    setSeo((prev) => {
      return activeId ? prev.map((item) => (item.id === activeId ? data : item)) : [...prev, data];
    });
    setActiveId(false);
  };

  const columns = [
    { title: t('cities.seo.fields.page'), dataIndex: 'page', key: 'page', align: 'start' },
    {
      title: t('cities.seo.fields.action'),
      key: 'actions',
      align: 'end',
      render: (_, record) => {
        return (
          <SeoTableActions>
            <Button icon={<FeatherIcon icon="edit" size={16} />} onClick={() => setActiveId(record.id)} className="action-button" />
            <Popconfirm
              title={t('cities.seo.fields.delete_popconfirm')}
              onConfirm={() => handleDelete(record.id)}
              okText={t('cities.seo.fields.ok_popconfirm')}
            >
              <Button danger icon={<DeleteOutlined size={16} />} className="action-button" />
            </Popconfirm>
          </SeoTableActions>
        );
      },
    },
  ];

  return (
    <SeoCard>
      <Button type="dashed" onClick={() => setActiveId(null)} className="add-button">
        {t('cities.seo.add_button')}
      </Button>

      <Table columns={columns} dataSource={seo} rowKey="id" pagination={false} className="seo-table" />

      <Modal open={activeId !== false} onCancel={() => setActiveId(false)} footer={null} width={1000} destroyOnClose>
        <CityFormSeo id={activeId} cityId={cityId} onSuccess={onSuccess} />
      </Modal>
    </SeoCard>
  );
};

CitySEO.propTypes = {
  cityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CitySEO;
