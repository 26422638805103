import { useTranslation } from 'react-i18next';
import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { CardToolbox, Main } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import withAuthLayout from '@/layouts/withAuthLayout';
import Table from '@/widgets/Table/Table';

const PromoCodeIndex = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        title: t('promo_codes.fields.name_promo_code'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('promo_codes.fields.code'),
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: t('promo_codes.fields.orders_count'),
        dataIndex: 'orders_count',
        key: 'orders_count',
      },
    ],
    [],
  );

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('promo_codes.list_page_header')}
          buttons={[
            <Button className="btn-add_new" size="default" type="primary" key="1">
              <Link to="/crm/promo-codes/create">+ {t('promo_codes.create_button')}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Table route="crm/promo-codes" resource="promo-codes" columns={columns} permissionNamespace="crm.promo_codes" />
      </Main>
    </>
  );
};

export default withAuthLayout(PromoCodeIndex);
