import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { PROGRESS_STATUSES } from '@/hooks/useFileUpload';
import { ProgressBarStyled } from '@/components/Product/style';

const ProgressBar = ({ file, style }) => {
  return (
    <>
      {file.status === 'error' ? (
        <Tooltip title={file.response}>
          <ProgressBarStyled percent={Math.round(file.progress) || 0} status={PROGRESS_STATUSES[file.status]} style={style} />
        </Tooltip>
      ) : (
        <ProgressBarStyled percent={Math.round(file.progress) || 0} status={PROGRESS_STATUSES[file.status]} style={style} />
      )}
    </>
  );
};

ProgressBar.propTypes = {
  file: PropTypes.object,
  style: PropTypes.object,
};

export default ProgressBar;
