import { useCallback, useEffect, useState } from 'react';
import { Col, Row, message } from 'antd';
import { FolderAddTwoTone } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Dragger from 'antd/es/upload/Dragger';
import PropTypes from 'prop-types';
import { ImageCard, PreviewContainer } from '@/container/pages/cms/sliderElements/style';
import ApiClient from '@/helpers/apiClient/ApiClient';
import LogoSpinner from '@/widgets/LogoSpinner';

const FormUploadImage = ({ onChange, value, multiple = false, directory = '', loading, setLoading }) => {
  const { t } = useTranslation();
  const [imagePreview, setImagePreview] = useState('');

  const handleChange = useCallback(
    async ({ file }) => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('file', file);
        if (directory) {
          formData.append('directory', directory);
        }

        const { id } = await ApiClient.call('post', 'pictures', {}, formData).getData();
        onChange(id);
      } catch (e) {
        message.error(e.message);
      } finally {
        setLoading(false);
      }
    },
    [directory],
  );

  useEffect(() => {
    if (value) {
      ApiClient.call('get', `pictures/${value}`).data(({ sizes }) => {
        setImagePreview(sizes.small);
      });
    }
  }, [value]);

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <Row gutter={10}>
          <Col span={12}>
            <Dragger beforeUpload={() => false} multiple={multiple} onChange={handleChange} showUploadList={false}>
              <FolderAddTwoTone style={{ fontSize: '75px' }} />
              <p className="ant-upload-text">{t('products.variant_images.dragger_message')}</p>
            </Dragger>
          </Col>
          <Col span={12}>
            <PreviewContainer>
              {imagePreview && !loading ? (
                <ImageCard>
                  <figure style={{ margin: 0, height: 155 }}>
                    <img src={imagePreview} alt="" />
                  </figure>
                </ImageCard>
              ) : loading ? (
                <div>
                  <LogoSpinner />
                </div>
              ) : (
                <span>Перегляд зображення</span>
              )}
            </PreviewContainer>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

FormUploadImage.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  directory: PropTypes.string,
};

export default FormUploadImage;
