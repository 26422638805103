import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment/moment';
import FeatherIcon from 'feather-icons-react';
import { Button } from 'antd';
import { CardToolbox, Main } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import withAuthLayout from '@/layouts/withAuthLayout';
import Table from '@/widgets/Table/Table';
import { DD_MM_Y, HH_mm } from '@/constants/momentFormat';
import { ProductReviewsComment } from '@/container/pages/style';
import StarRating from '@/components/Product/StartRating';
import ReviewModal from '@/components/Product/ReviewModal';

const ProductReviewsIndex = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState({});

  const columns = useMemo(
    () => [
      {
        title: t('products.product_reviews.fields.created_at'),
        dataIndex: 'created_at',
        key: 'created_at',
        width: 150,
        render: (value) => {
          const date = moment(value);
          return (
            <span>
              <p style={{ marginBottom: 0 }}>{date.format(DD_MM_Y)}</p>
              <p>{date.format(HH_mm)}</p>
            </span>
          );
        },
      },
      {
        title: t('products.product_reviews.fields.moderated'),
        dataIndex: 'moderated',
        key: 'moderated',
        width: 100,
        align: 'center',
        sorter: true,
        render: (value) => <span className={classNames('status-text', value ? 'active' : 'deactivate')}>{value ? 'Так' : 'Ні'}</span>,
      },
      {
        title: t('products.product_reviews.fields.rate'),
        dataIndex: 'rate',
        key: 'rate',
        align: 'center',
        render: (value) => <StarRating rate={value} />,
        width: 200,
      },
      {
        title: t('products.product_reviews.fields.product'),
        dataIndex: 'product',
        key: 'product',
        render: ({ name }) => <span>{name}</span>,
      },
      {
        title: t('products.product_reviews.fields.comment'),
        dataIndex: 'content',
        key: 'content',
        render: (value) => <ProductReviewsComment>{value}</ProductReviewsComment>,
        width: 300,
      },
    ],
    [],
  );

  return (
    <>
      <CardToolbox>
        <PageHeader ghost title={t('products.product_reviews.title')} />
      </CardToolbox>

      <ReviewModal reviewId={active.id} onCancel={() => setActive({})} onSuccess={active.cb} />

      <Main>
        <Table
          route="cms/products/product-reviews"
          resource="product-reviews"
          columns={columns}
          permissionNamespace="cms.products"
          defaultSort={{ field: 'moderated', direction: 'ascend' }}
          actionOptions={{ isDeletable: false, isEditable: false }}
          additionalActions={(row, setData) => {
            return (
              <Button
                style={{ cursor: 'pointer', boxShadow: 'none' }}
                onClick={() => {
                  setActive({
                    id: row.id,
                    cb: () => {
                      setData((data) => {
                        const index = data.findIndex((el) => el.id === row.id);
                        const items = [...data];
                        items[index] = { ...items[index], moderated: true };

                        return items;
                      });
                    },
                  });
                }}
              >
                <FeatherIcon icon="eye" size={16} />
              </Button>
            );
          }}
        />
      </Main>
    </>
  );
};

export default withAuthLayout(ProductReviewsIndex);
