import { useMemo } from 'react';
import { Select } from 'antd';
import propTypes from 'prop-types';
import { CalendarHeaderStyled } from '@/components/Store/style';

const MONTHS = ['січ', 'лют', 'бер', 'квіт', 'трав', 'черв', 'лип', 'серп', 'вер', 'жовт', 'лист', 'груд'];

const CalendarHeader = ({ value, selectedValue, setSelectedValue }) => {
  const yearOptions = useMemo(() => {
    const currentYear = selectedValue.year();
    return Array.from({ length: 20 }, (_, index) => ({
      label: currentYear - 10 + index,
      value: currentYear - 10 + index,
    }));
  }, [selectedValue.year()]);

  const handleYearChange = (year) => {
    const newValue = selectedValue.clone().year(year);
    setSelectedValue(newValue);
  };

  const handleMonthChange = (month) => {
    const newValue = selectedValue.clone().month(month);
    setSelectedValue(newValue);
  };

  return (
    <CalendarHeaderStyled>
      <Select size="small" value={value.year()} onChange={handleYearChange} options={yearOptions} />
      <Select
        size="small"
        value={value.month()}
        onChange={handleMonthChange}
        options={MONTHS.map((month, index) => ({
          label: month,
          value: index,
        }))}
      />
    </CalendarHeaderStyled>
  );
};

CalendarHeader.propTypes = {
  value: propTypes.object,
  selectedValue: propTypes.object,
  setSelectedValue: propTypes.func,
};

export default CalendarHeader;
