import { InputNumberStyle, InputStyle, OrderFormItem } from '@/components/Order/styled';

// eslint-disable-next-line react/prop-types
const InputOrder = ({ prefix, name, label, type = 'text', required, error, disabled = false, rules, ...props }) => {
  return (
    <OrderFormItem
      required={required}
      rules={rules}
      validateStatus={error ? 'error' : false}
      help={error}
      name={name}
      label={label}
      labelCol={{ style: { display: 'none' } }}
    >
      {type === 'text' ? (
        <InputStyle prefix={prefix} disabled={disabled} {...props} />
      ) : (
        <InputNumberStyle prefix={prefix} disabled={disabled} min={1} {...props} type={type} />
      )}
    </OrderFormItem>
  );
};

export default InputOrder;
