import { useTranslation } from 'react-i18next';
import { Button, Table, Form, Popconfirm, Select } from 'antd';
import { DeleteOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { useCallback, useState } from 'react';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { get, has } from 'lodash';
import PropTypes from 'prop-types';
import slugify from 'slugify';
import { CardVariantsStyled, SlugContainerStyled } from '@/components/Product/style';
import FormSelectAsync from '@/form/FormSelectAsync';
import { PRODUCT_TYPE_BUNDLE, PRODUCT_TYPE_SINGLE } from '@/constants/products';
import FormInput from '@/form/FormInput';
import { ErrorMessage } from '@/components/Order/styled';
import VariantImages from '@/components/Product/VariantImages';
import VariantsProperties from '@/components/Product/VariantsProperties';
import OptionsVariants from '@/components/Product/OptionsVariants';
import DrawerVariant from '@/components/Product/DrawerVariant';
import FrontAppLinkByCityDropdown from '@/widgets/FrontAppLinkByCity';

const AddonSlug = ({ slugPath }) => {
  return (
    <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => get(prevValues, slugPath) !== get(nextValues, slugPath)}>
      {({ getFieldValue }) => <FrontAppLinkByCityDropdown pathname={`product/${getFieldValue(slugPath)}`} disabled={!getFieldValue(slugPath)} />}
    </Form.Item>
  );
};

AddonSlug.propTypes = {
  slugPath: PropTypes.any,
};

const TableVariants = ({ errors, id }) => {
  const { t } = useTranslation();
  const form = useFormInstance();
  const [activeItem, setActiveItem] = useState(null);

  const generateVariants = useCallback(() => {
    const data = form.getFieldValue('options').reduce(
      (acc, curr) => {
        return acc.flatMap((a) => curr.values.map((b) => [...a, { value: b, label: curr.label, option_id: curr.id }]));
      },
      [[]],
    );

    const variants = data.map((el) => {
      return {
        name: '',
        type: PRODUCT_TYPE_SINGLE,
        good_id: '',
        slug: '',
        option_values: el,
      };
    });

    form.setFieldValue('variants', variants);
  }, [form]);

  const handleDelete = (key) => {
    const newData = form.getFieldValue('variants').toSpliced(key, 1);
    form.setFieldValue('variants', newData);
  };

  const columns = [
    {
      dataIndex: 'pictures',
      key: 'pictures',
      width: 100,
      render: (value, record, index) => {
        return (
          <Form.Item name={[index, 'pictures']}>
            <VariantImages />
          </Form.Item>
        );
      },
    },
    {
      title: t('products.variants.table_title.type'),
      dataIndex: 'type',
      key: 'type',
      render: (value, record, index) => (
        <Form.Item
          shouldUpdate={(prev, next) => {
            return get(prev, ['variants', index, 'type']) !== get(next, ['variants', index, 'type']);
          }}
          required
          help={get(errors, `variants.${index}.type`)}
          validateStatus={get(errors, `variants.${index}.type`) ? 'error' : false}
        >
          {({ getFieldValue, setFieldValue }) => {
            return (
              <Select
                value={getFieldValue(['variants', index, 'type'])}
                onChange={(value) => {
                  if (value === PRODUCT_TYPE_BUNDLE) {
                    setFieldValue(['variants', index, 'good_id'], null);
                  }
                  setFieldValue(['variants', index, 'type'], value);
                }}
                options={[
                  { value: PRODUCT_TYPE_SINGLE, label: t(`products.types.${PRODUCT_TYPE_SINGLE}`) },
                  { value: PRODUCT_TYPE_BUNDLE, label: t(`products.types.${PRODUCT_TYPE_BUNDLE}`) },
                ]}
              />
            );
          }}
        </Form.Item>
      ),
    },
    {
      title: t('products.variants.table_title.name'),
      dataIndex: 'name',
      key: 'name',
      render: (value, record, index) => <FormInput name={[index, 'name']} error={get(errors, `variants.${index}.name`)} />,
    },
    {
      title: t('products.variants.table_title.good_id'),
      dataIndex: 'good_id',
      key: 'good_id',
      render: (text, record, index) => {
        return (
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => {
              return get(prev, ['variants', index, 'type']) !== get(next, ['variants', index, 'type']);
            }}
          >
            {({ getFieldValue }) => {
              return getFieldValue(['variants', index, 'type']) === PRODUCT_TYPE_BUNDLE ? (
                <>
                  <Button
                    type={has(errors, `variants.${index}.items`) ? 'danger' : 'default'}
                    onClick={() => {
                      setActiveItem(index);
                    }}
                    style={{ whiteSpace: 'wrap', height: 'fit-content' }}
                  >
                    {t('products.variants.edit_inventory')}
                  </Button>
                  {has(errors, `variants.${index}.items`) && (
                    <ErrorMessage style={{ fontSize: '14px', fontFamily: 'Inter, sans-serif' }}>{t('products.variants.edit_inventory_message')}</ErrorMessage>
                  )}
                </>
              ) : (
                <FormSelectAsync resource="warehouse/goods/search" name={[index, 'good_id']} error={get(errors, `variants.${index}.good_id`)} />
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: t('products.variants.table_title.slug'),
      dataIndex: 'slug',
      key: 'slug',
      render: (value, record, index) => {
        return (
          <SlugContainerStyled>
            <FormInput
              validateStatus={get(errors, `variants.${index}.slug`) ? 'error' : ''}
              error={get(errors, `variants.${index}.slug`)}
              name={[index, 'slug']}
              addonAfter={<AddonSlug slugPath={['variants', index, 'slug']} />}
            />
            <Form.Item dependencies={[['variants', index, 'name']]} noStyle>
              {({ getFieldValue, setFieldValue }) => {
                const name = getFieldValue(['variants', index, 'name']) || '';

                const makeSlug = () => {
                  const slug = slugify(name, { lower: true, strict: true });
                  setFieldValue(['variants', index, 'slug'], slug);
                };

                return (
                  <Button className="generation-btn" onClick={makeSlug} disabled={!name}>
                    <ThunderboltOutlined />
                  </Button>
                );
              }}
            </Form.Item>
          </SlugContainerStyled>
        );
      },
    },
    {
      title: t('products.variants.table_title.options'),
      dataIndex: 'option_values',
      key: 'option_values',
      render: (value, record, index) => {
        return (
          <Form.Item noStyle name={[index, 'option_values']}>
            <OptionsVariants errors={get(errors, `variants.${index}.option_values`)} />
          </Form.Item>
        );
      },
      onCell: (record, rowIndex) => {
        const error = get(errors, `variants.${rowIndex}.option_values`);
        return {
          className: error ? 'border-red' : '',
        };
      },
    },
    {
      title: t('products.variants.table_title.properties'),
      key: 'properties',
      dataIndex: 'properties',
      width: 150,
      render: (value, record, index) => (
        <Form.Item noStyle name={[index, 'properties']}>
          <VariantsProperties errors={get(errors, `variants.${index}.properties`)} />
        </Form.Item>
      ),
      onCell: (record, rowIndex) => {
        const error = get(errors, `variants.${rowIndex}.properties`);
        return {
          className: error ? 'border-red' : '',
        };
      },
    },
    {
      key: 'operation',
      fixed: 'right',
      width: 50,
      render: (value, record) => {
        return (
          <>
            <Popconfirm title={t('products.variants.popconfirm_title')} onConfirm={() => handleDelete(record.key)} okText={t('products.variants.ok_text')}>
              <DeleteOutlined />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Form.Item name={['variants', activeItem, 'items']} noStyle>
        <DrawerVariant open={activeItem !== null} onClose={() => setActiveItem(null)} errors={get(errors, `variants.${activeItem}.items`)} />
      </Form.Item>

      <Form.List name="variants">
        {(fields, { add }) => {
          const handleAdd = () => {
            const newVariant = {
              option_values: form.getFieldValue('variants').map((option) => ({
                value: '',
                label: option.label,
                option_id: option.id,
              })),
              type: PRODUCT_TYPE_SINGLE,
              properties: [],
            };

            add(newVariant);
          };

          return (
            <CardVariantsStyled
              title={t('products.variants.title')}
              error={errors?.variants}
              extra={[
                <Button key="1" onClick={handleAdd}>
                  {t('products.variants.add')}
                </Button>,
                !id && (
                  <Button key="2" onClick={generateVariants} disabled={!form.getFieldValue('options').length}>
                    {t('products.variants.generate')}
                  </Button>
                ),
              ]}
            >
              <Table
                size="small"
                bordered
                dataSource={form.getFieldValue('variants').map((el, i) => ({ ...el, key: i }))}
                columns={columns}
                pagination={false}
                scroll={{
                  x: 1000,
                }}
              />
            </CardVariantsStyled>
          );
        }}
      </Form.List>
    </>
  );
};

TableVariants.propTypes = {
  errors: PropTypes.object,
  id: PropTypes.string,
};

export default TableVariants;
