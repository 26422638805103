import { Button } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withAuthLayout from '@/layouts/withAuthLayout';
import { CardToolbox, Main } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import Table from '@/widgets/Table/Table';

const TaxCodesIndex = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        title: t('warehouse.tax_codes.tax_name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('warehouse.tax_codes.tax_code'),
        dataIndex: 'code',
        key: 'code',
      },
    ],
    [],
  );

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('warehouse.tax_codes.header_title')}
          buttons={[
            <Button className="btn-add_new" size="default" type="primary" key="1">
              <Link to="/warehouse/tax-codes/create">+ {t('warehouse.tax_codes.create_button')}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Table route="warehouse/tax-codes" resource="warehouse/tax-codes" columns={columns} permissionNamespace="warehouse.tax_codes" />
      </Main>
    </>
  );
};

export default withAuthLayout(TaxCodesIndex);
