import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row, Space } from 'antd';
import FontAwesome from 'react-fontawesome';
import { DeleteOutlined } from '@ant-design/icons';
import { PageHeader } from '@/components/page-headers/page-headers';
import { Main } from '@/container/styled';
import { Cards } from '@/components/cards/frame/cards-frame';
import { ActionsWrapper, FormWrapper } from '@/container/pages/style';
import FormSubmit from '@/form/FormSubmit';
import useFormValues from '@/hooks/useFormValues';
import withAuthLayout from '@/layouts/withAuthLayout';
import FormSelectAsync from '@/form/FormSelectAsync';
import { ItemsStyled } from '@/container/pages/cms/mainPageCarousel/styles';
import { Button } from '@/components/buttons/buttons';
import IconInput from '@/components/iconInput/iconInput';

function HeadCategoriesForm() {
  const { t } = useTranslation();
  const { id, form, loading, handleSubmit, errors } = useFormValues('header-categories');

  return (
    <>
      <PageHeader ghost title={id ? t('head_categories.edit_page_header') : t('head_categories.create_page_header')} />
      <Main>
        <Cards headless>
          <Row justify="center">
            <Col xs={24} md={20} lg={16} xl={12}>
              <FormWrapper>
                <Form style={{ width: '100%' }} form={form} layout="vertical" onFinish={handleSubmit}>
                  <FormSelectAsync name="city_id" resource="cities/search" label={t('head_categories.fields.city_id')} error={errors.city_id} required />

                  <Form.List name="categories">
                    {(fields, { add, remove, move }) => (
                      <div>
                        <h3>{t('head_categories.categories_title')}</h3>
                        <ItemsStyled>
                          <thead>
                            <tr>
                              <th style={{ width: 50 }}>{t('head_categories.fields.manner')}</th>
                              <th>{t('head_categories.fields.title')}</th>
                              <th>{t('head_categories.fields.icons')}</th>
                              <th style={{ width: 200 }}>{t('head_categories.fields.operation')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map((field, index) => {
                              return (
                                <tr key={field.name}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <FormSelectAsync
                                      {...field}
                                      name={[field.name, 'category_id']}
                                      resource="categories/search"
                                      error={errors[`categories.${field.name}.category_id`]}
                                      required
                                    />
                                  </td>
                                  <td style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Form.Item name={[field.name, 'icon_name']}>
                                      <IconInput />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Space>
                                      <Button type="primary" icon={<FontAwesome name="arrow-circle-down" />} onClick={() => move(index, index + 1)} />
                                      <Button type="primary" icon={<FontAwesome name="arrow-circle-up" />} onClick={() => move(index, index - 1)} />
                                      <Button type="danger" icon={<DeleteOutlined />} onClick={() => remove(field.name)} />
                                    </Space>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </ItemsStyled>
                        <Button onClick={() => add({ category_id: '', icon_name: '' })}>{t('add')}</Button>
                      </div>
                    )}
                  </Form.List>
                  <ActionsWrapper>
                    <FormSubmit loading={loading} />
                  </ActionsWrapper>
                </Form>
              </FormWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
}

export default withAuthLayout(HeadCategoriesForm);
