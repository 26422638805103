import moment from 'moment/moment';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { DD_MM_Y } from '@/constants/momentFormat';

const DeliveryInfoCard = ({ order }) => {
  const { t } = useTranslation();

  const details = [
    { label: t('orders.type'), value: t(`orders.delivery_types.${order.delivery_type}`) },
    {
      label: t('orders.date_delivery_or_pickup'),
      value: moment(order.delivery_date).format(DD_MM_Y),
    },
    {
      label: t('orders.period_delivery_or_pickup'),
      value: `${order.delivery_data.time_from.replace(/:00$/, '')} - ${order.delivery_data.time_to.replace(/:00$/, '')}`,
    },
    { label: t('orders.address'), value: order.delivery_address },
    {
      label: t('orders.recipient'),
      value: `${order.recipient_name}, ${order.recipient_phone_number}`,
    },
    ...(order.comment ? [{ label: t('orders.comment'), value: order.comment }] : []),
    ...(order.delivery_details ? [{ label: t('orders.to_courier'), value: order.delivery_details }] : []),
  ];

  return (
    <Card title={t('orders.delivery')} style={{ height: '100%' }}>
      <ul>
        {details.map((detail, index) => (
          <li key={index} className="d-flex justify-content-between mb-20 gap-10 ">
            <strong>{detail.label}</strong>
            <span>{detail.value}</span>
          </li>
        ))}
      </ul>
    </Card>
  );
};

DeliveryInfoCard.propTypes = {
  order: propTypes.object,
};

export default DeliveryInfoCard;
