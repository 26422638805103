import { useTranslation } from 'react-i18next';
import React from 'react';
import { MFIcon } from '@million-flowers/mf-icon-component';
import { CardToolbox, Main } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import Table from '@/widgets/Table/Table';
import withAuthLayout from '@/layouts/withAuthLayout';

const ProductPropertiesIndex = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        title: t('products.product_properties.fields.name'),
        dataIndex: 'label',
        key: 'label',
      },
      {
        title: t('products.product_properties.fields.icon'),
        dataIndex: 'icon',
        key: 'icon',
        render: (value) => <MFIcon icon={value} />,
      },
    ],
    [],
  );

  return (
    <>
      <CardToolbox>
        <PageHeader ghost title={t('products.product_properties.title')} />
      </CardToolbox>

      <Main>
        <Table route="cms/products/product-properties" resource="product-properties" columns={columns} permissionNamespace="cms.products" />
      </Main>
    </>
  );
};

export default withAuthLayout(ProductPropertiesIndex);
