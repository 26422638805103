import { Checkbox, Col, Form, Row, Tooltip } from 'antd';
import FontAwesome from 'react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormWrapper } from '@/container/pages/style';
import FormInput from '@/form/FormInput';
import FormUploadImage from '@/form/FormUploadImage';
import FormSubmit from '@/form/FormSubmit';
import { Cards } from '@/components/cards/frame/cards-frame';
import ApiClient from '@/helpers/apiClient/ApiClient';
import useFormValues from '@/hooks/useFormValues';

const GeneralCategoryInfo = () => {
  const { t } = useTranslation();
  const { form, id, model, handleSubmit, loading, errors } = useFormValues('categories');
  const isShowOnMainPage = model.is_show_on_main_page;
  const [availableToShowOnMainPage, setAvailableToShowOnMainPage] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);

  useEffect(() => {
    ApiClient.call('get', 'categories/available-to-show-on-main-page').data((data) => {
      setAvailableToShowOnMainPage(data);
    });
  }, []);

  return (
    <Cards headless>
      <Row justify="center">
        <Col md={14} xs={24}>
          <FormWrapper>
            <Form
              style={{ width: '100%' }}
              form={form}
              layout="vertical"
              onFinish={handleSubmit}
              initialValues={{
                is_active: true,
                is_show_on_main_page: false,
              }}
            >
              <FormInput name="name" label={t('categories.fields.name')} error={errors.name} placeholder="Троянди" />
              {id && <FormInput name="slug" disabled label={t('categories.fields.slug')} error={errors.slug} placeholder="Троянди" />}
              {/* <FormFile accept="image/*" name="file" label={t('categories.fields.image')} /> */}

              <Form.Item name="picture_id" label={t('categories.fields.image')} validateStatus={errors.pictures ? 'error' : false} help={errors.pictures}>
                <FormUploadImage directory="categories" loading={loadingImage} setLoading={setLoadingImage} />
              </Form.Item>

              <Form.Item name="is_active" valuePropName="checked">
                <Checkbox>{t('categories.fields.is_active')}</Checkbox>
              </Form.Item>
              <Form.Item name="is_for_new_products" valuePropName="checked">
                <Checkbox>{t('categories.fields.is_for_new_products')}</Checkbox>
              </Form.Item>
              <Form.Item name="is_show_on_main_page" valuePropName="checked">
                <Checkbox disabled={!isShowOnMainPage && !availableToShowOnMainPage}>
                  <span style={{ marginRight: 10 }}>{t('categories.fields.is_show_on_main_page')}</span>
                  {!availableToShowOnMainPage && !isShowOnMainPage && (
                    <Tooltip title={t('categories.disabled_show_on_main_page_explain')}>
                      <FontAwesome name="question-circle-o" className="super-crazy-colors" />
                    </Tooltip>
                  )}
                </Checkbox>
              </Form.Item>
              <FormSubmit loading={loading} disabled={loadingImage} />
            </Form>
          </FormWrapper>
        </Col>
      </Row>
    </Cards>
  );
};

export default GeneralCategoryInfo;
