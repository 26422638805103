import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Popover } from '@/components/statistic/siteStatistic/calendar/Popover';
import DateRangePickerOne from '@/components/statistic/siteStatistic/calendar/dataPicker';
import { Button } from '@/components/buttons/buttons';
import { SpanPeriod } from '@/components/statistic/siteStatistic/calendar/styled';
import { YYYY_MM_DD } from '@/constants/momentFormat';

const formatDays = (days) => {
  if (typeof days !== 'number' || days <= 0) {
    return '';
  }
  if (days === 1) {
    return 'За останній день';
  }
  if (days > 1 && days < 5) {
    return `За останні ${days} дні`;
  }
  return `За останні ${days} днів`;
};

const formatDateRange = (startDate, endDate) => {
  moment.locale('uk');

  const start = moment(startDate, YYYY_MM_DD);
  const end = moment(endDate, YYYY_MM_DD);

  const startFormat = start.year() === end.year() ? 'D MMM' : 'D MMM YYYY';
  const startDay = start.format(startFormat);
  const endDay = end.format('D MMM YYYY');

  return `${startDay} - ${endDay}`;
};

const Calendar = ({ range, setRange, startDate, endDate, maxDate }) => {
  const [open, setOpen] = useState(false);

  const content = (
    <>
      <DateRangePickerOne range={range} setRange={setRange} onApply={() => setOpen(false)} maxDate={maxDate} />
    </>
  );

  return (
    <Popover placement="bottomRight" title="" content={content} action="click" onOpenChange={setOpen} open={open}>
      <Button size="small" type="white">
        <SpanPeriod>{formatDays(moment(endDate).diff(moment(startDate), 'days'))}</SpanPeriod>
        <span>{formatDateRange(startDate, endDate)}</span>
      </Button>
    </Popover>
  );
};

Calendar.propTypes = {
  setRange: PropTypes.func.isRequired,
  range: PropTypes.object.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

export default Calendar;
