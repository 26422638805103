import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import { get } from 'lodash';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import ApiClient from '@/helpers/apiClient/ApiClient';
import FormSelectAsync from '@/form/FormSelectAsync';
import FormInput from '@/form/FormInput';
import FormTextArea from '@/form/FormTextArea';

const CategoriesFormSeo = ({ id, onCreate, onUpdate, categoryId }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      ApiClient.call('get', `categories/${categoryId}/seo/${id}`)
        .data(({ data }) => {
          form.setFieldsValue(data);
        })
        .validation((info, error) => setError(error));
    }
  }, [id]);

  const handleSaveOrEdit = (values) => {
    const method = id ? 'patch' : 'post';
    const url = id ? `categories/${categoryId}/seo/${id}` : `categories/${categoryId}/seo`;
    const cb = id ? onUpdate : onCreate;

    ApiClient.call(method, url, {}, values)
      .data(({ data }) => {
        form.setFieldsValue(data);
        cb(data);
      })
      .validation((info, errors) => setError(errors));
  };

  return (
    <Form layout="vertical" initialValues={{ city_id: null, title: '', heading: '', heading_2: '', content: '' }} form={form} onFinish={handleSaveOrEdit}>
      <FormSelectAsync label={t('categories.seo.city_name')} name="city_id" resource="cities/search" error={get(error, 'city_id')} />

      <FormInput name="title" label={t('categories.seo.title')} error={get(error, 'title')} />

      <FormTextArea name="description" label={t('categories.seo.description')} error={get(error, 'description')} rows={2} />

      <FormInput name="og_title" label={t('categories.seo.og_title')} error={get(error, 'og_title')} />
      <FormInput name="og_description" label={t('categories.seo.og_description')} error={get(error, 'og_description')} />

      <FormInput name="heading" label={t('categories.seo.heading')} error={get(error, 'heading')} />
      <FormInput name="heading_2" label={t('categories.seo.heading_2')} error={get(error, 'heading_2')} />

      <Form.Item name="content" label={t('categories.seo.content')} validateStatus={get(error, 'content') ? 'error' : null} help={get(error, 'content')}>
        <ReactQuill theme="snow" />
      </Form.Item>

      <Button htmlType="submit">{t('categories.seo.save_button')}</Button>
    </Form>
  );
};

CategoriesFormSeo.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  categoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CategoriesFormSeo;
