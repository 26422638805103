import styled from 'styled-components';

const ImageCard = styled.div`
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 5px 20px ${({ theme }) => theme['light-color']}03;
  max-height: 400px;

  figure {
    width: auto;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: fill;
    }
  }

  .type {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
`;

const PreviewContainer = styled.div`
  width: 100%;
  height: 200px;
  padding: 0 5px;
  border: 1px dashed rgb(198, 208, 220);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(198, 208, 220);
  font-size: 15px;
  overflow: hidden;
`;

export { ImageCard, PreviewContainer };
