import { Col, Modal, Row } from 'antd';
import { MFIcon } from '@million-flowers/mf-icon-component';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useToggle } from '@/hooks/useToggle';
import { AddIconButton } from '@/components/iconInput/style';
import {
  ICON_BOUQUET,
  ICON_CHAMOMILE,
  ICON_FLOWER_1,
  ICON_FLOWER_2,
  ICON_FLOWER_3,
  ICON_FLOWER_4,
  ICON_HEARTS,
  ICON_MEDAL,
  ICON_PALLETE,
  ICON_PEONY,
  ICON_ROSE,
  ICON_SALE,
  ICON_SIZE,
  ICON_VAZON,
} from '@/constants/mf-icons';

const iconsName = [
  ICON_FLOWER_1,
  ICON_FLOWER_2,
  ICON_FLOWER_3,
  ICON_FLOWER_4,
  ICON_HEARTS,
  ICON_PEONY,
  ICON_ROSE,
  ICON_SALE,
  ICON_VAZON,
  ICON_CHAMOMILE,
  ICON_SIZE,
  ICON_PALLETE,
  ICON_MEDAL,
  ICON_BOUQUET,
];

const IconInput = ({ value, onChange }) => {
  const [modalOpen, toggleModal] = useToggle();
  const { t } = useTranslation();

  return (
    <>
      <AddIconButton type="button" onClick={toggleModal}>
        {value ? <MFIcon icon={value} style={{ fontSize: '35px' }} /> : '+'}
      </AddIconButton>

      <Modal title={t('head_categories.fields.icons')} open={modalOpen} footer={null} onCancel={toggleModal}>
        <Row gutter={[16, 16]}>
          {iconsName.map((item, key) => (
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={6}
              key={key}
              onClick={() => {
                onChange(item);
                toggleModal();
              }}
            >
              <MFIcon icon={item} style={{ fontSize: 70, cursor: 'pointer' }} />
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  );
};

IconInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default IconInput;
