import styled from 'styled-components';

const AddIconButton = styled.button`
  border-radius: 10px;
  width: 50px;
  height: 50px;
  border: 2px dashed;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  background: transparent;
  margin-bottom: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: #4347d9;
  }
`;

export { AddIconButton };
