import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import React from 'react';
import { NavLink, Route, Routes, useLocation } from 'react-router-dom';
import { last } from 'lodash/array';
import { Main } from '../../../styled';
import withAuthLayout from '@/layouts/withAuthLayout';
import useFormValues from '@/hooks/useFormValues';
import { PageHeader } from '@/components/page-headers/page-headers';
import GeneralCategoryInfo from '@/components/Categories/GeneralCategoryInfo';
import CategoriesSeo from '@/components/Categories/CategoriesSEO';

const CategoryForm = () => {
  const { t } = useTranslation();
  const { id } = useFormValues('categories');
  const { pathname } = useLocation();
  const activeKey = last(pathname.split('/'));

  const tabs = [
    {
      label: <NavLink to="">Загальна інформація</NavLink>,
      key: id,
    },
    {
      label: <NavLink to="seo">SEO</NavLink>,
      key: 'seo',
    },
  ];

  return (
    <>
      <PageHeader ghost title={t(id ? 'categories.edit_page_header' : 'categories.create_page_header')} />
      <Main>
        {id ? (
          <>
            <Tabs items={tabs} activeKey={activeKey} />
            <Routes>
              <Route path="" element={<GeneralCategoryInfo />} />
              <Route path="seo" element={<CategoriesSeo categoryId={id} />} />
            </Routes>
          </>
        ) : (
          <GeneralCategoryInfo />
        )}
      </Main>
    </>
  );
};

export default withAuthLayout(CategoryForm);
