import { Col, Form, Row } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import PropTypes from 'prop-types';
import withAuthLayout from '@/layouts/withAuthLayout';
import { PageHeader } from '@/components/page-headers/page-headers';
import { Main } from '@/container/styled';
import { Cards } from '@/components/cards/frame/cards-frame';
import { ActionsWrapper, FormWrapper } from '@/container/pages/style';
import FormInput from '@/form/FormInput';
import FormTextArea from '@/form/FormTextArea';
import FormSubmit from '@/form/FormSubmit';
import useFormValues from '@/hooks/useFormValues';
import AsyncSelect from '@/widgets/AsyncSelect';
import FormSelect from '@/form/FormSelect';
import { BOX, FLOWER, PAPER, PLANT, POSTCARD, productTypeGoods, SWEET, TAPE, TOY } from '@/constants/goods';
import { GoodsFormStyle, GoodsTableWrapper } from '@/container/pages/warehouse/goods/styled';
import GoodInventory from '@/components/warehouse/GoodInventory';
import GoodBillingInventory from '@/components/warehouse/GoodBillingInventory';

const SelectCharacteristic = ({ value, onChange, goodId }) => {
  const form = useFormInstance();

  return (
    <AsyncSelect
      value={value}
      onChange={(value, ext) => {
        onChange(value);
        form.setFieldValue('billing_characteristic_id', value);
        form.setFieldValue('name', ext?.label || '');
        if (ext?.label) {
          const height = get(ext.label.match(/(\d+)\sсм/), '1');
          form.setFieldValue('height', height);
        }
      }}
      disabled={!goodId}
      resource="billing/goods-characteristic-list"
      params={{ billing_good_id: goodId }}
    />
  );
};

SelectCharacteristic.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  onChange: PropTypes.func,
  goodId: PropTypes.any,
};

const GoodsForm = () => {
  const { t } = useTranslation();
  const { form, id, loading, handleSubmit, errors } = useFormValues('warehouse/goods');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const productType = Form.useWatch('type', form);
  const billingGoodId = Form.useWatch('billing_good_id', form);
  const billingGoodCharacteristicId = Form.useWatch('billing_characteristic_id', form);

  return (
    <>
      <PageHeader ghost title={id ? t('warehouse.goods.header_title_edit') : t('warehouse.goods.header_title_create')} />
      <Main>
        <Cards headless>
          <FormWrapper>
            <GoodsFormStyle style={{ width: '100%' }} form={form} layout="vertical" onFinish={handleSubmit}>
              <Row gutter={[10, 20]}>
                <Col span={8}>
                  <FormSelect
                    name="type"
                    label={t('warehouse.goods.fields.type')}
                    required
                    error={errors.type}
                    options={[
                      { value: FLOWER, label: t(`warehouse.types.${FLOWER}`) },
                      { value: TOY, label: t(`warehouse.types.${TOY}`) },
                      { value: POSTCARD, label: t(`warehouse.types.${POSTCARD}`) },
                      { value: PLANT, label: t(`warehouse.types.${PLANT}`) },
                      { value: SWEET, label: t(`warehouse.types.${SWEET}`) },
                      { value: PAPER, label: t(`warehouse.types.${PAPER}`) },
                      { value: TAPE, label: t(`warehouse.types.${TAPE}`) },
                      { value: BOX, label: t(`warehouse.types.${BOX}`) },
                    ]}
                  />
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={t('warehouse.goods.fields.billing_good_id')}
                    name="billing_good_id"
                    required
                    validateStatus={errors.billing_good_id ? 'error' : false}
                    help={errors.billing_good_id}
                  >
                    <AsyncSelect resource="billing/goods-list" params={{ type: productTypeGoods[productType] }} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={t('warehouse.goods.fields.billing_characteristic_id')}
                    validateStatus={errors.billing_characteristic_id ? 'error' : false}
                    help={errors.billing_characteristic_id}
                    name="billing_characteristic_id"
                  >
                    <SelectCharacteristic goodId={billingGoodId} />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <FormInput name="name" label={t('warehouse.goods.fields.name')} required error={errors.name} />
                </Col>
                <Col span={8}>
                  <FormInput name="sku" label={t('warehouse.goods.fields.sku')} error={errors.sku} />
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={t('warehouse.goods.fields.tax_code')}
                    name="tax_code_id"
                    validateStatus={errors.tax_code ? 'error' : false}
                    help={errors.tax_code}
                  >
                    <AsyncSelect resource="warehouse/tax-codes/search" />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    label={t('warehouse.goods.fields.oversell_limit_groups')}
                    name="oversell_limit_group_id"
                    validateStatus={errors.oversell_limit_groups ? 'error' : false}
                    help={errors.oversell_limit_groups}
                  >
                    <AsyncSelect resource="warehouse/oversell-limit-groups/search" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <FormInput name="color" label={t('warehouse.goods.fields.color')} error={errors.color} />
                </Col>
                <Col span={6}>
                  <FormInput name="height" label={t('warehouse.goods.fields.height')} error={errors.height} />
                </Col>
                <Col span={6}>
                  <FormInput name="max_comment_symbols" label={t('warehouse.goods.fields.max_comment_symbols')} error={errors.max_comment_symbols} />
                </Col>

                <Col span={24}>
                  <FormTextArea name="description" label={t('warehouse.goods.fields.description')} error={errors.description} />
                </Col>

                <ActionsWrapper>
                  <FormSubmit loading={loading} />
                </ActionsWrapper>
              </Row>
            </GoodsFormStyle>
          </FormWrapper>
        </Cards>

        {id && (
          <GoodsTableWrapper>
            <Cards headless title={t('warehouse.goods.price_and_remains')}>
              <GoodInventory id={id} setOpen={setIsOpenModal} />
              <GoodBillingInventory
                open={isOpenModal}
                setOpen={setIsOpenModal}
                billingGoodId={billingGoodId}
                billingGoodCharacteristicId={billingGoodCharacteristicId}
              />
            </Cards>
          </GoodsTableWrapper>
        )}
      </Main>
    </>
  );
};

export default withAuthLayout(GoodsForm);
