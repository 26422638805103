import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Main, CardToolbox } from '../../../styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import withAuthLayout from '@/layouts/withAuthLayout';
import Heading from '@/components/heading/heading';
import Table from '@/widgets/Table/Table';
import { useAuth } from '@/helpers/userContext';

const UserIndex = () => {
  const { t } = useTranslation();
  const { checkPerm } = useAuth();

  const columns = React.useMemo(
    () => [
      {
        title: 'Користувач',
        dataIndex: 'user',
        key: 'user',
        render: (_, row) => (
          <div className="user-info">
            {row.avatar_url && (
              <span className="ant-avatar ant-avatar-lg ant-avatar-circle ant-avatar-icon">
                <img style={{ width: '40px' }} src={row.avatar_url} alt="" />
              </span>
            )}
            <figcaption>
              <Heading className="user-name" as="h6">
                {row.full_name}
              </Heading>
            </figcaption>
          </div>
        ),
      },
      {
        title: t('user.role'),
        dataIndex: 'role_name',
        key: 'role_name',
      },
      {
        title: t('account.profile.fields.email'),
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: t('user.phone'),
        dataIndex: 'phone',
        key: 'phone',
      },
    ],
    [],
  );

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('user.list_page_header')}
          buttons={
            checkPerm('administrating.users.edit') && [
              <Button className="btn-add_new" size="default" type="primary" key="1">
                <Link to="/administrating/users/create">+ {t('user.create_button')}</Link>
              </Button>,
            ]
          }
        />
      </CardToolbox>

      <Main>
        <Table route="administrating/users" resource="users" columns={columns} permissionNamespace="administrating.users" />
      </Main>
    </>
  );
};

export default withAuthLayout(UserIndex);
