import { useTranslation } from 'react-i18next';
import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { CardToolbox, Main } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import withAuthLayout from '@/layouts/withAuthLayout';
import Table from '@/widgets/Table/Table';

const CityIndex = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        title: t('cities.fields.name'),
        dataIndex: 'name',
        key: 'name',
      },
    ],
    [],
  );

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('cities.list_page_header')}
          buttons={[
            <Button className="btn-add_new" size="default" type="primary" key="1">
              <Link to="/cms/cities/create">+ {t('cities.create_button')}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Table route="cms/cities" resource="cities" columns={columns} permissionNamespace="cms.cities" />
      </Main>
    </>
  );
};

export default withAuthLayout(CityIndex);
