import { useTranslation } from 'react-i18next';
import React from 'react';
import { MFIcon } from '@million-flowers/mf-icon-component';
import { CardToolbox, Main } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import Table from '@/widgets/Table/Table';
import withAuthLayout from '@/layouts/withAuthLayout';

const ProductOptionIndex = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        title: t('products.product_option.fields.name'),
        dataIndex: 'label',
        key: 'label',
      },
      {
        title: t('products.product_option.fields.icon'),
        dataIndex: 'icon',
        key: 'icon',
        render: (value) => <MFIcon icon={value} />,
      },
    ],
    [],
  );

  return (
    <>
      <CardToolbox>
        <PageHeader ghost title={t('products.product_option.title')} />
      </CardToolbox>

      <Main>
        <Table
          route="cms/products/product-options"
          resource="product-options"
          columns={columns}
          permissionNamespace="cms.products"
          actionOptions={{ isDeletable: false }}
        />
      </Main>
    </>
  );
};

export default withAuthLayout(ProductOptionIndex);
