import React from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { NavLink, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { last } from 'lodash/array';
import { Main, SegmentFormStyled } from '../../../styled';
import withAuthLayout from '@/layouts/withAuthLayout';
import { PageHeader } from '@/components/page-headers/page-headers';
import LocalityForm from '@/components/City/LocalityForm';
import CityDetailsForm from '@/components/City/CityDetailsForm';
import CitySEO from '@/components/City/CitySEO';

const CityForm = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { pathname } = useLocation();
  const activeKey = last(pathname.split('/'));

  const tabs = [
    {
      label: <NavLink to="">Деталі</NavLink>,
      key: id,
    },
    {
      label: <NavLink to="delivery-zones">Зона доставки</NavLink>,
      key: 'delivery-zones',
    },
    {
      label: <NavLink to="seo">SEO</NavLink>,
      key: 'seo',
    },
  ];

  return (
    <>
      <PageHeader ghost title={t('cities.list_page_header')} />
      <Main>
        <SegmentFormStyled>
          {id ? (
            <>
              <Tabs items={tabs} activeKey={activeKey} />

              <Routes>
                <Route path="" element={<CityDetailsForm />} />
                <Route path="delivery-zones" element={<LocalityForm cityId={id} />} />
                <Route path="seo" element={<CitySEO cityId={id} />} />
              </Routes>
            </>
          ) : (
            <CityDetailsForm />
          )}
        </SegmentFormStyled>
      </Main>
    </>
  );
};

export default withAuthLayout(CityForm);
