const ICON_FLOWER_1 = 'flower-1';
const ICON_FLOWER_2 = 'flower-2';
const ICON_FLOWER_3 = 'flower-3';
const ICON_FLOWER_4 = 'flower-4';
const ICON_HEARTS = 'hearts';
const ICON_PEONY = 'peony';
const ICON_ROSE = 'rose';
const ICON_SALE = 'sale';
const ICON_VAZON = 'vazon';
const ICON_CHAMOMILE = 'chamomile';
const ICON_SIZE = 'size';
const ICON_PALLETE = 'palette';
const ICON_MEDAL = 'medal';
const ICON_BOUQUET = 'bouquet';

export {
  ICON_FLOWER_1,
  ICON_FLOWER_2,
  ICON_FLOWER_3,
  ICON_FLOWER_4,
  ICON_HEARTS,
  ICON_PEONY,
  ICON_ROSE,
  ICON_SALE,
  ICON_VAZON,
  ICON_CHAMOMILE,
  ICON_SIZE,
  ICON_PALLETE,
  ICON_MEDAL,
  ICON_BOUQUET,
};
