import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Col, Form, Row } from 'antd';
import { PageHeader } from '@/components/page-headers/page-headers';
import { Main } from '@/container/styled';
import { Cards } from '@/components/cards/frame/cards-frame';
import { ActionsWrapper, FormWrapper } from '@/container/pages/style';
import FormSubmit from '@/form/FormSubmit';
import useFormValues from '@/hooks/useFormValues';
import withAuthLayout from '@/layouts/withAuthLayout';
import FormInput from '@/form/FormInput';
import IconInput from '@/components/iconInput/iconInput';

const ProductPropertiesForm = () => {
  const { t } = useTranslation();
  const { id, form, loading, handleSubmit, errors } = useFormValues('product-properties');

  return (
    <>
      <PageHeader ghost title={id && t('products.product_properties.title_edit')} />
      <Main>
        <Cards headless>
          <Row justify="center">
            <Col xs={24} md={20} lg={16} xl={12}>
              <FormWrapper>
                <Form style={{ width: '100%' }} form={form} layout="vertical" onFinish={handleSubmit}>
                  <FormInput name="label" label={t('products.product_properties.fields.name')} error={errors.label} required />

                  <FormInput name="slug" label={t('products.product_properties.fields.slag')} error={errors.slug} required />

                  <Form.Item name="use_in_filters" noStyle valuePropName="checked">
                    <Checkbox style={{ marginBottom: 10 }}>{t('products.product_properties.fields.use_in_filters')}</Checkbox>
                  </Form.Item>

                  <Form.Item name="icon" label={t('products.product_properties.fields.icon')} error={errors.icon}>
                    <IconInput />
                  </Form.Item>

                  <ActionsWrapper>
                    <FormSubmit loading={loading} />
                  </ActionsWrapper>
                </Form>
              </FormWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default withAuthLayout(ProductPropertiesForm);
