import { useTranslation } from 'react-i18next';
import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import withAuthLayout from '@/layouts/withAuthLayout';
import { PageHeader } from '@/components/page-headers/page-headers';
import { CardToolbox, Main } from '@/container/styled';
import Table from '@/widgets/Table/Table';

const SliderElementIndex = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        title: t('slider_elements.image'),
        dataIndex: 'image',
        key: 'image',
        render: (value) => <img src={value?.sizes.small} alt="slider" />,
      },
      {
        title: t('slider_elements.title'),
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: t('slider_elements.button_text'),
        dataIndex: 'button_text',
        key: 'button_text',
      },
    ],
    [],
  );

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('slider_elements.list_page_header')}
          buttons={[
            <Button className="btn-add_new" size="default" type="primary" key="1">
              <Link to="/cms/main-page/slider-elements/create">+ {t('slider_elements.create_button')}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Table
          route="cms/main-page/slider-elements"
          resource="main-page/slider-elements"
          columns={columns}
          permissionNamespace="cms.main_page.slider_elements"
        />
      </Main>
    </>
  );
};

export default withAuthLayout(SliderElementIndex);
