import { useState, useEffect, useRef, forwardRef, Fragment } from 'react';
import { Form, Input, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { OptionTagParamStyled } from '@/components/Product/style';
import { ErrorMessage } from '@/components/Order/styled';

const OptionCreateTagItem = forwardRef(({ inputValue, setInputValue, add, isActive, setInputVisible }, ref) => {
  const handleInput = () => {
    if (inputValue && isActive) {
      add(inputValue);
    }
    setInputVisible(false);
    setInputValue('');
  };
  return (
    <Input
      ref={ref}
      type="text"
      size="small"
      className="tag-input"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onBlur={handleInput}
      onPressEnter={handleInput}
    />
  );
});

OptionCreateTagItem.propTypes = {
  inputValue: PropTypes.string,
  setInputValue: PropTypes.func,
  add: PropTypes.func,
  isActive: PropTypes.bool,
  setInputVisible: PropTypes.func,
};

const OptionTagItem = forwardRef(({ value, onChange, onRemove, isActive }, ref) => {
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setEdit(false);
    }
  }, [isActive]);

  const startEdit = () => {
    if (isActive) {
      setEdit(true);
    }
  };

  if (edit) {
    return (
      <Input ref={ref} size="small" className="tag-input" value={value} onChange={onChange} onBlur={() => setEdit(false)} onPressEnter={() => setEdit(false)} />
    );
  }

  return (
    <Tag className="edit-tag" closable={!edit && isActive} onClose={onRemove}>
      <span
        onClick={startEdit}
        className="tag-value"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            startEdit();
          }
        }}
        tabIndex={0}
        role="button"
      >
        {value}
      </span>
    </Tag>
  );
});

OptionTagItem.displayName = 'OptionTagItem';

OptionTagItem.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  isActive: PropTypes.bool,
};

const OptionTagParam = ({ isActive }) => {
  const { t } = useTranslation();
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  return (
    <OptionTagParamStyled>
      <Form.List name={['values']} rules={[{ min: 1, type: 'array', message: t('products.options.min_values'), validateTrigger: 'onBlur' }]}>
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map(({ name: fieldName }) => (
                <Fragment key={fieldName}>
                  <Form.Item name={fieldName} noStyle>
                    <OptionTagItem onRemove={() => remove(fieldName)} isActive={isActive} />
                  </Form.Item>
                </Fragment>
              ))}

              {inputVisible && (
                <OptionCreateTagItem
                  ref={inputRef}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  add={add}
                  isActive={isActive}
                  setInputVisible={setInputVisible}
                />
              )}
              {!inputVisible && isActive && (
                <Tag className="site-tag-plus" onClick={() => setInputVisible(true)}>
                  <PlusOutlined /> {t('products.options.add_param')}
                </Tag>
              )}
              {Boolean(errors.length) && errors.map((err, i) => <ErrorMessage key={i}>{err}</ErrorMessage>)}
            </>
          );
        }}
      </Form.List>
    </OptionTagParamStyled>
  );
};

OptionTagParam.propTypes = {
  isActive: PropTypes.bool,
};

export default OptionTagParam;
