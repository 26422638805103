import { Button, Col, Input, Row } from 'antd';
import SortableTree from 'react-sortable-tree';
import React from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import propTypes from 'prop-types';
import { get, has } from 'lodash';
import { CategoriesTreeStyled, InfoCategories } from '@/container/pages/cms/categories/style';

const CategoriesTree = ({
  treeData,
  setTreeData,
  isAddedNode,
  editNodeKey,
  setEditNodeKey,
  addNode,
  removeNode,
  saveNodeTitle,
  handleAddCategory,
  newCategory,
  setNewCategory,
  errors,
}) => {
  const handleEditTree = (e, rowInfo) => {
    if (e.target.value.trim() === '') {
      removeNode(rowInfo);
    } else {
      saveNodeTitle(rowInfo, e.target.value);
    }
    setEditNodeKey(null);
  };

  const generateNodeProps = (rowInfo) => {
    const isEditing = editNodeKey === rowInfo.path.join('-');
    const hasError = has(errors, `${rowInfo.node.path}.name`);

    return {
      title: (
        <div className="row-wrapper">
          {isEditing || rowInfo.node.isEditing ? (
            <Input
              size="small"
              defaultValue={rowInfo.node.title}
              autoFocus
              onBlur={(e) => handleEditTree(e, rowInfo)}
              onPressEnter={(e) => handleEditTree(e, rowInfo)}
            />
          ) : (
            <span>{rowInfo.node.title}</span>
          )}
        </div>
      ),
      subtitle: get(errors, `${rowInfo.node.path}.name`),
      className: hasError ? 'row-with-error' : '',
      buttons:
        isEditing || rowInfo.node.isEditing
          ? [
              <>
                <Button
                  size="small"
                  onClick={() => {
                    saveNodeTitle(rowInfo, rowInfo.node.title);
                  }}
                  className="add-btn"
                >
                  <SaveOutlined />
                </Button>
              </>,
            ]
          : [
              <>
                <Button size="small" disabled={isAddedNode} onClick={() => addNode(rowInfo)} className="add-btn">
                  <PlusOutlined />
                </Button>
                <Button size="small" disabled={isAddedNode} onClick={() => setEditNodeKey(rowInfo.path.join('-'))} className="edit-btn">
                  <EditOutlined />
                </Button>
                <Button size="small" disabled={isAddedNode} danger onClick={() => removeNode(rowInfo)}>
                  <DeleteOutlined style={{ fontSize: 20 }} />
                </Button>
              </>,
            ],
      expanded: rowInfo.node.isEditing || isEditing,
    };
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={20} sm={16} md={12} lg={8} xl={7}>
        <InfoCategories>
          <p className="info-text">В даному елементі ви створюєте головну категорію:</p>
          <div className="example-add-category" />
        </InfoCategories>
      </Col>
      <Col xs={20} sm={16} md={12} lg={8} xl={7}>
        <InfoCategories>
          <p className="info-text">Ви можете пересувати категорії в будь яке місце натиснувши і утримуючи даний елемент: </p>
          <div className="example-move-btn" />
        </InfoCategories>
      </Col>
      <Col xs={20} sm={16} md={12} lg={8} xl={5}>
        <InfoCategories>
          <p className="info-text">Якщо ви бачите даний елемент, це означає що у категорії є підкатегорія</p>
          <div className="example-expand-btn" />
        </InfoCategories>
      </Col>
      <Col xs={20} sm={16} md={12} lg={8} xl={5}>
        <InfoCategories>
          <p className="info-text">Для того щоб зміни вступили в силу, потрібно натиснути кнопку: </p>
          <div className="example-save-btn" />
        </InfoCategories>
      </Col>

      <Col span={24}>
        <CategoriesTreeStyled>
          <div className="categories-add-container">
            <Input size="small" value={newCategory} onChange={(e) => setNewCategory(e.target.value)} />
            <Button className="add-categories-btn" onClick={handleAddCategory}>
              Додати категорію
            </Button>
          </div>
          <SortableTree
            treeData={treeData}
            onChange={(treeData) => {
              setTreeData(treeData);
            }}
            isVirtualized={false}
            generateNodeProps={generateNodeProps}
          />
        </CategoriesTreeStyled>
      </Col>
    </Row>
  );
};

CategoriesTree.propTypes = {
  treeData: propTypes.array,
  setTreeData: propTypes.func,
  isAddedNode: propTypes.bool,
  editNodeKey: propTypes.string,
  setEditNodeKey: propTypes.func,
  addNode: propTypes.func,
  removeNode: propTypes.func,
  saveNodeTitle: propTypes.func,
  handleAddCategory: propTypes.func,
  newCategory: propTypes.string,
  setNewCategory: propTypes.func,
  errors: propTypes.object,
};

export default CategoriesTree;
