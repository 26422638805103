import React, { useEffect, useId, useState } from 'react';
import { CloseOutlined, FormOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  ClientAsyncSelectStyle,
  OrderCard,
  OrderFormItem,
  ClientPhoneSearchContainer,
  SearchClientInputContainer,
  FontsStyle,
} from '@/components/Order/styled';
import ApiClient from '@/helpers/apiClient/ApiClient';
import UserEditorModal from '@/components/Order/UserEditorModal';
import AsyncSelect from '@/widgets/AsyncSelect';

const ClientPhoneSearch = ({
  name,
  clientId,
  error,
  resource,
  params,
  disabled,
  multiple = false,
  required = false,
  clientData,
  changeClientData,
  onDelete,
  searchIcon = false,
}) => {
  const { t } = useTranslation();
  const [isCreate, setIsCreate] = useState(false);
  const containerID = useId();

  useEffect(() => {
    if (clientId) {
      ApiClient.call('get', `clients/${clientId}`).data(({ data }) => changeClientData(data));
    }
  }, [clientId, changeClientData]);

  const handleOpenCreate = () => {
    changeClientData({});
    setIsCreate(true);
  };

  const renderNotFound = () => (
    <div className="not-found-container">
      <span className="not-found-message">{t('orders.fields.not_found_user')}</span>
      <button className="create-user-btn" onClick={handleOpenCreate} type="button">
        <PlusOutlined />
        <span>{t('orders.fields.create')}</span>
      </button>
    </div>
  );

  return (
    <>
      <UserEditorModal open={isCreate} setOpen={setIsCreate} clientData={clientData} changeClientData={changeClientData} />
      <OrderCard>
        <FontsStyle>
          <ClientPhoneSearchContainer id={containerID}>
            <SearchClientInputContainer>
              <ClientAsyncSelectStyle>
                <OrderFormItem name={name} required={required} validateStatus={error ? 'error' : undefined} help={error}>
                  <AsyncSelect
                    resource={resource}
                    params={params}
                    disabled={disabled}
                    multiple={multiple}
                    searchIcon={searchIcon}
                    getPopupContainer={() => document.getElementById(containerID)}
                    notFound={renderNotFound()}
                    placeholder={t('orders.fields.search_for_number')}
                  />
                </OrderFormItem>
              </ClientAsyncSelectStyle>
              {clientId ? (
                <div className="user-card">
                  <UserOutlined className="icon" />
                  <span className="user-name">{clientData.name}</span>
                  <div className="user-controls">
                    <button type="button" aria-label="Edit user" onClick={() => setIsCreate(true)}>
                      <FormOutlined className="edit" />
                    </button>
                    <button type="button" aria-label="Delete user" onClick={onDelete}>
                      <CloseOutlined className="delete" />
                    </button>
                  </div>
                </div>
              ) : (
                <span className="message">{t('orders.card_message')}</span>
              )}
            </SearchClientInputContainer>
          </ClientPhoneSearchContainer>
        </FontsStyle>
      </OrderCard>
    </>
  );
};

ClientPhoneSearch.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  required: PropTypes.bool,
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clientData: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  resource: PropTypes.string,
  params: PropTypes.object,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  changeClientData: PropTypes.func,
  onDelete: PropTypes.func,
  searchIcon: PropTypes.bool,
};

export default ClientPhoneSearch;
