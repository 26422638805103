import styled from 'styled-components';
import { Row, TimePicker } from 'antd';

const TimeRangeRowStyled = styled(Row)`
  margin: 30px 0;
  background: #f4f5f7;
  border-radius: 4px;
  padding: 5px;
`;

const TimePickerStyled = styled(TimePicker)`
  width: 100%;

  .ant-picker-input {
    padding: 14.5px 5px !important;
    flex-direction: row-reverse;
    gap: 10px;

    & > input {
      line-height: 1;
    }
  }

  .ant-picker-suffix {
    color: #000;
    line-height: 1;
  }
`;

export { TimeRangeRowStyled, TimePickerStyled };
