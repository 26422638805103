import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { TitleCard } from '@/components/Order/styled';

const Title = ({ icon, title }) => {
  const { t } = useTranslation();

  return (
    <TitleCard>
      {icon}
      <span className="title">{t(title)}</span>
    </TitleCard>
  );
};

Title.propTypes = {
  icon: propTypes.node,
  title: propTypes.string,
};

export default Title;
