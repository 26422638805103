import React from 'react';
import { Col, Form, InputNumber, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { get, has } from 'lodash';
import InputOrder from '@/components/Order/InputOrder';
import OrderFormItemSelect from '@/components/Order/OrderFormItemSelect';
import {
  ORDER_SOURCE_TYPE_MESSENGER,
  ORDER_SOURCE_TYPE_OFFLINE,
  ORDER_SOURCE_TYPE_PHONE,
  ORDER_SOURCE_TYPE_SOCIAL_NETWORK,
  ORDER_SOURCE_TYPE_WEBSITE,
} from '@/constants/orders';
import ExtendedGoodSelect from '@/components/Order/ExtendedGoodSelect';
import { OrderCard } from '@/components/Order/styled';

const PersonalInfo = ({ errorData }) => {
  const { t } = useTranslation();

  return (
    <OrderCard>
      <Row gutter={[10, 8]}>
        <Col span={24}>
          <InputOrder prefix={t('orders.fields.manager')} name="manager_id" />
        </Col>
        <Col span={24}>
          <OrderFormItemSelect
            prefix={t('orders.fields.source')}
            name="source"
            error={get(errorData, 'source')}
            options={[
              { value: ORDER_SOURCE_TYPE_WEBSITE, label: t(`orders.source.${ORDER_SOURCE_TYPE_WEBSITE}`) },
              { value: ORDER_SOURCE_TYPE_PHONE, label: t(`orders.source.${ORDER_SOURCE_TYPE_PHONE}`) },
              { value: ORDER_SOURCE_TYPE_MESSENGER, label: t(`orders.source.${ORDER_SOURCE_TYPE_MESSENGER}`) },
              { value: ORDER_SOURCE_TYPE_SOCIAL_NETWORK, label: t(`orders.source.${ORDER_SOURCE_TYPE_SOCIAL_NETWORK}`) },
              { value: ORDER_SOURCE_TYPE_OFFLINE, label: t(`orders.source.${ORDER_SOURCE_TYPE_OFFLINE}`) },
            ]}
          />
        </Col>
        <Col span={24}>
          <ExtendedGoodSelect prefix={t('orders.fields.city')} name="city_id" resource="cities/search" required />
        </Col>

        <Form.Item hidden name="store_id" validateStatus={has(errorData, 'store_id') ? 'error' : ''} help={get(errorData, 'store_id')}>
          <InputNumber />
        </Form.Item>
      </Row>
    </OrderCard>
  );
};

PersonalInfo.propTypes = {
  errorData: propTypes.object,
};

export default PersonalInfo;
