import { Form, Select } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, FormOutlined, SaveOutlined } from '@ant-design/icons';
import { useClickAway } from '@uidotdev/usehooks';
import PropTypes from 'prop-types';
import { CardOptionsItemStyled, OptionFormItemStyled } from '@/components/Product/style';
import OptionTagParam from '@/components/Product/OptionTagParam';
import { Button } from '@/components/buttons/buttons';
import AsyncAutoComplete from '@/components/Product/AsyncAutoComplete';
import { PRODUCT_OPTION_TYPE_BUTTON, PRODUCT_OPTION_TYPE_SELECT } from '@/constants/products';

const OptionsEditForm = ({ value, onChange, onRemove, name, isActive, setActive, updateVariants, isAnyActive, anotherLabels = [] }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const TYPE = [
    { label: t(`products.option_types.${PRODUCT_OPTION_TYPE_BUTTON}`), value: PRODUCT_OPTION_TYPE_BUTTON },
    { label: t(`products.option_types.${PRODUCT_OPTION_TYPE_SELECT}`), value: PRODUCT_OPTION_TYPE_SELECT },
  ];

  useEffect(() => {
    form.setFieldsValue(value);
  }, [value, isActive]);

  const handleSave = async () => {
    try {
      await form.validateFields([['label']]);
      return true;
    } catch (error) {
      return false;
    }
  };

  const ref = useClickAway(async () => {
    if (!isActive) return;

    const isValid = await handleSave();

    if (isValid) {
      form.submit();
    }
  });

  const handleSubmit = (values) => {
    onChange({ ...values, id: value.id });
    updateVariants(name);
    setActive(null);
  };

  return (
    <CardOptionsItemStyled
      ref={ref}
      edit={Number(isActive)}
      actions={
        isActive
          ? [
              <Button key="save" type="primary" size="small" onClick={form.submit}>
                <SaveOutlined />
              </Button>,
              <Button
                key="delete"
                type="danger"
                size="small"
                onClick={() => {
                  setActive(null);
                  onRemove();
                }}
              >
                <DeleteOutlined />
              </Button>,
            ]
          : [
              <Button
                key="edit"
                type="primary"
                size="small"
                onClick={() => {
                  setActive(name);
                }}
                disabled={isAnyActive}
              >
                <FormOutlined />
              </Button>,
              <Button
                key="delete"
                type="danger"
                size="small"
                onClick={() => {
                  onRemove();
                }}
              >
                <DeleteOutlined />
              </Button>,
            ]
      }
    >
      <Form form={form} component={false} onFinish={handleSubmit}>
        <div>
          {isActive ? (
            <div style={{ display: 'flex', gap: 20 }}>
              <OptionFormItemStyled
                name={['label']}
                label={t('products.options.name_input')}
                rules={[
                  { required: true, message: t('products.options.required') },
                  {
                    validator: async (_, inputValue) => {
                      if (inputValue && anotherLabels.includes(inputValue.toLowerCase()) && inputValue.toLowerCase() !== value.label.toLowerCase()) {
                        return Promise.reject(new Error(t('products.options.reject_message')));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <AsyncAutoComplete resource="product-options/search" size="small" />
              </OptionFormItemStyled>

              <OptionFormItemStyled
                name={['type']}
                initialValue={PRODUCT_OPTION_TYPE_BUTTON}
                label={t('products.options.name_select')}
                rules={[{ required: true, message: t('products.options.required') }]}
              >
                <Select size="small" options={TYPE} getPopupContainer={(triggerNode) => triggerNode.parentElement} />
              </OptionFormItemStyled>
            </div>
          ) : (
            <>
              <span style={{ fontWeight: 700 }}>{value.label}</span>
              <span className="types-options" style={{ fontWeight: 700 }}>
                {t('products.options.name_select')}: {TYPE.find((item) => item.value === value.type)?.label || ''}
              </span>
            </>
          )}

          <OptionTagParam isActive={isActive} />
        </div>
      </Form>
    </CardOptionsItemStyled>
  );
};

OptionsEditForm.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  name: PropTypes.number,
  isActive: PropTypes.bool,
  setActive: PropTypes.func,
  updateVariants: PropTypes.func,
  isAnyActive: PropTypes.bool,
  anotherLabels: PropTypes.array,
};

export default OptionsEditForm;
