import { Empty, Select } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { SearchOutlined } from '@ant-design/icons';
import ApiClient from '../helpers/apiClient/ApiClient';
import { SelectWrapperStyle } from '@/container/ui-elements/ui-elements-styled';

const AsyncSelect = ({
  value,
  onChange,
  resource,
  params,
  disabled = false,
  multiple = false,
  client = ApiClient,
  notFound = <Empty />,
  placeholder = 'Оберіть...',
  getPopupContainer,
  searchIcon,
}) => {
  const [options, setOptions] = useState([]);
  const [cachedValue, setCachedValue] = useState(value);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const paramsRef = useRef(params);

  if (!isEqual(paramsRef.current, params)) {
    paramsRef.current = params;
  }

  const onSearch = useCallback(
    (text = '') => {
      setLoading(true);
      client
        .call('get', `${resource}`, { ...paramsRef.current, q: text })
        .data((data) => {
          setError(null);
          setOptions(data);
          setLoading(false);
        })
        .catch(({ data }) => {
          setError(data.message);
          setOptions([]);
        });
    },
    [resource, paramsRef.current],
  );

  useEffect(() => {
    if (!disabled) {
      onSearch();
    }
  }, [disabled, onSearch]);

  useEffect(() => {
    if (multiple && value?.length === 0) {
      return;
    }
    if (value) {
      client.call('get', `${resource}`, { ids: multiple ? value : [value], ...paramsRef.current }).data((data) => {
        if (data.length) {
          setOptions((options) => {
            if (multiple) {
              return [...data, ...options.filter((el) => !value.includes(el.value))];
            }

            return [data[0], ...options.filter((el) => el.value !== data[0].value)];
          });
        }
      });
    } else {
      setCachedValue(undefined);
    }
  }, [value, resource, multiple, paramsRef.current]);

  useEffect(() => {
    setCachedValue(value);
  }, [value]);

  const handelChange = (selected, ext) => {
    const value = selected || null;
    onChange(value, ext);
    setCachedValue(value);
    if (!value || !value?.length) {
      onSearch();
    }
  };

  return (
    <SelectWrapperStyle>
      <Select
        mode={multiple && 'multiple'}
        style={{ width: '100%' }}
        showSearch
        onSearch={onSearch}
        onChange={handelChange}
        filterOption={false}
        value={cachedValue}
        disabled={disabled}
        placeholder={placeholder}
        options={options}
        loading={loading}
        allowClear
        getPopupContainer={getPopupContainer}
        notFoundContent={error ? <span>{error}</span> : notFound}
      />
      {searchIcon && <SearchOutlined className="prefix" />}
    </SelectWrapperStyle>
  );
};

AsyncSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  resource: PropTypes.string.isRequired,
  params: PropTypes.object,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  client: PropTypes.object,
  notFound: PropTypes.node,
  placeholder: PropTypes.string,
  getPopupContainer: PropTypes.func,
  searchIcon: PropTypes.bool,
};

export default AsyncSelect;
