import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import React from 'react';
import withAuthLayout from '@/layouts/withAuthLayout';
import useFormValues from '@/hooks/useFormValues';
import { PageHeader } from '@/components/page-headers/page-headers';
import { Main } from '@/container/styled';
import { Cards } from '@/components/cards/frame/cards-frame';
import { ActionsWrapper, FormWrapper } from '@/container/pages/style';
import FormSubmit from '@/form/FormSubmit';
import FormSelect from '@/form/FormSelect';
import { DEFAULT_PACKING_TYPE_PACK, DEFAULT_PACKING_TYPE_TAPE } from '@/constants/defaultPacking';
import AsyncSelect from '@/widgets/AsyncSelect';

const DefaultPackingForm = () => {
  const { t } = useTranslation();
  const { id, form, loading, handleSubmit, errors } = useFormValues('default-packing', true);

  return (
    <>
      <PageHeader ghost title={id ? t('default_packing.edit_page_header') : t('default_packing.create_page_header')} />
      <Main>
        <Cards headless>
          <Row justify="center">
            <Col span={12}>
              <FormWrapper>
                <Form
                  style={{ width: '100%' }}
                  form={form}
                  layout="vertical"
                  onFinish={handleSubmit}
                  initialValues={{
                    colors: ['На смак флориста'],
                  }}
                >
                  <Form.Item label={t('default_packing.fields.city_id')} name="city_id">
                    <AsyncSelect resource="cities/search" />
                  </Form.Item>
                  <FormSelect
                    name="type"
                    label={t('default_packing.fields.type')}
                    required
                    error={errors.type}
                    options={[
                      { value: DEFAULT_PACKING_TYPE_PACK, label: t(`default_packing.types.${DEFAULT_PACKING_TYPE_PACK}`) },
                      { value: DEFAULT_PACKING_TYPE_TAPE, label: t(`default_packing.types.${DEFAULT_PACKING_TYPE_TAPE}`) },
                    ]}
                  />

                  <Form.Item label={t('default_packing.fields.good')} name="good_id">
                    <AsyncSelect resource="warehouse/goods/search" />
                  </Form.Item>

                  <Form.Item label={t('default_packing.fields.colors')} name="colors">
                    <FormSelect name="colors" options={[]} mode="tags" placeholder={t('default_packing.fields.colors')} required error={errors.colors} />
                  </Form.Item>
                  <ActionsWrapper>
                    <FormSubmit loading={loading} />
                  </ActionsWrapper>
                </Form>
              </FormWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default withAuthLayout(DefaultPackingForm);
