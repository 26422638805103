import React, { useMemo, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Button } from 'antd';
import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DataPickerStyle, TimePickerStyle, DataPickerContainer, FooterDataPicker, TimePickerContainer } from '@/components/Order/styled';
import { HH_mm, YYYY_MM_DD } from '@/constants/momentFormat';

const GlobalStyle = createGlobalStyle`
    .ant-picker-header-super-next-btn,
    .ant-picker-header-super-prev-btn {
        display: none;
    }
    
    .ant-picker-time-panel {
        padding: 0;
    }
    
    .ant-picker-content {
        padding-top: 4px;
    }

    .ant-picker-time-panel .ant-picker-header,
    .ant-picker-time-panel .ant-picker-content {
        background-color: #eaf2fc;
    }

    .ant-picker-time-panel .ant-picker-header-view {
        opacity: 0;
    }

    .ant-picker-time-panel-column {
        font-size: 12px;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 2px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background: #007afc;
            border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #007afc;
        }
    }

    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
        background: #007afc;
        border-radius: 5px;
        font-weight: 400;
        line-height: 1;
        color: #fff;
        height: fit-content;
        width: fit-content;
    }

    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
        padding: 3px;
        margin: 0 0 0 14px;
        width: fit-content;
        border-radius: 5px;
    }

    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
        background-color: transparent;
    }

    .ant-picker-header-next-btn,
    .ant-picker-header-prev-btn {
        color: #007afc !important;
    }

    .ant-picker-header {
        display: flex;
        justify-content: center;
        gap: 13px;
        background: #f8fbff;
    }

    .ant-picker-header-view {
        flex: none;
    }

    .ant-picker-year-btn {
        display: none;
    }

    .ant-picker-date-panel .ant-picker-body {
        padding: 8px 12px 55px;
    }

    .ant-picker-ranges {
        display: none;
    }

    .ant-picker-panel, .ant-picker-footer {
        border: none !important;
    }
`;

const CustomTimeTitle = () => {
  const { t } = useTranslation();
  return (
    <div style={{ fontSize: '10px', lineHeight: 1.2, fontWeight: 700 }}>
      <span style={{ position: 'absolute', right: 75, top: 10 }}>{t('orders.fields.hour')}</span>
      <span style={{ position: 'absolute', right: 25, top: 10 }}>{t('orders.fields.minute')}</span>
    </div>
  );
};

const CustomFooter = ({ timeFrom, timeTo, close, activeInputTime, nextClick }) => {
  const { t } = useTranslation();

  return (
    <FooterDataPicker>
      <div className="time-range-container">
        <input className="time-range-input" value={moment(timeFrom).format(HH_mm)} readOnly onClick={() => nextClick(false)} />
        <span className={classNames('time-range-input-from', { active: !activeInputTime })}>{t('orders.fields.from')}</span>
        <button className="next-time" onClick={() => nextClick(true)} type="button">
          <span className="arrow">&rarr;</span>
        </button>
        <input className="time-range-input" value={moment(timeTo).format(HH_mm)} readOnly onClick={() => nextClick(true)} />
        <span className={classNames('time-range-input-to', { active: activeInputTime })}>{t('orders.fields.to')}</span>
      </div>
      <Button type="primary" onClick={() => close()}>
        {t('orders.fields.save')}
      </Button>
    </FooterDataPicker>
  );
};

CustomFooter.propTypes = {
  timeFrom: PropTypes.object,
  timeTo: PropTypes.object,
  close: PropTypes.func,
  activeInputTime: PropTypes.bool,
  nextClick: PropTypes.func,
};

const DeliveryDateTimePicker = ({ value, onChange }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [nextTime, setNextTime] = useState(false);

  const isToday = moment(value.date, YYYY_MM_DD).isSame(moment(), 'day');

  const dataPicker = (date) => {
    if (!nextTime && date.hour() >= moment(value.time_to, HH_mm).hour()) {
      onChange({
        date: date.format(YYYY_MM_DD),
        time_from: date.format(HH_mm),
        time_to: date.hour() === 23 ? date.set('minutes', 59).format(HH_mm) : date.add(1, 'hour').format(HH_mm),
      });
    } else {
      onChange({
        ...value,
        date: date.format(YYYY_MM_DD),
        [nextTime ? 'time_to' : 'time_from']: moment(date, HH_mm).format(HH_mm),
      });
    }
  };

  const handleOk = () => {
    setOpen(false);
  };

  const disabledTimeTo = (key) => {
    return () => {
      const minTime = moment(value[key], HH_mm);

      return {
        disabledHours: () => {
          const startHour = minTime.hour();
          return Array.from({ length: startHour }, (_, i) => i);
        },
        disabledMinutes: (selectedHour) => {
          if (selectedHour === minTime.hour()) {
            return Array.from({ length: minTime.minute() }, (_, i) => i);
          }
          return [];
        },
      };
    };
  };

  const dateTimeValue = useMemo(() => {
    const date = moment(value.date, YYYY_MM_DD);
    const hour = moment(value[nextTime ? 'time_to' : 'time_from'], HH_mm).hour();
    const minute = moment(value[nextTime ? 'time_to' : 'time_from'], HH_mm).minute();

    return date.set('hour', hour).set('minute', minute);
  }, [value, nextTime]);

  return (
    <div style={{ display: 'flex', gap: 10 }}>
      <DataPickerContainer>
        <GlobalStyle />
        <DataPickerStyle
          open={open}
          onOpenChange={(open) => setOpen(open)}
          value={dateTimeValue}
          format={YYYY_MM_DD}
          showTime={{ format: HH_mm }}
          onSelect={dataPicker}
          disabledDate={(current) => current && current < moment().startOf('day')}
          disabledTime={isToday ? disabledTimeTo('time_from') : nextTime && disabledTimeTo('time_from')}
          renderExtraFooter={() => (
            <CustomFooter
              timeFrom={moment(value.time_from, HH_mm)}
              timeTo={moment(value.time_to, HH_mm)}
              close={handleOk}
              activeInputTime={nextTime}
              nextClick={setNextTime}
            />
          )}
          panelRender={(originPanel) => (
            <div>
              <CustomTimeTitle />
              {originPanel}
            </div>
          )}
        />
        <span className="prefix">{t('orders.fields.date')}</span>
      </DataPickerContainer>

      <TimePickerContainer>
        <TimePickerStyle
          onClick={() => setNextTime(false)}
          format={HH_mm}
          value={moment(value.time_from, HH_mm).set({
            year: moment(value.date, YYYY_MM_DD).year(),
            month: moment(value.date, YYYY_MM_DD).month(),
            date: moment(value.date, YYYY_MM_DD).date(),
          })}
          onSelect={dataPicker}
        />
        <span className="prefix">{t('orders.fields.from')}</span>
      </TimePickerContainer>

      <TimePickerContainer>
        <TimePickerStyle
          onClick={() => {
            setNextTime(true);
          }}
          format={HH_mm}
          value={moment(value.time_to, HH_mm).set({
            year: moment(value.date, YYYY_MM_DD).year(),
            month: moment(value.date, YYYY_MM_DD).month(),
            date: moment(value.date, YYYY_MM_DD).date(),
          })}
          onSelect={dataPicker}
          disabledTime={disabledTimeTo('time_from')}
        />
        <span className="prefix">{t('orders.fields.to')}</span>
      </TimePickerContainer>
    </div>
  );
};

DeliveryDateTimePicker.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default DeliveryDateTimePicker;
