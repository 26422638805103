import PropTypes from 'prop-types';
import { CheckboxStyle } from '@/components/Order/styled';

const CustomerIsRecipientCheckbox = ({ value, onChange, labelCheckbox = '', disabled = false }) => {
  return (
    <CheckboxStyle checked={value} onChange={onChange} disabled={disabled}>
      {labelCheckbox}
    </CheckboxStyle>
  );
};

CustomerIsRecipientCheckbox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  labelCheckbox: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CustomerIsRecipientCheckbox;
