import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import withAuthLayout from '@/layouts/withAuthLayout';
import { CardToolbox, Main } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import Table from '@/widgets/Table/Table';

function HeadCategoriesElementIndex() {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        title: t('head_categories.fields.city_id'),
        dataIndex: ['city', 'name'],
        key: 'city_id',
      },
    ],
    [],
  );

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('head_categories.list_page_header')}
          buttons={[
            <Button className="btn-add_new" size="default" type="primary" key="1">
              <Link to="/cms/head-categories/create">+ {t('head_categories.create_button')}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Table route="cms/head-categories" resource="header-categories" columns={columns} permissionNamespace="cms.header_categories" />
      </Main>
    </>
  );
}

export default withAuthLayout(HeadCategoriesElementIndex);
