import { Button, Modal } from 'antd';
import { useState } from 'react';
import { EyeOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NameColorCommentContainer } from '@/components/Order/styled';

const ProductItemLine = ({ name, color, comment }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Modal title={t('orders.name_color_comment.modal_title')} open={open} footer={false} onCancel={() => setOpen(false)} centered>
        {comment}
      </Modal>
      <NameColorCommentContainer>
        <div className="name-and-color_container">
          <span>- {name}</span>
          {color && <span className="color-container">{color}</span>}
        </div>
        {comment && (
          <div className="comment-container">
            <span>{t('orders.name_color_comment.modal_title')}</span>
            <Button className="show-comment" type="primary" onClick={() => setOpen(true)} icon={<EyeOutlined />}>
              {t('orders.name_color_comment.show_button')}
            </Button>
          </div>
        )}
      </NameColorCommentContainer>
    </>
  );
};

ProductItemLine.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  comment: PropTypes.string,
};

export default ProductItemLine;
