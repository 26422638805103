import { Calendar } from 'antd';
import moment from 'moment';
import { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import CalendarHeader from '@/components/Store/CalendarHeader';
import { CalendarCard } from '@/components/Store/style';
import ApiClient from '@/helpers/apiClient/ApiClient';
import DateCell from '@/components/Store/DateCell';

const CalendarForm = ({ storeId }) => {
  const [selectedValue, setSelectedValue] = useState(moment());
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    ApiClient.call('get', `stores/${storeId}/schedules`).data(({ data }) => {
      setSchedule(data);
    });
  }, [storeId]);

  return (
    <CalendarCard>
      <Calendar
        value={selectedValue}
        onSelect={setSelectedValue}
        onPanelChange={setSelectedValue}
        dateCellRender={(date) => <DateCell date={date} schedule={schedule} selectedValue={selectedValue} setSchedule={setSchedule} storeId={storeId} />}
        headerRender={({ value }) => (
          <CalendarHeader
            value={value}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            schedule={schedule}
            setSchedule={setSchedule}
            storeId={storeId}
          />
        )}
      />
    </CalendarCard>
  );
};

CalendarForm.propTypes = {
  storeId: propTypes.oneOfType([propTypes.string, propTypes.number]),
};

export default CalendarForm;
