import { message, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { NavLink, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { last } from 'lodash/array';
import { Main, SegmentFormStyled } from '../../../../styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import useFormValues from '@/hooks/useFormValues';
import withAuthLayout from '@/layouts/withAuthLayout';
import { Button } from '@/components/buttons/buttons';
import { ReviewsTabStyled } from '@/components/Product/style';
import ProductDetailsForm from '@/components/Product/ProductDetailsForm';
import ApiClient from '@/helpers/apiClient/ApiClient';
import ReviewsPage from '@/components/Product/ReviewsPage';

const modifyData = (values) => {
  const data = {
    ...values,
    variants: values.variants.map((variant) => {
      return {
        ...variant,
        option_values: variant.option_values.filter((ov) => ov.label && ov.value),
      };
    }),
  };

  // return omit(data, 'options');
  return data;
};

const ProductForm = () => {
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(6);
  const [reviewsData, setReviewsData] = useState([]);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = +searchParams.get('page') || 1;

  const { pathname } = useLocation();
  const activeKey = last(pathname.split('/'));

  const { form, id, handleSubmit, loading, errors } = useFormValues('products', false, modifyData, true);

  useEffect(() => {
    if (id) {
      ApiClient.call('get', `/products/${id}/reviews`, { page, per_page: perPage }).data((data) => {
        setReviewsData(data.data);
        setTotal(data.total);
      });
    }
  }, [id, page, perPage]);

  const saveAndCreateNext = () => {
    handleSubmit(form.getFieldsValue(), () => {
      message.success(t('success_message'));
      setTimeout(() => {
        form.resetFields();
        navigate(`../create`, { replace: true });
      }, 500);
    });
  };

  const tabs = [
    {
      label: <NavLink to="">{t('products.tabs.good_form')}</NavLink>,
      key: id,
    },
    {
      label: (
        <NavLink to="reviews">
          <ReviewsTabStyled>
            <span>{t('products.tabs.reviews')}</span>
            <span className="qty">{total}</span>
          </ReviewsTabStyled>
        </NavLink>
      ),
      key: 'reviews',
    },
  ];

  return (
    <>
      <PageHeader
        ghost
        title={t(id ? 'products.edit_page_header' : 'products.create_page_header')}
        buttons={[
          !id && (
            <Button size="large" type="info" onClick={saveAndCreateNext} raised key={1} loading={loading}>
              {t('saveAndCreateNext')}
            </Button>
          ),
          <Button size="large" type="success" onClick={form.submit} raised key={2} loading={loading}>
            {t('save')}
          </Button>,
          <Button
            key={3}
            className="btn-cancel"
            size="large"
            onClick={() => {
              navigate(-1);
            }}
          >
            {t('cancel')}
          </Button>,
        ]}
      />
      <Main>
        {id ? (
          <>
            <Tabs items={tabs} activeKey={activeKey} />
            <SegmentFormStyled>
              <Routes>
                <Route path="" element={<ProductDetailsForm form={form} handleSubmit={handleSubmit} id={id} errors={errors} />} />
                <Route
                  path="reviews"
                  element={
                    <ReviewsPage
                      reviewsData={reviewsData}
                      setReviewsData={setReviewsData}
                      total={total}
                      page={page}
                      perPage={perPage}
                      setPerPage={setPerPage}
                    />
                  }
                />
              </Routes>
            </SegmentFormStyled>
          </>
        ) : (
          <SegmentFormStyled>
            <ProductDetailsForm form={form} handleSubmit={handleSubmit} id={id} errors={errors} />
          </SegmentFormStyled>
        )}
      </Main>
    </>
  );
};

export default withAuthLayout(ProductForm);
