import { Checkbox, Col, Form, InputNumber, Row, Tooltip, Typography } from 'antd';
import moment from 'moment/moment';
import FontAwesome from 'react-fontawesome';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { ActionsWrapper, FormWrapper } from '@/container/pages/style';
import FormSelectAsync from '@/form/FormSelectAsync';
import FormInput from '@/form/FormInput';
import { TimePickerStyled, TimeRangeRowStyled } from '@/container/pages/cms/stores/style';
import FormSubmit from '@/form/FormSubmit';
import { Cards } from '@/components/cards/frame/cards-frame';
import CoordsSelector from '@/components/Store/CoordsSelector';
import { HH_mm } from '@/constants/momentFormat';

const { Title } = Typography;

const StoreDetailsForm = ({ form, handleFinish, id, errors, cityId, loading }) => {
  const { t } = useTranslation();

  return (
    <Cards headless>
      <Row justify="center">
        <Col xl={16} md={24} xs={24}>
          <FormWrapper>
            <Form
              style={{ width: '100%' }}
              form={form}
              layout="vertical"
              onFinish={handleFinish}
              initialValues={{
                id: '',
                is_primary: false,
                is_self_pick_available: false,
                is_shown_in_contacts: false,
                start_time: '09:00',
                end_time: '21:00',
              }}
            >
              <FormSelectAsync
                resource="cities/search"
                name="city_id"
                label={t('stores.fields.city')}
                error={errors.city_id}
                required
                disabled={Boolean(id) && Boolean(cityId)}
              />
              <FormSelectAsync
                required
                name="billing_storage_id"
                label={t('stores.fields.billing_storage_id')}
                resource={`billing/get-storages-by-local-city/${cityId}`}
                disabled={Boolean(id) || !cityId}
              />
              <FormInput name="name" label={t('stores.fields.name')} error={errors.name} required placeholder="Соборна" />
              <FormInput name="address" label={t('stores.fields.address')} error={errors.address} required />

              <TimeRangeRowStyled gutter={10}>
                <Col span={24}>
                  <Title level={5}>{t('stores.title_recipient')}</Title>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="start_time"
                    getValueProps={(value) => {
                      return { value: moment(value, HH_mm) };
                    }}
                    getValueFromEvent={(e) => {
                      return moment(e).format(HH_mm);
                    }}
                    rules={[
                      {
                        required: true,
                        message: t('stores.message_from'),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const orderTimeTo = getFieldValue('end_time');
                          if (!value || !orderTimeTo || value < orderTimeTo) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t('stores.message_from.validation_message_from')));
                        },
                      }),
                    ]}
                  >
                    <TimePickerStyled format={HH_mm} suffixIcon={t('stores.fields.time_from')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="end_time"
                    getValueProps={(value) => {
                      return { value: moment(value, HH_mm) };
                    }}
                    getValueFromEvent={(e) => {
                      return moment(e).format(HH_mm);
                    }}
                    rules={[
                      {
                        required: true,
                        message: t('stores.message_from.message_to'),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const orderTimeFrom = getFieldValue('start_time');
                          if (!value || !orderTimeFrom || value > orderTimeFrom) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t('stores.message_from.validation_message_to')));
                        },
                      }),
                    ]}
                  >
                    <TimePickerStyled format={HH_mm} suffixIcon={t('stores.fields.time_to')} />
                  </Form.Item>
                </Col>
              </TimeRangeRowStyled>

              <Row gutter={20}>
                <Col span={14}>
                  <FormInput name="p_service_counter_id" label={t('stores.fields.counter_p_service')} error={errors.counter_p_service} />
                </Col>
                <Col span={10}>
                  <Form.Item
                    name="order_delay_days"
                    label={t('stores.fields.order_delay_days')}
                    validateStatus={errors.order_delay_days ? 'error' : false}
                    help={errors.order_delay_days}
                  >
                    <InputNumber style={{ width: '100%' }} min={1} />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col md={8} sm={24}>
                  <Form.Item name="is_primary" valuePropName="checked">
                    <Checkbox>
                      {t('stores.fields.is_primary')}{' '}
                      <Tooltip title={t('stores.primary_explain')}>
                        <FontAwesome name="question-circle-o" className="super-crazy-colors" />
                      </Tooltip>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col md={8} sm={24}>
                  <Form.Item name="is_self_pick_available" valuePropName="checked">
                    <Checkbox>{t('stores.fields.is_self_pick_available')}</Checkbox>
                  </Form.Item>
                </Col>
                <Col md={8} sm={24}>
                  <Form.Item name="is_shown_in_contacts" valuePropName="checked">
                    <Checkbox>{t('stores.fields.is_shown_in_contacts')}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label={t('stores.fields.coords')} name="coords" validateStatus={errors.coords ? 'error' : false} help={errors.coords}>
                <CoordsSelector />
              </Form.Item>
              <ActionsWrapper>
                <FormSubmit loading={loading} />
              </ActionsWrapper>
            </Form>
          </FormWrapper>
        </Col>
      </Row>
    </Cards>
  );
};

StoreDetailsForm.propTypes = {
  form: propTypes.object,
  handleFinish: propTypes.func,
  id: propTypes.oneOfType([propTypes.string, propTypes.number]),
  errors: propTypes.object,
  cityId: propTypes.oneOfType([propTypes.string, propTypes.number]),
  loading: propTypes.bool,
};

export default StoreDetailsForm;
