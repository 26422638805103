import { Button, Col, Divider, Form, message, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { get, has } from 'lodash';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import InputOrder from '@/components/Order/InputOrder';
import { OrderCard, OrderFormItem } from '@/components/Order/styled';
import ApiClient from '@/helpers/apiClient/ApiClient';
import OrderMoneyInput from '@/components/Order/OrderMoneyInput';

const DeliveryDescription = ({ cityId, sum, error }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const form = useFormInstance();
  const deliveryCity = Form.useWatch(['delivery_address', 'city'], form);
  const deliveryStreet = Form.useWatch(['delivery_address', 'street'], form);
  const deliveryStreetNumber = Form.useWatch(['delivery_address', 'street_number'], form);

  const calcDeliveryPrice = useCallback(async () => {
    if (!cityId) return;

    setLoading(true);
    try {
      const response = await ApiClient.call(
        'get',
        `https://nominatim.openstreetmap.org/search?format=json&accept-language=ua-UA&limit=1&addressdetails=1&countrycode=UA&country=UA`,
        {
          city: deliveryCity,
          street: `${deliveryStreet} ${deliveryStreetNumber}`,
        },
      );

      const coords = response.data[0];
      if (!coords) {
        throw new Error(t('orders.fields.not_found_message'));
      }

      const { lat, lon } = coords;
      await ApiClient.call('get', `orders/delivery-price/${cityId}`, {
        lat,
        lon,
        items_sum: sum,
      }).data((price) => form.setFieldValue('delivery_price', price));
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [cityId, deliveryCity, deliveryStreet, deliveryStreetNumber, sum]);

  return (
    <OrderCard>
      <Row gutter={[10, 8]}>
        <Col span={12}>
          <InputOrder prefix={t('orders.fields.town')} name={['delivery_address', 'city']} error={get(error, 'delivery_address.city')} />
        </Col>
        <Col span={12}>
          <InputOrder prefix={t('orders.fields.street')} name={['delivery_address', 'street']} error={get(error, 'delivery_address.street')} />
        </Col>
        <Col span={12}>
          <InputOrder
            prefix={t('orders.fields.street_number')}
            name={['delivery_address', 'street_number']}
            error={get(error, 'delivery_address.street_number')}
          />
        </Col>

        <Col span={12}>
          <OrderFormItem
            name="delivery_price"
            validateStatus={has(error, 'delivery_price') ? 'error' : false}
            help={get(error, 'delivery_price')}
            rules={[{ required: true }]}
          >
            <OrderMoneyInput prefix={t('orders.fields.delivery_cost')} />
          </OrderFormItem>
        </Col>

        <Col span={24}>
          <Button
            type="primary"
            className="calc-delivery-price"
            onClick={calcDeliveryPrice}
            loading={loading}
            disabled={!cityId || !deliveryCity || !deliveryStreet || !deliveryStreetNumber || sum === 0}
          >
            {t('orders.calc_delivery_price')}
          </Button>
        </Col>

        <Col span={24}>
          <Divider className="line" />
        </Col>
        <Col span={8}>
          <InputOrder prefix={t('orders.fields.entrance')} name={['delivery_address', 'entrance']} error={get(error, 'delivery_address.entrance')} />
        </Col>
        <Col span={8}>
          <InputOrder prefix={t('orders.fields.floor')} name={['delivery_address', 'floor']} error={get(error, 'delivery_address.floor')} />
        </Col>
        <Col span={8}>
          <InputOrder
            prefix={t('orders.fields.apartment_number')}
            name={['delivery_address', 'apartment_number']}
            error={get(error, 'delivery_address.apartment_number')}
          />
        </Col>
        <Col span={24}>
          <InputOrder name="delivery_details" prefix={t('orders.fields.courier_comment')} />
        </Col>
      </Row>
    </OrderCard>
  );
};

DeliveryDescription.propTypes = {
  cityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sum: PropTypes.number,
  error: PropTypes.object,
};

export default DeliveryDescription;
