import {
  PRODUCT_ITEM_TYPE_BOX,
  PRODUCT_ITEM_TYPE_FLOWER,
  PRODUCT_ITEM_TYPE_PACK,
  PRODUCT_ITEM_TYPE_TAPE,
  PRODUCT_OPTION_TYPE_BUTTON,
  PRODUCT_OPTION_TYPE_SELECT,
  PRODUCT_TYPE_BOUQUET,
  PRODUCT_TYPE_BUNDLE,
  PRODUCT_TYPE_FLOWER,
  PRODUCT_TYPE_PLANT,
  PRODUCT_TYPE_POSTCARD,
  PRODUCT_TYPE_SINGLE,
  PRODUCT_TYPE_SWEET,
  PRODUCT_TYPE_TOY,
} from '@/constants/products';
import {
  ORDER_DELIVERY_TYPE_COURIER,
  ORDER_DELIVERY_TYPE_SELF,
  ORDER_GENDER_TYPE_FEMALE,
  ORDER_GENDER_TYPE_MALE,
  ORDER_PAYMENT_STATUS_FAILED,
  ORDER_PAYMENT_STATUS_PAID,
  ORDER_PAYMENT_STATUS_PENDING,
  ORDER_PAYMENT_STATUS_REVERSED,
  ORDER_PAYMENT_TYPE_ACQUIRING,
  ORDER_PAYMENT_TYPE_CASH,
  ORDER_SOCIAL_NETWORK_FACEBOOK,
  ORDER_SOCIAL_NETWORK_INSTAGRAM,
  ORDER_SOCIAL_NETWORK_TELEGRAM,
  ORDER_SOCIAL_NETWORK_VIBER,
  ORDER_SOURCE_TYPE_MESSENGER,
  ORDER_SOURCE_TYPE_OFFLINE,
  ORDER_SOURCE_TYPE_PHONE,
  ORDER_SOURCE_TYPE_SOCIAL_NETWORK,
  ORDER_SOURCE_TYPE_WEBSITE,
  ORDER_STAGE_BOUQUET_READY,
  ORDER_STAGE_COMPLETED,
  ORDER_STAGE_DELIVERING,
  ORDER_STAGE_IN_QUE_FOR_PACKING,
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_DONE,
  ORDER_STATUS_NEED_CONFIRMATION,
  ORDER_STATUS_PROCESSING,
} from '@/constants/orders';
import { DEFAULT_PACKING_TYPE_PACK, DEFAULT_PACKING_TYPE_TAPE } from '@/constants/defaultPacking';
import { APPLICATION_STATUS_PROCESSED, APPLICATION_STATUS_WAIT_REPLY } from '@/constants/siteApplications';
import {
  INVOICE_PAYMENT_STATUS_FAILED,
  INVOICE_PAYMENT_STATUS_REVERSED,
  INVOICE_PAYMENT_STATUS_SUCCESS,
  INVOICE_PAYMENT_STATUS_WAIT_SECURE,
  INVOICE_PAYMENT_STATUS_WAITING,
} from '@/constants/invoices';
import {
  PAYMENT_STATUS_EXPIRED,
  PAYMENT_STATUS_FAILED,
  PAYMENT_STATUS_REVERSED,
  PAYMENT_STATUS_SUCCESS,
  PAYMENT_STATUS_WAIT_SECURE,
  PAYMENT_STATUS_WAITING,
} from '@/constants/payments';
import { PROMO_CODE_MODE_PERCENT, PROMO_CODE_MODE_VALUE } from '@/constants/promoCodes';
import { PERIOD_DAY, PERIOD_MONTH, PERIOD_QUARTER, PERIOD_WEEK } from '@/components/statistic/conversion/hooks/useStatisticChartsData';
import { BOX, FLOWER, PAPER, PLANT, POSTCARD, SWEET, TAPE, TOY } from '@/constants/goods';
import { MAX_FILES } from '@/hooks/useFileUpload';

export const TRANSLATIONS_UA = {
  login_welcome: 'Авторизація',
  password: 'Пароль',
  sign_in: 'Увійти',
  signout: 'Вийти',
  close: 'Закрити',
  save: 'Зберегти',
  saveAndCreateNext: 'Зберегти та створити ще',
  add: 'Додати',
  confirm: 'Підтвердити',
  undo: 'Скасувати',
  cancel: 'Відмінити',
  remove: 'Видалити',
  apply: 'Застосувати',
  clear: 'Очистити',
  proved: 'Підтверджено',
  undone: 'Скасовано',
  reset: 'Скинути',
  processing: 'Обробка',
  profile: 'Профіль',
  profile_edit: 'Редагування профілю',
  personal_info: 'Особиста інформація',
  authorization: 'Авторизація',
  notifications: 'Сповіщення',
  profile_photo: 'Фото профілю',
  yes: 'Так',
  no: 'Ні',
  search: 'Пошук...',
  uah_short: 'грн',
  validation_error: 'Помилка валідації',
  success_message: 'Успішно!',
  error_page: {
    return_home: 'Повернутися на головну',
    not_found_text: 'Сторінку не знайдено',
    unauthorized_text: 'У вас немає прав для перегляду цієї сторінки',
    server_error_text: 'Виникла помилка серверу',
  },
  modal: {
    accept_delete_message: 'Ви дійсно бажаєте видалити цей елемент?',
  },
  table: {
    total: 'Всього - {{count}}',
    clear_filters: 'Очистити фільтри',
    refresh: 'Оновити',
    sort_tooltips: {
      asc: 'Сортувати за зростанням',
      desc: 'Сортувати за спаданням',
      cancel: 'Відмінити сортування',
    },
  },
  menu: {
    home: 'Головна',
    administrating: 'Адміністрування',
    accounting: 'Фін. облік',
    cms: 'Управління сайтом',
    crm: 'CRM',
    main_page: 'Головна сторінка',
    statistics: 'Cтатистика',
  },
  account: {
    my_account: 'Профіль',
    edit_profile: 'Редагування профілю',
    change_password: 'Зміна паролю',
    notifications: 'Сповіщення',
    profile: {
      personal_info: 'Мої дані',
      profile_settings: 'Налаштування персональної інформації',
      fields: {
        first_name: 'Ім`я',
        last_name: 'Призвіще',
        phone_number: 'Телефон',
        email: 'Email',
      },
    },
    password: {
      heading: 'Зміна паролю',
      min_password_length: 'Мінімув 8 символів',
      fields: {
        old_password: 'Старий пароль',
        password: 'Новий пароль',
        password_confirmation: 'Повторіть пароль',
      },
    },
    notification: {
      heading: 'Сповіщення',
      description: 'Оберіть які сповіщення ви бажаєте отримувати',
      channels: {
        sms: 'СМС',
        mail: 'Email',
      },
    },
  },
  fops: {
    menu_name: 'ФОПи',
    list_page_header: 'ФОПи',
    create_button: 'Додати ФОПа',
    alert_description: 'Після редагування статусу "Активний" відбудеться автоматичне закриття зміни каси в Checkbox.',
    alert_message: 'Увага',
    fields: {
      name: 'Назва',
      iban: 'IBAN',
      liq_pay_public_key: 'Публічний ключ LiqPay',
      liq_pay_private_key: 'Приватний ключ LiqPay',
      checkbox_pin_code: 'Пінкод касира Checkbox',
      checkbox_license_key: 'Ключ ліцензії каси Checkbox',
      checkbox_access_token: 'Ключ авторизації касира Checkbox',
      available_in_bot: 'Активний у боті',
      is_used_on_site: 'Використовувати для сайту',
      type: 'Тип',
      tax_codes: 'Коди податкових ставок (через кому)',
      bot: 'Бот',
      active: 'Активний',
      disabled: 'Неактивний',
    },
    type_fop: 'ФОП',
    type_tov: 'ТОВ',
    checkbox_errors: {
      title: 'Помилки чекбокса',
      columns: {
        id: '#',
        message: 'Помилка',
        operation: 'Дія',
        amount: 'Сума',
        source: 'Джерело',
        updated_at: 'Дата та час',
      },
    },
  },
  invoices: {
    menu_name: 'Інвойси (Бот)',
    list_page_header: 'Інвойси',
    checkbox_errors_page_title: 'Помилки Checkbox',
    payment_statuses: {
      [INVOICE_PAYMENT_STATUS_WAITING]: 'Очікує оплату',
      [INVOICE_PAYMENT_STATUS_SUCCESS]: 'Оплачено',
      [INVOICE_PAYMENT_STATUS_FAILED]: 'Неуспішна оплата',
      [INVOICE_PAYMENT_STATUS_REVERSED]: 'Поверення',
      [INVOICE_PAYMENT_STATUS_WAIT_SECURE]: 'Очікується підтвердження банку',
      [null]: 'Невідомо',
    },
    columns: {
      id: 'ID',
      order: 'Замовлення',
      order_number: 'Номер замовлення',
      status: 'Статус',
      amount: 'Сума',
      payment_status: 'Статус платежу',
      created_at: 'Час створення',
      expired_date: 'Дійсний до',
      paid_at: 'Час оплати',
      checkbox_error_logs_count: 'Помилки фіскалізації',
      not_errors: 'Немає помилок',
    },
    fiscalization: 'Фіскалізувати',
    review_check: 'Переглянути чек',
  },
  payments: {
    menu_name: 'Платежі',
    list_page_header: 'Платежі',
    checkbox_errors_page_title: 'Помилки Checkbox',
    statuses: {
      [PAYMENT_STATUS_WAITING]: 'Очікує оплату',
      [PAYMENT_STATUS_SUCCESS]: 'Оплачено',
      [PAYMENT_STATUS_FAILED]: 'Неуспішна оплата',
      [PAYMENT_STATUS_EXPIRED]: 'Час оплати минув',
      [PAYMENT_STATUS_REVERSED]: 'Поверення',
      [PAYMENT_STATUS_WAIT_SECURE]: 'Очікується підтвердження банку',
      [null]: 'Невідомо',
    },
  },
  cities: {
    menu_name: 'Міста',
    list_page_header: 'Міста',
    create_button: 'Додати місто',
    details: 'Деталі',
    save: 'Зберегти',
    fields: {
      billing_city_id: 'Місто в системі 1С',
      name: 'Назва',
      slug: 'Слаг',
      instagram_link: 'Інстаграм (назва)',
      phone: 'Контактний номер телефону',
      email: 'Контактний email',
      is_default: 'Місто за замовчуванням',
      free_delivery_zone_coords: 'Зона безкоштовної доставки',
      free_delivery_from_price: 'Ціна безкоштовної доставки (від грн)',
      orders_for_delivery_limit: 'Ліміт замовлень на доставку за проміжок',
      delivery_points: 'Населені пункти',
      streets_loaded: 'Вулиці',
      delete_message: 'Ви хочете видалити Населений пункт',
      on_ok: 'Так',
      add_button: 'Додати',
      created: 'Створення',
      edited: 'Редагування',
      city: 'Місто',
      district: 'Район',
      region: 'Область',
    },
    default_city_explain: 'При встановленні цього параметру для користувача сайту за замовчуванням завантажується каталог відповідний данному місту',
    seo: {
      title: 'SEO',
      fields: {
        page: 'Сторінка',
        title: '<title>',
        description: '<meta:description>',
        og_title: 'og:title',
        og_description: 'og:description',
        heading: '<h1>',
        heading_2: '<h2>',
        content: 'Контент',
        action: 'Дія',
        delete_popconfirm: 'Ви впевнені ?',
        ok_popconfirm: 'Так',
      },
      save_button: 'Зберегти',
      add_button: 'Додати SEO',
    },
  },
  delivery_points: {
    region: 'Район',
    city: 'Місто',
  },
  delivery_zones: {
    modal_title: 'Зони доставки',
    fields: {
      color: 'Колір',
      delivery_price: 'Вартість доставки',
      free_delivery_from_sum: 'Безкоштовна доставки від',
      info_text: 'Текст',
    },
  },

  stores: {
    menu_name: 'Торгові точки',
    list_page_header: 'Торгові точки',
    create_button: 'Додати торгову точку',
    title_recipient: 'Часи прийому замовлень',
    message_from: 'Будь ласка, виберіть час "Від".',
    validation_message_from: 'Час "Від" має бути менше за "До".',
    message_to: 'Будь ласка, виберіть час "До".',
    validation_message_to: 'Час "До" має бути більше за "Від".',
    default_value: 'За замовчуванням',
    weekend: 'Вихідний',
    fields: {
      billing_storage_id: 'Склад в системі 1С',
      name: 'Назва',
      address: 'Адреса',
      city: 'Місто',
      counter_p_service: 'Лічильник P-Service',
      coords: 'Локація (оберіть на карті)',
      is_primary: 'Головний',
      is_self_pick_available: 'Доступний самовивіз',
      is_shown_in_contacts: "Відображати на сторінці 'Контакти'",
      time_from: 'Від',
      time_to: 'До',
      order_delay_days: 'Термін прийняття замовлень (дні)',
    },
    tabs: {
      store_form_title: 'Форма торгової точки',
      store_calendar_title: 'Календар графіку роботи',
    },
    edit_schedule: {
      success: 'Успішно збережено!',
      error: 'Помилка серверу',
      modal_title: 'Проміжки прийому замовлень',
      fields: {
        start: 'Від',
        end: 'До',
        specify_start: 'Вкажіть "Від"',
        specify_end: 'Вкажіть "До"',
        orders_limit: 'Ліміт замовлень',
        add: 'Додати час',
      },
      actions: {
        save: 'Зберегти',
        cancel: 'Скасувати',
        worker: 'Робочий',
        weekend: 'Вихідний',
      },
    },
    calendar_header: {
      select_date: 'Ви обрали дату:',
    },
    weekend_modal: {
      question_message: 'Ви бажаєте зробити цей день',
      worker: 'Робочим',
      weekend: 'Вихідним',
    },
    primary_explain: 'При активації параметру, за замовчуванням відвідувачу сайту буде відображатись асортимент данної торгової точки.',
  },
  user: {
    menu_name: 'Персонал',
    list_page_header: 'Користувачі CRM',
    create_button: 'Створити користувача',
    count: '{{count}} користувачів',
    first_name: "Ім'я",
    last_name: 'Призвіще',
    email: 'Електронна адреса',
    phone_number: 'Телефон',
    phone: 'Телефон',
    role: 'Роль',
    full_access: 'Повний доступ',
    choose_role: 'Оберіть роль',
    password: 'Пароль',
    password_confirmation: 'Підтвердження паролю',
  },
  role: {
    menu_name: 'Ролі',
    list_page_header: 'Ролі CRM',
    create_button: 'Створити роль',
    name: 'Назва ролі',
    form: {
      main_block: 'Основне',
      permissions_block: 'Доступ',
      model_fields: 'Перегляд полів в структурах',
    },
  },
  categories: {
    menu_name: 'Категорії',
    list_page_header_table: 'Список категорії',
    list_page_header_grid: 'Категорії',
    create_page_header: 'Створення категорії',
    edit_page_header: 'Редагування категорії',
    create_button: 'Додати',
    fields: {
      name: 'Назва',
      slug: 'Слаг',
      is_active: 'Активна',
      is_for_new_products: 'Додавати нові товари в цю категорію',
      is_show_on_main_page: 'Виводити категорію на головній сторінці',
      image: 'Зображення',
      count_product: 'Кількість товарів',
    },
    disabled_show_on_main_page_explain:
      'Максимально доступна кількість категорій на головній сторінці - 2. Відредагуйте це поле в іншій категорії щоб виводити цю категорію на головній сторінці.',
    message_success: 'Успішно!',
    card_sort_title: 'Сортування категорій',
    card_web_title: 'WEB версія',
    card_mobile_title: 'Mobile версія',
    card_alert_text: 'В данному меню відображені лише активні категорії',
    card_button_text: 'Зберегти',
    card_hint_text: 'Перетягніть елемент для зміни порядку відображення та натисніть кнопку `Зберегти` для застосування змін.',
    seo: {
      city_name: 'Місто',
      title: '<title>',
      description: '<meta:description>',
      og_title: 'og:title',
      og_description: 'og:description',
      heading: '<h1>',
      heading_2: '<h2>',
      content: 'Контент',
      save_button: 'Зберегти',
      delete_button: 'Видалити',
      delete_popconfirm: 'Ви впевнені ?',
      ok_popconfirm: 'Так',
      add_button: 'Додати SEO поле',
      actions: 'Дія',
      modal_title: 'Редагування SEO',
    },
  },
  head_categories: {
    menu_name: 'Категорії шапки',
    list_page_header: 'Категорії шапки',
    create_button: 'Додати категорію',
    create_page_header: 'Нова категорія',
    edit_page_header: 'Редагування категорії',
    categories_title: 'Категорії',
    fields: {
      manner: 'Порядок',
      title: 'Назва',
      placement: 'Розміщення',
      icons: 'Іконки',
      city_id: 'Місто',
      operation: 'Дія',
    },
  },
  recommended_products: {
    menu_name: 'Рекомендовані товари',
    list_page_header: 'Рекомендовані товари',
    create_page_header: 'Новий товар',
    create_button: 'Додати список товарів',
    delete_products_list: 'Видалити список товарів',
    edit_page_header: 'Редагування товару',
    extra_data: 'Додаткові дані',
    min_sum: 'Мінімальна сума',
    product_list: 'Список товарів',
    success: 'Підтвердити',
    close: 'Закрити',
    delete_product: 'Видалити товар',
    add_product: 'Додати товар',
    edit: 'Редагувати',
    delete: 'Видалити',
    add_list_products: 'Додати список товарів',
    fields: {
      variant_id: 'Товар',
      product_size_id: 'Розмір',
      city_id: 'Місто',
      add_info: 'Опис',
      name: 'Назва',
      operation: 'Дія',
      key: 'Ключ',
      value: 'Значення',
    },
  },
  products: {
    nav_title: 'Позиції',
    menu_name: 'Товари',
    list_page_header_table: 'Список товарів',
    list_page_header_grid: 'Сітка товарів',
    create_page_header: 'Створення товару',
    edit_page_header: 'Редагування товару',
    create_button: 'Додати товар',
    sizes: 'Розміри',
    details: 'Деталі',
    search: 'Пошук по назві',
    sku: 'SKU (сайт)',
    last_order: 'Останнє оновлення',
    variant_images: {
      title_add: 'Додати зображення',
      title_edit: 'Редагування зображення',
      save: 'Зберегти',
      uploaded: 'Завантажити',
      uploading: 'Завантаження',
      dragger_message: 'Натисніть або перетягніть файл в цю зону, щоб завантажити.',
      title_list_file: 'Список завантажених зображень',
      added: 'Додано',
      review_images: 'Перегляд',
      not_view_image_message: 'Оберіть зображення для перегляду',
      upload_error_message: 'Не вдалося завантажити деякі файли.',
    },
    tabs: {
      good_form: 'Форма товару',
      reviews: 'Відгуки',
    },
    reviews_page: {
      personal_info: 'Персональна інформація',
      author_name: 'Ім`я автора',
      author_email: 'Ел.адреса',
      author_phone: 'Номер телефону',
      comment: 'Коментар',
      not_comment_message: 'Автор не залишив коментар',
      total: 'Всього',
      confirmed: 'Підтверджено',
      confirm: 'Підтвердити',
      confirm_message: 'Ви впевнені ?',
    },
    upload_warning_message: 'Помилка при завантажені файлів',
    limited_file_message: `Можна завантажити максимум ${MAX_FILES} фото.`,
    billing_good_id: 'Товар в системі 1С',
    billing_good_characteristic_id: 'Характеристика товару',
    synchronize_with_1c: 'Синхронізувати з 1С',
    transform: 'Перетворити',
    price: 'Ціни',
    name: 'Назва',
    short_name: 'Коротка назва',
    type: 'Тип',
    moderated: 'Відмодеровано',
    categories: 'Категорії',
    description: 'Опис',
    url: 'Url',
    image: 'Зображення',
    nomenclature: 'Номенклатура',
    price_city_1c: 'Ціни по містам (данні з 1С)',
    all: 'Усі',
    sizes_fields: {
      title: 'Назва',
      slug: 'Слаг',
      create_to_basis_name: 'Створити на основі ім`я',
      height: 'Висота',
      main: 'Головний',
    },
    items: {
      drawer_title: 'Склад букету',
      name: 'Найменування',
      type: 'Тип',
      type_flower: 'Квіти',
      type_pack: 'Упакування',
      color: 'Колір',
      count: 'Кількість',
      height: 'Висота (см)',
    },
    columns: {
      item_name: 'Позиція',
      single_price: 'Ціна за шт',
      quantity: 'Кількість шт',
      remains: 'Залишки',
      presence: 'Наявність',
      total: 'Тотал',
      storage_name: 'Назва Торгової Точки',
      in_stock: 'В наявності',
      price: 'Ціна',
      city_name: 'Місто',
    },
    product_reviews: {
      nav_title: 'Відгуки',
      title: 'Сітка відгуків',
      product_link: 'Посилання на товар',
      fields: {
        moderated: 'Опубліковано',
        created_at: 'Дата',
        rate: 'Рейтинг',
        product: 'Товар',
        comment: 'Коментар',
      },
    },
    options: {
      title: 'Опції',
      name_input: 'Назва',
      name_select: 'Тип',
      required: 'Це поле обов`язкове',
      min_values: 'Додайте хоча б одне значення',
      reject_message: 'Така опція вже існує',
      add_option: 'Додати опцію',
      add_param: 'Додати параметр',
      not_found_options_message: 'Ви не створили опції',
    },
    product_option: {
      nav_title: 'Опції',
      title: 'Список опцій товару',
      title_edit: 'Редагування опції',
      fields: {
        name: 'Назва',
        type: 'Тип',
        icon: 'Іконка',
      },
    },
    product_properties: {
      nav_title: 'Характеристики',
      title: 'Список характеристик товару',
      title_edit: 'Редагування характеристики',
      fields: {
        name: 'Назва',
        slag: 'Слаг',
        icon: 'Іконка',
        use_in_filters: 'Використовувати в фільтрах',
      },
    },
    variants: {
      title: 'Варіанти товару',
      add: 'Додати',
      generate: 'Згенерувати',
      table_title: {
        type: 'Тип',
        name: 'Назва',
        good_id: 'Номенклатура',
        slug: 'Слаг',
        options: 'Опції',
        properties: 'Характеристики',
      },
      option_modal: {
        title: 'Опції товару',
        save: 'Зберегти',
        edit: 'Редагувати',
        add: '+ Додати',
        add_options: 'Додати опцію',
        fields: {
          name: 'Назва',
          value: 'Значення',
          color: 'Колір',
        },
      },
      properties_modal: {
        title: 'Список характеристик',
        save: 'Зберегти',
        edit: 'Редагувати',
        add: '+ Додати',
        add_value: 'Додати',
        add_properties: 'Додати характеристику',
        info_message: 'Вибране значення характеристики буде відображатися на картці товару у каталозі.',
        fields: {
          name: 'Назва',
          value: 'Значення',
          color: 'Колір',
        },
      },

      edit_inventory: 'Редагувати склад',
      edit_inventory_message: 'Натисніть на кнопку "Редагувати склад" щоб побачити помилку',
      popconfirm_title: 'Ви впевнені?',
      ok_text: 'Видалити',
      drawer: {
        title: 'Редагування товару',
        save_button: 'Зберегти',
        delete_button: 'Видалити',
        add_button: 'Додати товар',
        fields: {
          name: 'Назва',
          qty: 'Кількість',
          color: 'Колір',
        },
      },
    },
    product_item_types: {
      [PRODUCT_ITEM_TYPE_FLOWER]: 'Квітка',
      [PRODUCT_ITEM_TYPE_PACK]: 'Упакування',
      [PRODUCT_ITEM_TYPE_TAPE]: 'Стрічка',
      [PRODUCT_ITEM_TYPE_BOX]: 'Коробка',
    },
    types: {
      [PRODUCT_TYPE_FLOWER]: 'Квіти поштучно',
      [PRODUCT_TYPE_BOUQUET]: 'Букет',
      [PRODUCT_TYPE_SWEET]: 'Солодощі',
      [PRODUCT_TYPE_TOY]: 'Іграшки',
      [PRODUCT_TYPE_PLANT]: 'Вазони',
      [PRODUCT_TYPE_POSTCARD]: 'Листівка',
      [PRODUCT_TYPE_BUNDLE]: 'Компонований',
      [PRODUCT_TYPE_SINGLE]: 'Одинарний',
    },
    option_types: {
      [PRODUCT_OPTION_TYPE_SELECT]: 'Селект',
      [PRODUCT_OPTION_TYPE_BUTTON]: 'Кнопка',
    },
  },
  slider_elements: {
    menu_name: 'Слайдер',
    list_page_header: 'Список елементів',
    create_page_header: 'Новий елемент',
    edit_page_header: 'Редагування елементу',
    create_button: 'Додати елемент',
    image: 'Зображення',
    title: 'Заголовок',
    button_text: 'Текст кнопки',
    fields: {
      title: 'Назва',
      mobile_src: 'Зображення Mobile',
      desktop_src: 'Зображення Desktop',
      link: 'Посилання',
      slug: 'Слаг',
      create_to_basis_name: 'Створити на основі ім`я',
      height: 'Висота',
      main: 'Головний',
      cities: 'Міста',
    },
  },
  main_page_carousel: {
    menu_name: 'Карусель товарів',
    list_page_header: 'Список елементів',
    create_page_header: 'Новий елемент',
    edit_page_header: 'Редагування елементу',
    create_button: 'Додати елемент',
    fields: {
      city_id: 'Місто',
      title: 'Назва',
      is_active: 'Активний',
      category_id: 'Категорія',
      items: 'Товари',
      variant_id: 'Товар',
      operation: 'Дія',
      order: 'Порядок',
      old_price: 'Стара ціна',
    },
  },
  faqs: {
    menu_name: 'FAQ',
    list_page_header: 'Список додаткових FAQ',
    create_page_header: 'Нове місто',
    edit_page_header: 'Редагування інформації',
    create_button: 'Додати місто',
    fields: {
      city_id: 'Місто',
      title: 'Назва',
      placement: 'Розміщення',
      html: 'Контент',
    },
  },
  orders: {
    menu_name: 'Замовлення',
    list_page_header: 'Список замовлень',
    list_page_create_page: 'Створення замовлення',
    list_page_edit_page: 'Редагування замовлення',
    cancel_order: 'Скасування замовлення',
    cause: 'Причина',
    confirmation: 'Підтвердження',
    client: 'Клієнт',
    client_name: 'Ім`я',
    client_surname: 'Призвіще',
    client_telephone: 'Телефон',
    product: 'Товари',
    delivery: 'Доставка',
    delivery_type: 'Тип доставки',
    delivery_date: 'Дата доставки',
    delivery_time_range: 'Проміжок достаки',
    city_id: 'Місто',
    store: 'Торгова точка',
    client_phone: 'Телефон клієнта',
    created_at: 'Час створення',
    delivery_details: 'Коментар для кур`єра',
    client_comment: 'Коментар клієнта',
    type: 'Тип',
    stage: 'Етап',
    received_at: 'Час отримання',
    sum: 'Сума',
    promo_code: 'Промокод',
    status_order: 'Статус замовлення',
    number: 'Номер',
    search_to_number: 'Пошук за номером',
    date_delivery_or_pickup: 'Дата доставки/самовивозу',
    period_delivery_or_pickup: 'Проміжок доставки/самовивозу',
    address: 'Адреса',
    recipient: 'Отримувач',
    comment: 'Коментар',
    to_courier: 'Для кур`єра',
    payment: 'Оплата',
    payment_type: 'Тип оплати',
    status: 'Статус',
    discount: 'Знижка',
    total_sum: 'Загальна сума',
    all: 'Усі',
    need_call: 'Телефонувати клієнту',
    need_call_modal_title: 'Зателефонувати клієнту',
    need_call_modal_name: 'Ім`я клієнта',
    need_call_modal_phone: 'Номер клієнта',
    need_call_button: 'Підтвердити замовлення',
    card_message: 'Почніть вводити номер щоб отримати список клієнтів',
    calc_delivery_price: 'Розрахувати доставку',
    apply_promocode: 'Застосувати промокод',
    create_button: 'Створити замовлення',
    fops: 'ФОПи',
    tracking: 'Трекінг',
    name_color_comment: {
      modal_title: 'Коментар',
      show_button: 'Дивитись',
    },
    cards_title: {
      personal_info: 'Персональна інформація',
      store_and_availability: 'Магазини і наявність',
      customer: 'Замовник',
      delivery_info: 'Інформація про доставку',
      link: 'Посилання',
    },
    user_modal: {
      title_add_user: 'Додати користувача',
      title_edit_user: 'Редагувати користувача',
      first_name: 'Ім`я',
      last_name: 'Призвіще',
      gender: 'Стать',
      social_media: 'Соц мережі',
      nickname: 'Нікнейм',
      save: 'Зберегти',
      cancel: 'Скасувати',
      update_data: 'Данні оновлено!',
      create_user: 'Користувача створено!',
      server_error_message: 'Виникла помилка серверу.',
    },
    order_modal: {
      store: 'Магазин',
      good: 'Товар',
      name: 'Назва',
      count: 'Кількість',
      position_list: 'Список позицій',
      add_position: 'Додати позицію',
      price: 'Ціна',
      color: 'Колір',
      comment: 'Коментар',
      save: 'Зберегти',
      cancel: 'Скасувати',
      reject_message: 'Будь ласка, додайте хоча б одну позицію.',
    },
    product_type: 'Тип товару',
    products_types: {
      [FLOWER]: 'Квіти поштучно',
      [TOY]: 'Іграшки',
      [POSTCARD]: 'Листівка',
      [PLANT]: 'Вазони',
      [SWEET]: 'Солодощі',
      [PAPER]: 'Упакування',
      [TAPE]: 'Стрічка',
      [BOX]: 'Коробка',
    },
    fields: {
      manager: 'Менеджер/адмін',
      city: 'Місто',
      source: 'Джерело',
      store: 'Магазин',
      payment_type: 'Спосіб оплати',
      manual_discount: 'Ручна знижка',
      promo_code: 'Промокод',
      payment_status: 'Статус оплати',
      cashback_sum: 'Списати кешбек',
      comment: 'Коментар',
      name: 'Ім`я',
      delivery_type: 'Тип доставки',
      gender: 'Стать',
      is_recipient_self: 'Замовник і є отримувачем',
      street: 'Вулиця',
      street_number: '№ Будинку',
      entrance: "Під'їзд",
      floor: 'Поверх',
      items_sum: 'Сума товарів',
      delivery_cost: 'Вартість доставки',
      apartment_number: '№ Квартири',
      date: 'Дата',
      to: 'До',
      from: 'Від',
      save: 'Зберегти',
      hour: 'Год',
      minute: 'Хв',
      product: 'Товар',
      add_product: 'Додати товар',
      your_order: 'Ваше замовлення',
      additional_information: 'Додаткова інформація',
      discount: 'Знижки',
      sum_order: 'Сума замовлення',
      search_for_number: 'Пошук по номеру',
      not_found_user: 'Користувача не знайдено',
      create: 'Створити',
      phone_number: 'Номер телефону',
      courier_comment: "Коментар для кур'єра",
      town: 'Населений пункт',
      not_found_message: 'Не вдалось знайти вулицю',
      select_city_message: 'Оберіть місто',
      validation_message: 'Помилка валідації',
      copy_message: 'Скопійовано',
      uah: 'грн',
    },
    promo_code_error: 'Оберіть товар щоб застосувати промокод',
    social_type: {
      [ORDER_SOCIAL_NETWORK_TELEGRAM]: 'Telegram',
      [ORDER_SOCIAL_NETWORK_VIBER]: 'Viber',
      [ORDER_SOCIAL_NETWORK_FACEBOOK]: 'Facebook',
      [ORDER_SOCIAL_NETWORK_INSTAGRAM]: 'Instagram',
    },
    source: {
      [ORDER_SOURCE_TYPE_WEBSITE]: 'Сайт',
      [ORDER_SOURCE_TYPE_PHONE]: 'Телефонія',
      [ORDER_SOURCE_TYPE_MESSENGER]: 'Месенджери',
      [ORDER_SOURCE_TYPE_SOCIAL_NETWORK]: 'Соц. мережі',
      [ORDER_SOURCE_TYPE_OFFLINE]: 'Офлайн',
    },
    statuses: {
      [ORDER_STATUS_NEED_CONFIRMATION]: 'Потрібно підтвердження',
      [ORDER_STATUS_PROCESSING]: 'Виконується',
      [ORDER_STATUS_DONE]: 'Виконано',
      [ORDER_STATUS_CANCELLED]: 'Скасовано',
    },
    stages: {
      [ORDER_STAGE_IN_QUE_FOR_PACKING]: 'В черзі на пакування',
      [ORDER_STAGE_BOUQUET_READY]: 'Букет готовий',
      [ORDER_STAGE_DELIVERING]: 'Доставляється',
      [ORDER_STAGE_COMPLETED]: 'Виконане',
    },
    gender: {
      [ORDER_GENDER_TYPE_MALE]: 'Чоловік',
      [ORDER_GENDER_TYPE_FEMALE]: 'Жінка',
    },
    need_call_statuses: {
      call: 'Так',
      not_call: 'Ні',
    },
    payment_statuses: {
      [ORDER_PAYMENT_STATUS_PENDING]: 'Очікує оплати',
      [ORDER_PAYMENT_STATUS_PAID]: 'Оплачено',
      [ORDER_PAYMENT_STATUS_FAILED]: 'Неуспішно',
      [ORDER_PAYMENT_STATUS_REVERSED]: 'Повернення',
    },
    payment_types: {
      [ORDER_PAYMENT_TYPE_CASH]: 'Готівка',
      [ORDER_PAYMENT_TYPE_ACQUIRING]: 'Еквайрінг',
    },
    delivery_types: {
      [ORDER_DELIVERY_TYPE_COURIER]: "Доставка кур'єром",
      [ORDER_DELIVERY_TYPE_SELF]: 'Самовивіз',
    },
  },
  default_packing: {
    menu_name: 'Пакування',
    list_page_header: 'Список пакувань за замовчуванням',
    create_page_header: 'Створити',
    edit_page_header: 'Редагування пакування',
    create_button: 'Додати',
    price_and_stock: 'Ціни та залишки',
    fields: {
      city_id: 'Місто',
      type: 'Тип',
      billing_good_id: 'Товар в системі 1С',
      billing_good_characteristic_id: 'Характеристика товару в системі 1С',
      colors: 'Кольори',
      good: 'Товар зі складу',
    },
    types: {
      [DEFAULT_PACKING_TYPE_PACK]: 'Упакування',
      [DEFAULT_PACKING_TYPE_TAPE]: 'Стрічка',
    },
  },
  site_applications: {
    menu_name: 'Заявки з сайту',
    list_page_header: 'Заявки з сайту',
    order_processed: 'Заявка опрацьована',
    order_close: 'Закрити заявку',
    statuses: {
      [APPLICATION_STATUS_WAIT_REPLY]: 'Очікує відповідь',
      [APPLICATION_STATUS_PROCESSED]: 'Опрацьована',
    },
    columns: {
      id: 'id',
      city_id: 'Місто',
      status: 'Статус',
      created_at: 'Дата',
      name: 'Ім`я',
      phone: 'Телефон',
      email: 'Email',
    },
  },
  binotel_auto_reply_settings: {
    name: 'Назва',
    menu_name: 'Binotel (Авто. смс)',
    list_page_header: 'Binotel (Автовідповідь по смс)',
    create_button: 'Додати налаштування',
    fields: {
      name: 'Назва налаштування',
      pbx_number: 'Телефон лінії Binotel',
      reply_message: 'Текст SMS для відповіді',
      sender: 'Відправник',
      is_active: 'Активна',
    },
  },
  promo_codes: {
    menu_name: 'Промокоди',
    list_page_header: 'Промокоди',
    create_button: 'Додати промокод',
    fields: {
      name: 'Назва промокоду',
      code: 'Код',
      mode: 'Тип знижки',
      value: 'Значення знижки',
      min_order_sum: 'Мінімальна ціна замовлення',
      usage_limit: 'Доступна кількість використань',
      valid_from: 'Діє з',
      valid_to: 'Діє по',
      name_promo_code: 'Назва',
      orders_count: 'Кількість використань',
    },
    modes: {
      [PROMO_CODE_MODE_VALUE]: 'Грн',
      [PROMO_CODE_MODE_PERCENT]: 'Відсоток',
    },
  },
  statistics: {
    list_page_header: 'Статистика',
    revenue_online: 'Виторг online',
    revenue_offline: 'Виторг offline',
    revenue_total: 'Загальний виторг',
    revenue_all: 'Виторг всього',
    stores_without_name: 'Магазини без міста',
    statistics_on_cities: 'Статистика по містах',
    periods: {
      [PERIOD_DAY]: 'День',
      [PERIOD_WEEK]: 'Тиждень',
      [PERIOD_MONTH]: 'Місяць',
      [PERIOD_QUARTER]: 'Квартал',
    },
    card_title: {
      conversion_and_analytics: 'Конверсія + Аналітика',
      site_statistic: 'Статистика сайту',
      revenue: 'Виторг',
    },
  },
  conversion: {
    list_page_header: 'Конверсія',
  },
  revenue: {
    list_page_header: 'Виторг',
  },
  site_statistic: {
    list_page_header: 'Статистика сайту',
    card_chunk_text: 'За минулий період',
    update_button: 'Оновити',
    periods: {
      today: 'Сьогодні',
      week: 'Тиждень',
      month: 'Місяць',
      year: 'Рік',
    },
    card_title: {
      orders: 'Замовлення',
      revenue: 'Виручка',
      avg: 'Сер. вар. замовлення',
      clients: 'Клієнти',
    },
    calendar_labels: {
      today: 'Сьогодні',
      yesterday: 'Вчора',
      this_week: 'Цей тиждень',
      last_week: 'Минулий тиждень',
      this_month: 'Цей місяць',
      last_month: 'Минулий місяць',
      days_to_day: 'Дні до сьогодні',
    },
    calendar_buttons: {
      apply: 'Застосувати',
      cancel: 'Відмінити',
      clear: 'Скинути',
    },
    top_products_title: {
      main_title: 'Лідери продажів',
      name: 'Назва',
      price: 'Ціна',
      total_quantity_sold: 'Продано',
      revenue: 'Виручка',
    },
    top_cities_title: {
      main_title: 'Продажі за місцезнаходженням',
      city: 'Місто',
      count: 'Замовлення',
      revenue: 'Виручка',
    },
    cities_revenue: {
      main_title: 'Виручка по містам',
    },
  },
  sms_balance: 'Баланс SmsClub',

  short_links: {
    menu_name: 'Короткі посилання',
    list_page_header: 'Посилання',
    create_button: 'Додати посилання',
    edit_page_header: 'Редагування короткого посилання',
    create_page_header: 'Створення короткого посилання',
    all: 'Усі',
    my: 'Мої',
    search_placeholder: 'Пошук за назвою',
    fields: {
      name_short_links: 'Назва',
      link: 'Посилання',
      generate_link: 'Згенероване посилання',
      generate_link_placeholder: 'Після збереження згенерується посилання',
      copy_link: 'Скопіювати посилання',
      copied: 'Скопійовано!',
    },
  },
  create_order: {
    product_modal: {
      name: 'Назва',
      count: 'Кількість',
    },
  },
  warehouse: {
    menu_name: 'Склад',
    tax_codes: {
      header_title: 'Коди податків',
      header_title_create: 'Створення коду',
      header_title_edit: 'Редагування коду',
      create_button: 'Створити код податку',
      tax_name: 'Назва',
      tax_code: 'Код',
    },
    oversell_limit_groups: {
      nav_title: 'Ліміти залишків',
      header_title: 'Групи лімітів по залишкам',
      header_title_create: 'Створення ліміту',
      header_title_edit: 'Редагування ліміту',
      create_button: 'Створити ліміт залишків',
      oversell_name_limit: 'Назва',
      oversell_limit: 'Ліміт',
    },
    goods: {
      nav_title: 'Товари',
      header_title: 'Список товарів',
      header_title_create: 'Створення товару',
      header_title_edit: 'Редагування товару',
      create_button: 'Створити товар',
      search_to_name: 'Пошук за назвою',
      name_goods: 'Назва',
      add: 'Додати',
      custom_data: 'Додаткові дані',
      attributes: 'Атрибути',
      price_and_remains: 'Ціни та залишки',
      data_1C: 'Дані 1С',
      fields: {
        type: 'Тип товару',
        name: 'Назва товару',
        sku: 'SKU (сайт)',
        tax_code: 'Податковий код',
        tax_name: 'Податкове ім`я',
        description: 'Опис',
        billing_good_id: 'Товар в системі 1С',
        oversell_limit_groups: 'Ліміт залишків',
        color: 'Колір',
        height: 'Висота',
        max_comment_symbols: 'Кількість символів',
        billing_characteristic_id: 'Характеристика товару',
        label: 'Ключ',
        value: 'Значення',
      },
    },
    types: {
      [FLOWER]: 'Квіти поштучно',
      [SWEET]: 'Солодощі',
      [TOY]: 'Іграшки',
      [PLANT]: 'Вазони',
      [POSTCARD]: 'Листівка',
      [PAPER]: 'Упакування',
      [TAPE]: 'Стрічка',
      [BOX]: 'Коробка',
    },
  },
};
