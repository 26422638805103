import { useTranslation } from 'react-i18next';
import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { CardToolbox, Main } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import withAuthLayout from '@/layouts/withAuthLayout';
import Table from '@/widgets/Table/Table';

const BinotelAutoReplyIndex = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        title: t('binotel_auto_reply_settings.name'),
        dataIndex: 'name',
        key: 'name',
      },
    ],
    [],
  );

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('binotel_auto_reply_settings.list_page_header')}
          buttons={[
            <Button className="btn-add_new" size="default" type="primary" key="1">
              <Link to="/crm/binotel-auto-reply-settings/create">+ {t('binotel_auto_reply_settings.create_button')}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Table
          route="crm/binotel-auto-reply-settings"
          resource="binotel-auto-reply-settings"
          columns={columns}
          permissionNamespace="crm.binotel_auto_reply_settings"
        />
      </Main>
    </>
  );
};

export default withAuthLayout(BinotelAutoReplyIndex);
