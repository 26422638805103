import { MapContainer, Marker, useMapEvents } from 'react-leaflet';
import PropTypes from 'prop-types';
import { Icon } from 'leaflet/src/layer';
import { useEffect, useState } from 'react';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { defaultCenter } from '@/constants/leaflet';
import config from '@/config.json';
import LogoSpinner from '@/widgets/LogoSpinner';
import { MapLoaderStyled } from '@/components/City/styled';

const CoordsSelector = ({ value, onChange }) => {
  const [isMapReady, setIsMapReady] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setIsMapReady(true);
  }, []);

  if (!isMapReady) return null;

  const Position = () => {
    useMapEvents({
      click(e) {
        onChange([e.latlng.lat, e.latlng.lng]);
      },
    });

    return value ? <Marker icon={new Icon({ iconUrl: '/location.svg', iconSize: [32, 32] })} position={value} interactive={false} /> : null;
  };

  return (
    <MapContainer center={value || defaultCenter} zoom={15} scrollWheelZoom style={{ width: '100%', height: 400 }}>
      <MapLoaderStyled isLoading={loading}>
        <LogoSpinner />
      </MapLoaderStyled>
      <ReactLeafletGoogleLayer
        apiKey={config.googleMapsApiKey}
        type="roadmap"
        eventHandlers={{
          load: () => setLoading(false),
        }}
      />
      <Position />
    </MapContainer>
  );
};

CoordsSelector.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default CoordsSelector;
