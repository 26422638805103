import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import { get } from 'lodash';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import ApiClient from '@/helpers/apiClient/ApiClient';
import FormInput from '@/form/FormInput';
import FormSelect from '@/form/FormSelect';
import FormTextArea from '@/form/FormTextArea';

const CityFormSeo = ({ id, onSuccess, cityId }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      ApiClient.call('get', `cities/${cityId}/seo/${id}`)
        .data(({ data }) => {
          form.setFieldsValue(data);
        })
        .validation((info, error) => setError(error));
    }
  }, [id]);

  const handleSaveOrEdit = (values) => {
    const method = id ? 'patch' : 'post';
    const url = `cities/${cityId}/seo/${id || ''}`;

    ApiClient.call(method, url, {}, values)
      .data(({ data }) => {
        form.setFieldsValue(data);
        onSuccess(data);
      })
      .validation((info, errors) => setError(errors));
  };

  return (
    <Form layout="vertical" initialValues={{ page: '', title: '', heading: '', heading_2: '', content: '' }} form={form} onFinish={handleSaveOrEdit}>
      <FormSelect
        label={t('cities.seo.fields.page')}
        name="page"
        error={get(error, 'page')}
        options={[
          { value: 'catalog', label: 'Каталог' },
          { value: 'home', label: 'Головна' },
        ]}
      />

      <FormInput name="title" label={t('cities.seo.fields.title')} error={get(error, 'title')} />

      <FormTextArea name="description" label={t('cities.seo.fields.description')} error={get(error, 'description')} rows={2} />

      <FormInput name="og_title" label={t('cities.seo.fields.og_title')} error={get(error, 'og_title')} />
      <FormInput name="og_description" label={t('cities.seo.fields.og_description')} error={get(error, 'og_description')} />

      <FormInput name="heading" label={t('cities.seo.fields.heading')} error={get(error, 'heading')} />
      <FormInput name="heading_2" label={t('cities.seo.fields.heading_2')} error={get(error, 'heading_2')} />

      <Form.Item name="content" label={t('cities.seo.fields.content')} validateStatus={get(error, 'content') ? 'error' : null} help={get(error, 'content')}>
        <ReactQuill theme="snow" />
      </Form.Item>

      <Button htmlType="submit">{t('cities.seo.save_button')}</Button>
    </Form>
  );
};

CityFormSeo.propTypes = {
  id: PropTypes.any,
  onSuccess: PropTypes.func.isRequired,
  cityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CityFormSeo;
