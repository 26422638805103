import { useEffect, useState } from 'react';
import { Form, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { forEach, set } from 'lodash';
import ApiClient from '@/helpers/apiClient/ApiClient';
import { useError } from '@/helpers/errorContext';
import NotFoundError from '@/helpers/NotFoundError';
import UnauthorizedError from '@/helpers/UnauthorizedError';
import ServerError from '@/helpers/ServerError';
import createFormData from '@/helpers/createFormData';

const errorFormat = (errors) => {
  const formattedErrors = {};

  forEach(errors, (messages, key) => {
    const keyParts = key.split('.');

    set(formattedErrors, keyParts, messages);
  });

  return formattedErrors;
};

export default function useFormValues(resource, multipart = false, modifyData = null, formatErrors = false) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({});
  const { setError: setGlobalError } = useError();
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState({});

  useEffect(() => {
    if (id) {
      ApiClient.call('get', `${resource}/${id}`)
        .data(({ data }) => {
          setModel(data);
          form.resetFields();
          form.setFieldsValue(data);
        })
        .code(404, () => {
          setGlobalError(new NotFoundError('404'));
        })
        .code(403, () => {
          setGlobalError(new UnauthorizedError('403'));
        })
        .code(500, () => {
          setGlobalError(new ServerError('500'));
        });
    }
  }, [id, resource]);

  const handleSubmit = (values, afterSubmit = null) => {
    setErrors({});
    setLoading(true);
    const method = id && !multipart ? 'patch' : 'post';
    const url = `${resource}/${id || ''}`;
    const modifiedData = modifyData ? modifyData(values) : values;

    const data = multipart ? createFormData({ ...modifiedData, id }) : { ...modifiedData, id };

    if (id && multipart) {
      data.append('_method', 'patch');
    }

    ApiClient.call(method, url, {}, data)
      // eslint-disable-next-line no-shadow
      .data(({ data }) => {
        if (afterSubmit) {
          afterSubmit();
        } else if (!id) {
          message.success('Успішно!');
          setTimeout(() => {
            navigate(`../${data.id}`, { replace: true });
          }, 1000);
        } else {
          form.setFieldsValue(data);
          message.success('Данні оновленно!');
        }
      })
      .code(400, ({ data }) => {
        message.error(data.message);
      })
      .code(500, () => {
        message.error('Виникла помилка серверу.');
      })
      .validation((info, err) => {
        message.error('Помилка валідації. Перевірте введені дані.');
        setErrors(formatErrors ? errorFormat(err) : err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    if (id) {
      ApiClient.call('delete', `${resource}/${id}`).then(() => navigate(-1));
    }
  };

  const clearErrors = () => {
    setErrors({});
  };

  return {
    id,
    model,
    form,
    errors,
    loading,
    handleDelete,
    handleSubmit,
    clearErrors,
  };
}
