import { Form, Upload } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const FormFile = ({ label, name, accept, required, multiple = false, disabled, error }) => {
  return (
    <Form.Item
      getValueFromEvent={(e) => {
        return e.file;
      }}
      required={required}
      validateStatus={error ? 'error' : false}
      help={error}
      name={name}
      label={label}
    >
      <Upload
        disabled={disabled}
        beforeUpload={() => {
          return false;
        }}
        accept={accept}
        maxCount={!multiple && 1}
        className="sDash_upload-basic"
      >
        <span className="sDash_upload-text">Оберіть файл...</span>
        <Link to="#" className="sDash_upload-browse">
          Завантажити
        </Link>
      </Upload>
    </Form.Item>
  );
};

FormFile.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  accept: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.any,
};

export default FormFile;
