import { PlusOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@/components/buttons/buttons';
import { CardOptionsStyled } from '@/components/Product/style';
import OptionsEditForm from '@/components/Product/OptionsEditForm';

const OptionsListItem = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(null);
  const form = useFormInstance();
  const options = Form.useWatch('options', form);

  const updateVariants = (name) => {
    const variants = form.getFieldValue('variants') || [];

    form.setFieldValue(
      'variants',
      variants.map((variant) => {
        const id = form.getFieldValue(['options', name, 'id']);
        const label = form.getFieldValue(['options', name, 'label']);

        return {
          ...variant,
          option_values:
            variant.option_values.findIndex((el) => id === el.option_id) === -1
              ? [...variant.option_values, { option_id: id, label, value: '' }]
              : variant.option_values,
        };
      }),
    );
  };

  return (
    <Form.List name="options">
      {(fields, { add, remove }) => (
        <CardOptionsStyled
          title={t('products.options.title')}
          actions={[
            <Button
              key="1"
              className="add-btn"
              icon={<PlusOutlined />}
              onClick={() => {
                const newOptionIndex = fields.length;
                add({ id: uuidv4(), label: '', values: [] });
                setActiveIndex(newOptionIndex);
              }}
              disabled={activeIndex}
            >
              {t('products.options.add_option')}
            </Button>,
          ]}
        >
          {options && options.length === 0 ? (
            <div className="not-found-options">
              <span>{t('products.options.not_found_options_message')}</span>
            </div>
          ) : (
            fields.map(({ name }) => {
              return (
                <Form.Item name={name} noStyle key={name}>
                  <OptionsEditForm
                    name={name}
                    isActive={activeIndex === name}
                    setActive={setActiveIndex}
                    isAnyActive={activeIndex !== null}
                    anotherLabels={form.getFieldValue('options').map(({ label }) => label.toLowerCase())}
                    updateVariants={updateVariants}
                    onRemove={() => {
                      const variants = form.getFieldValue('variants');

                      form.setFieldValue(
                        'variants',
                        variants.map((variant) => {
                          const id = form.getFieldValue(['options', name, 'id']);
                          return {
                            ...variant,
                            option_values: variant.option_values.filter((el) => id !== el.option_id),
                          };
                        }),
                      );
                      remove(name);
                    }}
                  />
                </Form.Item>
              );
            })
          )}
        </CardOptionsStyled>
      )}
    </Form.List>
  );
};

export default OptionsListItem;
