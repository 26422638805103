import { useEffect, useState } from 'react';
import { Button, Form, TimePicker, message, Modal } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { has, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import ApiClient from '@/helpers/apiClient/ApiClient';
import { D_MMMM_YYYY, HH_mm, YYYY_MM_DD } from '@/constants/momentFormat';
import { EditScheduleFormStyled, ModalHead, TimePickerFormItem } from '@/components/Store/style';
import FormInput from '@/form/FormInput';
import WeekendModal from '@/components/Store/WeekendModal';

const EditScheduleForm = ({ selectedValue, schedule, setSchedule, storeId, open, setOpen }) => {
  const { t } = useTranslation();
  const [isOpenWeekend, setIsOpenWeekend] = useState(false);
  const [form] = Form.useForm();
  const [errors, setErrors] = useState(null);

  const isWeekend = Form.useWatch('is_weekend', form);

  useEffect(() => {
    const formattedRanges =
      schedule?.ranges.map(({ start, end, orders_limit }) => ({
        start: moment(start, HH_mm),
        end: moment(end, HH_mm),
        orders_limit,
      })) || [];

    form.setFieldsValue({ ranges: formattedRanges, is_weekend: schedule?.is_weekend || false });
  }, [schedule, form]);

  const handleSubmit = ({ ranges, is_weekend }) => {
    const validRanges = ranges.filter(({ start, end }) => start && end);

    const modifyData = {
      date: selectedValue.format(YYYY_MM_DD),
      is_weekend,
      ranges: validRanges.map(({ start, end, orders_limit }) => ({
        start: start.format(HH_mm),
        end: end.format(HH_mm),
        orders_limit: orders_limit || null,
      })),
    };

    ApiClient.call('post', `stores/${storeId}/schedule`, {}, modifyData)
      .then(() => {
        setErrors(null);
        setSchedule((prev) => [...prev.filter((item) => item.date !== selectedValue.format(YYYY_MM_DD)), modifyData]);
        message.success(t('stores.edit_schedule.success'));
        setOpen(false);
      })
      .validation((msg, errors) => {
        setErrors(errors);
      })
      .catch(() => {
        message.error(t('stores.edit_schedule.error'));
      });
  };

  const handleCancel = () => {
    const formattedRanges =
      schedule?.ranges.map(({ start, end, orders_limit }) => ({
        start: moment(start, HH_mm),
        end: moment(end, HH_mm),
        orders_limit,
      })) || [];
    form.setFieldsValue({ ranges: formattedRanges });
    setOpen(false);
    setErrors(null);
  };

  const disabledTime = (key) => {
    const startValue = form.getFieldValue(['ranges', key, 'start']);

    if (!startValue) {
      return {};
    }

    const [selectHour, selectMinute] = startValue.format(HH_mm).split(':').map(Number);

    return {
      disabledHours: () => Array.from({ length: selectHour }, (_, i) => i),
      disabledMinutes: (selectedHour) => (selectedHour === selectHour ? Array.from({ length: selectMinute }, (_, i) => i) : []),
    };
  };

  return (
    <Modal
      title={
        <ModalHead>
          <span>{t('stores.edit_schedule.modal_title')}</span>
          <span>
            {t('stores.calendar_header.select_date')} {selectedValue?.format(D_MMMM_YYYY)}
          </span>
        </ModalHead>
      }
      open={open}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <EditScheduleFormStyled form={form} onFinish={handleSubmit}>
        <Form.Item name="is_weekend" noStyle>
          <WeekendModal open={isOpenWeekend} onClose={() => setIsOpenWeekend(false)} />
        </Form.Item>

        <Form.List name="ranges">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <div key={key} className="schedule-list-item">
                  <TimePickerFormItem
                    shouldUpdate={(prevValues, currentValues) => prevValues.ranges?.[name]?.start !== currentValues.ranges?.[name]?.start}
                    label={t('stores.edit_schedule.fields.start')}
                    rules={[{ required: true, message: t('stores.edit_schedule.fields.specify_start') }]}
                    validateStatus={has(errors, `ranges.${name}.start`) ? 'error' : false}
                    help={get(errors, `ranges.${name}.start`)}
                  >
                    {({ getFieldValue, setFieldValue }) => (
                      <TimePicker
                        value={getFieldValue(['ranges', name, 'start'])}
                        onSelect={(value) => {
                          setFieldValue(['ranges', name, 'start'], value);
                        }}
                        format={HH_mm}
                        suffixIcon={false}
                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                      />
                    )}
                  </TimePickerFormItem>

                  <TimePickerFormItem
                    shouldUpdate={(prevValues, currentValues) => prevValues.ranges?.[name]?.end !== currentValues.ranges?.[name]?.end}
                    label={t('stores.edit_schedule.fields.end')}
                    rules={[{ required: true, message: t('stores.edit_schedule.fields.specify_end') }]}
                    validateStatus={has(errors, `ranges.${name}.end`) ? 'error' : false}
                    help={get(errors, `ranges.${name}.end`)}
                  >
                    {({ getFieldValue, setFieldValue }) => (
                      <TimePicker
                        value={getFieldValue(['ranges', name, 'end'])}
                        onSelect={(value) => {
                          setFieldValue(['ranges', name, 'end'], value);
                        }}
                        format={HH_mm}
                        suffixIcon={false}
                        disabledTime={() => disabledTime(name)}
                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                      />
                    )}
                  </TimePickerFormItem>
                  <FormInput
                    name={[name, 'orders_limit']}
                    label={t('stores.edit_schedule.fields.orders_limit')}
                    error={get(errors, `ranges.${name}.orders_limit`)}
                  />
                  <Button size="small" icon={<DeleteOutlined />} type="primary" danger onClick={() => remove(name)} />
                </div>
              ))}

              <Button type="dashed" icon={<PlusOutlined />} onClick={() => add()} block>
                {t('stores.edit_schedule.fields.add')}
              </Button>
            </>
          )}
        </Form.List>
        <div className="actions">
          <Button style={{ marginRight: 'auto' }} onClick={() => setIsOpenWeekend(true)}>
            {isWeekend ? t('stores.edit_schedule.actions.weekend') : t('stores.edit_schedule.actions.worker')}
          </Button>
          <Button type="primary" htmlType="submit">
            {t('stores.edit_schedule.actions.save')}
          </Button>
          <Button onClick={handleCancel}>{t('stores.edit_schedule.actions.cancel')}</Button>
        </div>
      </EditScheduleFormStyled>
    </Modal>
  );
};

EditScheduleForm.propTypes = {
  selectedValue: propTypes.object,
  schedule: propTypes.object,
  setSchedule: propTypes.func,
  storeId: propTypes.oneOfType([propTypes.string, propTypes.number]),
  open: propTypes.bool,
  setOpen: propTypes.func,
};

export default EditScheduleForm;
