import { Button, Form } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { WeekendModalStyled } from '@/components/Store/style';

const WeekendModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  const form = useFormInstance();

  const isWeekend = Form.useWatch('is_weekend', form);

  const handleSetWeekend = (isWeekend) => {
    form.setFieldsValue({ is_weekend: isWeekend });

    if (isWeekend) {
      form.setFieldsValue({ ranges: [] });
    }

    onClose();
  };

  return (
    <WeekendModalStyled
      open={open}
      title={`${t('stores.weekend_modal.question_message')} "${isWeekend ? t('stores.weekend_modal.worker') : t('stores.weekend_modal.weekend')}"?`}
      footer={null}
      closable={false}
    >
      <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
        <Button type="primary" onClick={() => handleSetWeekend(!isWeekend)}>
          Так
        </Button>
        <Button danger onClick={() => handleSetWeekend(false)}>
          Ні
        </Button>
      </div>
    </WeekendModalStyled>
  );
};

WeekendModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default WeekendModal;
