import React, { useEffect, useState } from 'react';
import { CheckOutlined, DeleteOutlined, FormOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { OrderFormItem, InputStyle, UserEditorModalStyle } from '@/components/Order/styled';
import InputOrder from '@/components/Order/InputOrder';
import OrderFormItemSelect from '@/components/Order/OrderFormItemSelect';
import OrderSelect from '@/components/Order/OrderSelect';
import OrderPhoneInput from '@/components/Order/OrderPhoneInput';
import ApiClient from '@/helpers/apiClient/ApiClient';
import {
  ORDER_GENDER_TYPE_FEMALE,
  ORDER_GENDER_TYPE_MALE,
  ORDER_SOCIAL_NETWORK_FACEBOOK,
  ORDER_SOCIAL_NETWORK_INSTAGRAM,
  ORDER_SOCIAL_NETWORK_TELEGRAM,
  ORDER_SOCIAL_NETWORK_VIBER,
} from '@/constants/orders';

const socialMediaData = {
  [ORDER_SOCIAL_NETWORK_TELEGRAM]: {
    path: '@',
    img: '/telegram.svg',
  },
  [ORDER_SOCIAL_NETWORK_FACEBOOK]: {
    path: 'facebook.com/',
    img: '/facebook.svg',
  },
  [ORDER_SOCIAL_NETWORK_VIBER]: {
    path: '',
    img: '/viber.svg',
  },
  [ORDER_SOCIAL_NETWORK_INSTAGRAM]: {
    path: 'instagram.com/',
    img: '/instagram.svg',
  },
};

const SocialInfoItem = ({ value, onChange, onRemove }) => {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value.value.replace(socialMediaData[value.type].path, ''));

  return (
    <>
      {editing ? (
        <div className="edit-container">
          {value.type === ORDER_SOCIAL_NETWORK_VIBER ? (
            <InputMask mask="+380999999999" maskPlaceholder="" value={inputValue} onChange={(e) => setInputValue(e.target.value)}>
              <Input className="edit-input" style={{ marginBottom: '10px' }} />
            </InputMask>
          ) : (
            <Input value={inputValue} onChange={(e) => setInputValue(e.target.value)} style={{ marginBottom: '10px' }} className="edit-input" />
          )}

          <Button
            type="primary"
            className="save-change"
            onClick={() => {
              onChange({ ...value, value: socialMediaData[value.type].path + inputValue });
              setEditing(false);
            }}
          >
            Змінити
            <CheckOutlined />
          </Button>
        </div>
      ) : (
        <div className="social-info">
          <span className="value">
            <img src={socialMediaData[value.type].img} alt="Icon" width={20} height={20} />
            {value.value}
          </span>
          <div className="controls">
            <button type="button" onClick={() => setEditing(true)} aria-label="Edit social">
              <FormOutlined style={{ color: '#3f8cff' }} />
            </button>
            <button type="button" onClick={onRemove} aria-label="Delete social">
              <DeleteOutlined style={{ color: 'red' }} />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

SocialInfoItem.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

const UserEditorModal = ({ open, setOpen, clientData, changeClientData }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [errors, setError] = useState({});
  const [socialType, setSocialType] = useState(ORDER_SOCIAL_NETWORK_TELEGRAM);
  const [socialNickName, setSocialNickName] = useState('');

  useEffect(() => {
    if (open) {
      form.resetFields();
      if (clientData) {
        form.setFieldsValue(clientData);
      }
    }
  }, [clientData, open, form]);

  const handleCancel = () => {
    form.resetFields();
    setSocialType(ORDER_SOCIAL_NETWORK_TELEGRAM);
    setSocialNickName('');
    setOpen(false);
  };

  const handleSubmit = (values) => {
    const method = clientData.id ? 'patch' : 'post';
    const path = clientData.id ? `clients/${clientData.id}` : 'clients';
    const successMessage = clientData.id ? t('orders.user_modal.update_data') : t('orders.user_modal.create_user');

    ApiClient.call(method, path, {}, values)
      .data(({ data }) => {
        changeClientData(data);
        message.success(successMessage);
        setOpen(false);
        setError({});
      })
      .code(500, () => {
        message.error(t('orders.user_modal.server_error_message'));
      })
      .catch(({ data }) => setError(data.errors));
  };

  return (
    <UserEditorModalStyle
      title={
        <>
          <UserOutlined style={{ color: '#3f8cff', marginRight: '10px' }} />
          {clientData.id ? t('orders.user_modal.title_edit_user') : t('orders.user_modal.title_add_user')}
        </>
      }
      placement="right"
      onClose={handleCancel}
      open={open}
      closable={false}
    >
      <Form initialValues={clientData} form={form} onFinish={handleSubmit}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <InputOrder prefix={t('orders.user_modal.first_name')} name="name" error={get(errors, 'name')} />
          </Col>
          <Col span={24}>
            <InputOrder prefix={t('orders.user_modal.last_name')} name="last_name" error={get(errors, 'last_name')} />
          </Col>
          <Col span={24}>
            <OrderFormItemSelect
              prefix={t('orders.user_modal.gender')}
              required
              name="gender"
              options={[
                { value: ORDER_GENDER_TYPE_MALE, label: t(`orders.gender.${ORDER_GENDER_TYPE_MALE}`) },
                { value: ORDER_GENDER_TYPE_FEMALE, label: t(`orders.gender.${ORDER_GENDER_TYPE_FEMALE}`) },
              ]}
            />
          </Col>
          <Col span={24}>
            <OrderPhoneInput name="phone_number" prefix={t('orders.fields.phone_number')} error={get(errors, 'phone_number')} />
          </Col>
        </Row>

        <Form.List name="contact_channels">
          {(fields, { add, remove }) => (
            <Row className="social-container" gutter={[10, 10]}>
              <Col span={8} className="padding-left">
                <OrderSelect
                  value={socialType}
                  onChange={setSocialType}
                  prefix={t('orders.user_modal.social_media')}
                  options={[
                    { value: ORDER_SOCIAL_NETWORK_TELEGRAM, label: t(`orders.social_type.${ORDER_SOCIAL_NETWORK_TELEGRAM}`) },
                    { value: ORDER_SOCIAL_NETWORK_INSTAGRAM, label: t(`orders.social_type.${ORDER_SOCIAL_NETWORK_INSTAGRAM}`) },
                    { value: ORDER_SOCIAL_NETWORK_VIBER, label: t(`orders.social_type.${ORDER_SOCIAL_NETWORK_VIBER}`) },
                    { value: ORDER_SOCIAL_NETWORK_FACEBOOK, label: t(`orders.social_type.${ORDER_SOCIAL_NETWORK_FACEBOOK}`) },
                  ]}
                />
              </Col>
              <Col span={16} className="padding-right" style={{ position: 'relative' }}>
                {socialType === ORDER_SOCIAL_NETWORK_VIBER ? (
                  <InputMask mask="+380999999999" maskPlaceholder="" value={socialNickName} onChange={(e) => setSocialNickName(e.target.value)}>
                    <InputStyle prefix={t('orders.fields.phone_number')} />
                  </InputMask>
                ) : (
                  <InputStyle prefix={t('orders.user_modal.nickname')} value={socialNickName} onChange={(e) => setSocialNickName(e.target.value)} />
                )}

                <button
                  className="add-name"
                  onClick={() => {
                    if (socialNickName.trim()) {
                      add({ type: socialType, value: socialMediaData[socialType].path + socialNickName });
                      setSocialNickName('');
                    }
                  }}
                  type="button"
                >
                  +
                </button>
              </Col>

              <>
                {fields.map((field) => {
                  return (
                    <Col span={24} key={field.name} className="padding-left padding-right">
                      <OrderFormItem name={field.name}>
                        <SocialInfoItem onRemove={() => remove(field.name)} />
                      </OrderFormItem>
                    </Col>
                  );
                })}
              </>
            </Row>
          )}
        </Form.List>

        <Row justify="end" gutter={10} className="buttons-container">
          <Col>
            <Button className="button delete" onClick={handleCancel}>
              {t('orders.user_modal.cancel')}
            </Button>
          </Col>
          <Col>
            <Button type="primary" className="button save" htmlType="submit">
              {t('orders.user_modal.save')}
              <CheckOutlined />
            </Button>
          </Col>
        </Row>
      </Form>
    </UserEditorModalStyle>
  );
};

UserEditorModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  clientData: PropTypes.object,
  changeClientData: PropTypes.func,
};

export default UserEditorModal;
