import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import ApiClient from '@/helpers/apiClient/ApiClient';
import { GoodsModalTitle } from '@/container/pages/warehouse/goods/styled';
import GoodsTable from '@/components/warehouse/GoodTable';

const GoodBillingInventory = ({ open, setOpen, billingGoodId, billingGoodCharacteristicId }) => {
  const { t } = useTranslation();
  const [data, setData] = useState();

  useEffect(() => {
    if (billingGoodId && billingGoodCharacteristicId) {
      ApiClient.call('get', `warehouse/goods/billing-inventory`, {
        billing_good_id: billingGoodId,
        billing_good_characteristic_id: billingGoodCharacteristicId,
      }).data(({ data }) => {
        const updateDate = data.map(({ name, data }) => {
          return {
            city_name: name,
            stores: data.map((store) => {
              return {
                store_name: store.name,
                price: store.price,
                remains: store.remains,
              };
            }),
          };
        });

        setData(updateDate);
      });
    }
  }, [billingGoodId, billingGoodCharacteristicId]);

  return (
    <Modal open={open} footer={false} onCancel={() => setOpen(false)} title={t('warehouse.goods.data_1C')} width={1000}>
      <GoodsModalTitle>{t('warehouse.goods.price_and_remains')}</GoodsModalTitle>
      <GoodsTable data={data} />
    </Modal>
  );
};

GoodBillingInventory.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  billingGoodId: PropTypes.string,
  billingGoodCharacteristicId: PropTypes.string,
};

export default GoodBillingInventory;
