import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import withAuthLayout from '@/layouts/withAuthLayout';
import { CardToolbox, Main } from '@/container/styled';
import { PageHeader } from '@/components/page-headers/page-headers';
import Table from '@/widgets/Table/Table';

const OversellLimitGroupsIndex = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        title: t('warehouse.oversell_limit_groups.oversell_name_limit'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('warehouse.oversell_limit_groups.oversell_limit'),
        dataIndex: 'limit',
        key: 'limit',
      },
    ],
    [],
  );
  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t('warehouse.oversell_limit_groups.header_title')}
          buttons={[
            <Button className="btn-add_new" size="default" type="primary" key="1">
              <Link to="/warehouse/oversell-limit-groups/create">+ {t('warehouse.oversell_limit_groups.create_button')}</Link>
            </Button>,
          ]}
        />
      </CardToolbox>

      <Main>
        <Table
          route="warehouse/oversell-limit-groups"
          resource="warehouse/oversell-limit-groups"
          columns={columns}
          permissionNamespace="warehouse.oversell_limit_groups"
        />
      </Main>
    </>
  );
};

export default withAuthLayout(OversellLimitGroupsIndex);
